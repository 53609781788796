import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';
import {User} from '@app/shared/models/user';
import {environment} from '@environments/environment';

declare var $: any;
declare var require: any;


@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.css']
})
export class MileageComponent implements OnInit {
  users = [];
  assetUrl: any = environment.assetsUrl;
  apiUrl:any=environment.apiPrefix
  driverCurrentPage = 5;
  totalitems;
  str;
  end;
  ride_type = 0;
  showChart=0;
  payment_type = 0;
  driverType =0;
  temp = true;
  calling: boolean;
  callingF:boolean;
  dayReports:any={};
  dataPass:any={};
  searchString:any='';
  filter:any='';

  driverChartOption:any={data:{},options:{}};
  riderChartOption:any={data:{},options:{}};
  revenueChartOption:any={data:{},options:{}};
  public dateFilterForm: FormGroup;
  riders_data: { name: string; value: any; }[];
  totalRiders: any  =  0;
  vhiecle_data: any[];
  totalVheicles: any;
  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {

  }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
   

    
    this.calling = true;
    this.getUsers();
    this.apiServices.postGeneralMethod('get-milesages',{page:1}).subscribe((res)=>{
    
      if (res.response) {
       this.calling= false;
       this.dayReports=res.response;
       
       let dayReports=this.dayReports;
       console.log(this.dayReports.earnings.categories)
  this.revenueChartOption.data={
      labels:this.dayReports.earnings.categories,
      datasets: [{
        label: 'Travelled',
         borderColor: "#3cba9f",
        data:this.dayReports.earnings.distance

       }]
  }
  this.revenueChartOption.options= {
    legend: { display: true },
    title: {
      display: true,
      text: 'Monthly Average Mileage'
    }
  }
    this.riderChartOption.data = {
    datasets: [{
        label: "Mileage",
        backgroundColor:['#abd037','#1889cb','#383d41','#856404'],
        data: [dayReports.ride_result.today,dayReports.ride_result.week,dayReports.ride_result.month,
       dayReports.ride_result.year]
    }],
    labels:["Today","Week","Month","Year"]
   };

    this.riders_data = [{name : "Today" , value :dayReports.ride_result.today },
                        {name : "Week" , value : dayReports.ride_result.week},
                        {name : "Month" , value : dayReports.ride_result.month},
                        {name : "Year" , value : dayReports.ride_result.year}]
   this.totalRiders = this.dayReports.ride_result.total;
   this.riderChartOption.options= {
      legend: { display: false },
      title: {
        display: true,
        text: 'Total Mileage :'+this.dayReports.ride_result.total
      }
      }
    this.driverChartOption.data = {
    datasets: [{
        label: "Vehicle types",
        backgroundColor:this.dayReports.color,
        data: this.dayReports.numbers
    }],
    labels:this.dayReports.label
   };
   this.vhiecle_data = [];

      // before
    for (let i = 0; i < dayReports.numbers.length; i++) {
      this.vhiecle_data.push({
        value : dayReports.numbers[i],
        name : dayReports.label[i]
      })
    }

   this.driverChartOption.options= {
      legend: { display: false },
      title: {
        display: true,
        text: 'Vehicle types Count :'+this.dayReports.vehicle_result.length
      }
    }
    this.totalVheicles = this.dayReports.vehicle_result.length
    this.showChart=1;
       
     
     }
    }, (err) => {
      console.log(err);
    });
}
  
   getUsers() {
    this.callingF = true;
    this.dataPass={
      filter:this.filter,
      startDate:this.str,
      endDate:this.end,
      ride_type:this.ride_type,
      limit:this.driverCurrentPage
    }
     
    this.apiServices.postGeneralMethod('get-milesages-filter',this.dataPass).subscribe((res) => {
      this.callingF = false;
      if (res.response) {
        this.users = res.response;
        
      }
    }, (err) => {
      console.log(err);
    });
  }
 

 getData(filter) {
    
   if(filter=='date')
   {
    this.str = this.dateFilterForm.get('start').value;
    this.end = this.dateFilterForm.get('end').value;
    this.temp = false;
    $('#myDateModel').modal('hide');
  
   }
   else
   {
     this.str='';
     this.end='';
     
   }
   this.filter=filter;
   this.getUsers();
       
  }
  
  
  resetFilter() {
    this.filter = '';
    this.str = '';
    this.end='';
    
    this.getUsers();

  }
}