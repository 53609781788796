import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/v2/services/api.service';
import * as echarts from 'echarts';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['../../../assets/styles/css/material-dashboard.css', './home.component.css']
})
export class HomeComponent implements OnInit {

    siteStatistics: any;
    rideStatistics: any;
    riderStatistics: any;
    driverStatistics: any;

    constructor(private apiServices: ApiService) { }

    ngOnInit() {
        this.getSiteStatistics();
        this.getRideStatistics();
        this.getDriverStatistics();
        this.getRiderStatistics();
        setTimeout(()=>{
            this.ridesChartData();
            this.driversChartData();
            this.EarningsAreaChartData();
        },1000);
    }

    /**
     * site statistics
     */
    getSiteStatistics(){
        this.apiServices.getSiteStatistics()
            .subscribe((res)=>{
                this.siteStatistics = res;
            })
    }

    /**
     * ride statistics
     */
    getRideStatistics(){
        this.apiServices.getRideStatistics()
            .subscribe((res)=>{
                this.rideStatistics = res;
            })
    }

    /**
     * get rider statistics
     */
    getRiderStatistics(){
        this.apiServices.getRiderStatistics()
            .subscribe((res)=>{
                this.riderStatistics = res;
                console.log(this.riderStatistics);
            })
    }

    /**
     * driver statistics
     */
    getDriverStatistics(){
        this.apiServices.getDriverStatistics()
            .subscribe((res)=>{
                this.driverStatistics = res;
            })
    }
    /**
     * rides graphs
     */
    ridesChartData() {
        /*
          * Use echarts
    * */
        //initialization
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("rides-graph"));
        //Specify the configuration items and data of the icon
        let option;
        option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    name: 'Rides',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: '40',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: 784, name: 'Completed Rides'},
                        {value: 1000, name: 'Total Rides'},
                    ],
                    color:['#C0FF02',"#0177D9"],
                }
            ]
        };
        //Display the data to the table
        myCharts.setOption(option);
    }
    /**
     * drivers graphs
     */
    driversChartData() {
        /*
          * Use echarts
    * */
        //initialization
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("drivers-graph"));
        //Specify the configuration items and data of the icon
        let option;
        option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    name: 'Rides',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: '40',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: 200, name: 'Completed Rides'},
                        {value: 800, name: 'Total Rides'},
                    ],
                    color:['#C0FF02',"#0177D9"],
                }
            ]
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

    /**
     * earning area chart
     */
    EarningsAreaChartData() {
        /*
          * Use echarts
    * */
        //initialization
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("earnings-graph"));
        //Specify the configuration items and data of the icon
        let option;
        option = {
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line',
                itemStyle: {normal: {areaStyle: {type: 'default'}}}
            }],
            color:['#0177D9'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

}

