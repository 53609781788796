import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {environment} from '@environments/environment';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.css']
})
export class VehicleDetailsComponent implements OnInit {
  assetUrl: any = environment.assetsUrl;

  vehDetails = [
    {
      id: '',
      name: '',
      vehicle_images: [{
        file_path: '',
        exterior_front: ''
      }],
      vehicle_name: '',
      vehicle_model: '',
      vehicle_number: '',
      vehicle_color: '',
      chassis_number: '',
      vehicle_brand: ''
    }
  ];
  searchName = '';
  vehCurrentPage = 1;
  calling: boolean;
  totalItems = '';
  filterVeh = '';

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getVehicleDetails();
  }


  getVehicleDetails() {
    this.calling = true;
    this.apiServices.getVehicleDetails(this.vehCurrentPage, this.searchName, this.filterVeh).subscribe((res) => {
        this.calling = false;
        this.vehDetails = res.response.data;
        this.totalItems = res.response.total;
      }, (err) => {
        console.log(err);
      }
    );
  }

  pageChangedVehicle($event) {
    this.vehCurrentPage = $event;
    this.getVehicleDetails();
  }

  filterVehicles(filterVeh) {
    this.filterVeh = filterVeh;
    if (filterVeh == '') {
      this.searchName = '';
      this.apiServices.getVehicleDetails(this.vehCurrentPage, this.searchName, filterVeh).subscribe((res) => {
        this.vehDetails = res.response.data;
        this.totalItems = res.response.total;
      }, (err) => {
        console.log(err);
      });
    } else {
      this.apiServices.getVehicleDetails(this.vehCurrentPage, this.searchName, filterVeh).subscribe((res) => {
        this.vehDetails = res.response.data;
        this.totalItems = res.response.total;
      }, (err) => {
        console.log(err);
      });
    }
  }


  delete(id) {
    this.snotifyService.confirm('Click Yes if You Want Delete Vehicle', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deleteSingleVehicleDetails(id).subscribe((res) => {
                this.snotifyService.remove();
                this.snotifyService.success('Deleted', 'Success');
                this.getVehicleDetails();
              },
              (err) => {
                console.log(err);
              })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }

  resetFilterVehicle() {
    this.vehCurrentPage = 1;
    this.filterVeh = '';
    this.searchName = '';
    this.apiServices.getVehicleDetails(this.vehCurrentPage, this.searchName, this.filterVeh).subscribe((res) => {
      this.vehDetails = res.response.data;
      this.totalItems = res.response.total;
    }, (err) => {
      console.log(err);
    });
  }
}
