import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rideStatus'
})
export class RideStatusPipe implements PipeTransform {

  transform(value: any): any {
    if (value === 1) {
      return 'Posted';
    } else if (value === 2) {
      return  'Accepted';
    } else if (value === 3) {
      return  'Started';
    } else if (value === 4) {
      return  'Completed';
    } else if (value === 5) {
      return  'Cancelled';
    } else if (value === 6) {
      return  'Paid';
    }
  }

}
