import { Component, OnInit } from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {FormBuilder} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {Router} from "@angular/router";
import * as firebase from 'firebase';
import {environment} from "@environments/environment";
import {AngularFireMessaging} from "@angular/fire/messaging";
import { mergeMapTo } from 'rxjs/operators';

@Component({
  selector: 'app-general-notification',
  templateUrl: './general-notification.component.html',
  styleUrls: ['./general-notification.component.css']
})
export class GeneralNotificationComponent implements OnInit {
  sendObj: any = {
    sendingWay: {type: 1, email_content: '', message: ''},
    userType: {type: 1, username: ''},
  };
  sendGeneral = {
    users : [],
    message: ''
  };
  uzers = [{
    id: '',
    name: '',
    email: '',
    country_code: '',
    phone: '',
    checked: false,
  }];
  currentPage = 1;
  totalitems;
  str;
  submitted=false;
  end;
  locations;
  data;
  val;
  calling: boolean;
  checkedUsers = [];
  selectAllIndeterminate = false;
  selectAllChecked = false;
  nameSearchRider = '';

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private afMessaging: AngularFireMessaging,
              private snotifyService: SnotifyService,
              private router: Router) { }

  ngOnInit() {
    this.getUsers(1);
    this.requestPermission();
  }

  /**
   * request permission
   */
  requestPermission() {
    this.afMessaging.requestPermission
        .pipe(mergeMapTo(this.afMessaging.tokenChanges))
        .subscribe(
            (token) => { console.log('Permission granted! Save to the server!', token); },
            (error) => { console.error(error); },
        );
  }

  /**
   * get users
   * @param type
   */
  getUsers(type) {
    this.calling = true;
    this.sendObj.userType.type = type;
    console.log(this.sendObj.userType.type);
    this.apiServices.getUsers(type, '', this.nameSearchRider, '', '', '').subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.uzers = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (error) => {
      this.snotifyService.error(error.error.message,'Error');
    });
  }

  /**
   * send general message
   */
  sendGeneralNotification() {
    this.submitted=true;
    this.sendGeneral.users = this.checkedUsers.map(user => user.id);
    this.apiServices.sendInAppNotification(this.sendGeneral)
        .subscribe((res) => {
          this.snotifyService.success("You have successfully sent this message","success");
          this.submitted=false;
        },error=>{
          this.submitted=false;
          this.snotifyService.error(error.error.message,'Error');
        });
  }

  /**
   *
   * @param $event
   */
  pageChangedRider($event) {
    this.currentPage = $event;
    this.getUsers(this.sendObj.userType.type);
  }
 /* selectUser(eve, id, index) {
    if (eve.checked == true) {
      this.checkbox.push(id);
    } else if (eve.checked == false) {
      this.checkbox.splice(this.checkbox.indexOf(id), 1);
    }
    console.log(this.checkbox);
  }*/
  selectUser() {
    this.checkedUsers = this.uzers.filter(user => user.checked);
    if (this.checkedUsers.length === this.uzers.length) {
      this.selectAllChecked = true;
      this.selectAllIndeterminate = false;
    } else if (this.checkedUsers.length > 0) {
      this.selectAllChecked = false;
      this.selectAllIndeterminate = true;
    } else {
      this.selectAllChecked = false;
      this.selectAllIndeterminate = false;
    }
  }
  checkAll(event) {
    if (event.checked) {
      this.uzers.map((user) => {
        user.checked = true;
      });
    } else {
      this.uzers.map((user) => {
        user.checked = false;
      });
    }
    this.checkedUsers = this.uzers.filter(user => user.checked);
  }

  // checkAll(ele) {
  //   const checkboxes = document.getElementsByTagName('mat-checkbox');
  //   if (ele.checked) {
  //     for (var i = 0; i < checkboxes.length; i++) {
  //       if (checkboxes[i].type == 'mat-checkbox') {
  //         checkboxes[i].checked = true;
  //       }
  //     }
  //   }  else {
  //     for (var i = 0; i < this.uzers.length; i++) {
  //       if (this.uzers[i].id == 'checkbox') {
  //         this.uzers[i].checked = false;
  //       }
  //     }
  //   }
  //   console.log(ele);
  // }

}
