import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";
import * as moment from 'moment';
import {google} from '@agm/core/services/google-maps-types';

declare var $: any;

@Component({
  selector: 'app-add-bus-rides',
  templateUrl: './add-bus-rides.component.html',
  styleUrls: ['./add-bus-rides.component.css']
})

export class AddBusRidesComponent implements OnInit {
  addBusRidesFormGroup: FormGroup;
  disabled = false;
  id;
  busDriver = [];
  selectedDriver;
  minDate = new Date() + Date();
  startMin;
  endMin;
  thisSource;
  thisDestination;
  sourceLatitude;
  destinationLatitude;
  destinationLongitude;
  sourceLongitude;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService, private zone: NgZone,
              private router: Router, private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit() {
    this.addBusRidesFormGroup = this.formBuilder.group({
      bus_id: [this.id, Validators.required],
      source: ['', Validators.required],
      destination: ['', Validators.required],
      driver_id: ['', Validators.required],
      start_time: ['', Validators.required],
      finish_time: ['', Validators.required],
      per_seat_price: ['', Validators.required],
      source_latitude: ['', Validators.required],
      source_longitude: ['', Validators.required],
      destination_latitude: ['', Validators.required],
      destination_longitude: ['', Validators.required]
    });
    this.getBusDrivers(this.addBusRidesFormGroup.value.driver_id);
    const today = new Date();
    this.startMin = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    this.endMin = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
  }

  atDateSelect(event) {
    this.endMin = new Date(event.value.getUTCFullYear(), event.value.getUTCMonth(), event.value.getUTCDate());
    if (this.addBusRidesFormGroup.value.start_time.length > 1) {
      this.endMin = new Date(event.value.getUTCFullYear(), event.value.getUTCMonth(), event.value.getUTCDate() + 1);
    } else {
      this.endMin = new Date(event.value.getUTCFullYear(), event.value.getUTCMonth(), event.value.getUTCDate());
    }
  }

  addBusRides() {
    if (this.addBusRidesFormGroup.value.source == '' ||
      this.addBusRidesFormGroup.value.destination == '' || this.addBusRidesFormGroup.value.driver_id == '') {
      this.disabled = true;
      this.snotifyService.error('All Fields are Mandatory', 'Error');
      this.disabled = false;
    } else if (this.addBusRidesFormGroup.value.start_time == '') {
      this.disabled = true;
      this.snotifyService.error('Start Time Fields Is Mandatory', 'Error');
      this.disabled = false;
    } else if (this.addBusRidesFormGroup.value.finish_time == '') {
      this.disabled = true;
      this.snotifyService.error('Finish Time Fields Is Mandatory', 'Error');
      this.disabled = false;
    } else {
      this.disabled = true;
      this.addBusRidesFormGroup.value.source = this.thisSource;
      this.addBusRidesFormGroup.value.destination = this.thisDestination;
      this.addBusRidesFormGroup.value.source_latitude = this.sourceLatitude;
      this.addBusRidesFormGroup.value.source_longitude = this.sourceLongitude;
      this.addBusRidesFormGroup.value.destination_latitude = this.destinationLatitude;
      this.addBusRidesFormGroup.value.destination_longitude = this.destinationLongitude;
      this.addBusRidesFormGroup.value.start_time = moment(this.addBusRidesFormGroup.value.start_time, 'HH:mm:ss').format('X');
      this.addBusRidesFormGroup.value.finish_time = moment(this.addBusRidesFormGroup.value.finish_time, 'HH:mm:ss').format('X');
      console.log(this.addBusRidesFormGroup.value);
      this.apiServices.addBusRides(this.addBusRidesFormGroup.value, this.id).subscribe((res) => {
          this.snotifyService.success('Added Successfully', 'Success');
          this.disabled = false;
          this.router.navigate(['../dashboard/bus-rides/' + this.id]);
        },
        (err) => {
          this.zone.run(() => {
            this.snotifyService.error(err.error.message, err.error.response);
            this.addBusRidesFormGroup.value.start_time = '';
            this.addBusRidesFormGroup.value.finish_time = '';
            this.disabled = false;
          });
        });
    }
  }

  getBusDrivers(id) {
    this.apiServices.getBusDriver().subscribe((res) => {
      this.busDriver = res.response;
      // this.kkk = this.busDriver.id
    });
  }

  onDriverSelect(get) {
    console.log(get);
  }

  selectCitySource(city) {
    console.log(city.geometry.location.lat().toString());
    console.log(city.geometry.location.lng().toString());
    this.addBusRidesFormGroup.value.source = city.formatted_address;
    this.thisSource = city.formatted_address;
    this.sourceLatitude = city.geometry.location.lat().toString();
    this.sourceLongitude = city.geometry.location.lng().toString();
  }

  selectCityDestination(city) {
    this.addBusRidesFormGroup.value.destination = city.formatted_address;
    this.thisDestination = city.formatted_address;
    this.destinationLatitude = city.geometry.location.lat().toString();
    this.destinationLongitude = city.geometry.location.lng().toString();
    console.log(city.geometry.location.lat().toString());
    console.log(city.geometry.location.lng().toString());
  }
}
