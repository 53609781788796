import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

  transform(method: any, args?: any): any {
    if(method==0){
      return "pending";
    }
    else if(method==1){
      return "credit card"
    }
    else if(method==2){
      return "debit card"
    }
    else if(method==3){
      return "wallet"
    }
    else if(method==4){
      return "net banking"
    }
    else if(method==5){
      return "cash"
    }
    else{
      return "unpaid";
    }
  }

}
