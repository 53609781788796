import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {environment} from '@environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {AuthGuardService} from '@app/core/guards/auth.guard.service';

@Component({
  selector: 'app-keke-rides',
  templateUrl: './keke-rides.component.html',
  styleUrls: ['./keke-rides.component.css']
})
export class KekeRidesComponent implements OnInit {


  kekeUserRides = [];
  kekeUserPage = 1;
  currency: any = environment.currency;
  kekeDriverPage = 1;
  type = 1;
  kekeUserSearch = '';
  public dateFilterFormKekeUser: FormGroup;
  public dateFilterFormKekeDriver: FormGroup;
  kekeDriverSearch = '';
  kekeDriverRides = [];
  kekeDriverTotal = '';
  kekeUserTotal: number;
  tempUser = true;
  tempDriver = true;
  calling: boolean;

  constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, public dialog: MatDialog, public  authGuardService: AuthGuardService) {
  }

  ngOnInit() {
    this.dateFilterFormKekeUser = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.dateFilterFormKekeDriver = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.getKekeUserRides();
    this.getKekeDriverRides();
  }


  getKekeUserRides() {
    this.calling = true;
    this.apiServices.getKekeUserRides(this.kekeUserPage, this.kekeUserSearch, '').subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.kekeUserRides = res.response.data;
        this.kekeUserTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getKekeDriverRides() {
    this.calling = true;
    this.apiServices.getKekeDriverRides(this.kekeDriverPage, this.kekeDriverSearch, '').subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.kekeDriverRides = res.response.data;
        this.kekeDriverTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  pageChangedKekeUser($event) {
    this.kekeUserPage = $event;
    if (this.tempUser == true) {
      this.getKekeUserRides();
    }
   /* else {
      this.getData();
    }*/
  }

  pageChangedKekeDriver($event) {
    this.kekeDriverPage = $event;
    if (this.tempDriver == true) {
      this.getKekeDriverRides();
    }
    /*else {
      this.getDataDriver();
    }*/
  }

  getKekeUserFilter(filter) {
    this.apiServices.getKekeUserRides(this.kekeUserPage, this.kekeUserSearch, filter).subscribe((res) => {
      if (res.response) {
        this.kekeUserRides = res.response.data;
        this.kekeUserTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getKekeFilterDriver(filter) {
    this.apiServices.getKekeDriverRides(this.kekeDriverPage, this.kekeDriverSearch, filter).subscribe((res) => {
      if (res.response) {
        this.kekeDriverRides = res.response.data;
        this.kekeDriverTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  resetFilterUser() {
    this.kekeUserPage = 1;
    this.kekeUserSearch = '';
    this.apiServices.getKekeUserRides(this.kekeUserPage, this.kekeUserSearch, '').subscribe((res) => {
      if (res.response) {
        this.kekeUserRides = res.response.data;
        this.kekeUserTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  resetFilterDriver() {
    this.kekeDriverPage = 1;
    this.kekeDriverSearch = '';
    this.apiServices.getKekeDriverRides(this.kekeDriverPage, this.kekeDriverSearch, '').subscribe((res) => {
      if (res.response) {
        this.kekeDriverRides = res.response.data;
        this.kekeDriverTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
