import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthGuardService} from '@app/core/guards/auth.guard.service';
import {DashboardComponent} from '@app/components/dashboard/dashboard.component';
import {HomeComponent} from '@app/components/home/home.component';
import {UsersComponent} from '@app/components/users/users.component';
import {RidesComponent} from '@app/components/rides/rides.component';
import {DriverComponent} from '@app/components/drivers/driver.component';
import {RideDetailsComponent} from '@app/components/ride-details/ride-details.component';
import {UserDetailsComponent} from '@app/components/user-details/user-details.component';
import {VehiclesComponent} from '@app/components/vehicles/vehicles.component';
import {AddVehicleComponent} from '@app/components/vehicles/add-vehicle.component';
import {EditVehicleComponent} from '@app/components/vehicles/edit-vehicle.component';
import {PoolRideComponent} from '@app/components/rides/pool-ride.component';
import {DriverDetailComponent} from '@app/components/drivers/driver-detail.component';
import {DriverRideDetailsComponent} from '@app/components/ride-details/driver-ride-details/driver-ride-details.component';
import {SupportComponent} from '@app/components/support/support.component';
import {NewsComponent} from '@app/components/news/news.component';
import {AddNewsComponent} from '@app/components/news/add-news/add-news.component';
import {EditNewsComponent} from '@app/components/news/edit-news/edit-news.component';
import {LiveRidesComponent} from '@app/components/live-rides/live-rides.component';
import {StartedRidesComponent} from './components/started-rides/started-rides.component';
import {StartedRideDetailsComponent} from './components/started-ride-details/started-ride-details.component';
import {RatingReviewComponent} from './components/rating-review/rating-review.component';
import {EditUserComponent} from './components/users/edit-user/edit-user.component';
import {EditDriverComponent} from './components/drivers/edit-driver/edit-driver.component';
import {EditDriverVehicleComponent} from './components/drivers/edit-driver-vehicle/edit-driver-vehicle.component';
import {DruppShopComponent} from '@app/components/drupp-shop/drupp-shop.component';
import {DruppShopAddComponent} from '@app/components/drupp-shop/drupp-shop-add.component';
import {EditDruppShopComponent} from '@app/components/drupp-shop/edit-drupp-shop.component';
import {NewsLetterComponent} from '@app/components/news-letter/news-letter.component';
import {VehicleDetailsComponent} from '@app/components/vehicle-details/vehicle-details.component';
import {MoreDetailsVehicleComponent} from '@app/components/vehicle-details/more-details-vehicle.component';
import {OrderHistoryShopComponent} from '@app/components/order-history-shop/order-history-shop.component';
import {BusComponent} from '@app/components/bus/bus.component';
import {AddBusComponent} from '@app/components/bus/add-bus.component';
import {EditBusComponent} from '@app/components/bus/edit-bus.component';
import {AddBusRidesComponent} from '@app/components/bus-rides/add-bus-rides.component';
import {BusRidesComponent} from '@app/components/bus-rides/bus-rides.component';
import {EditVehicleDetailsComponent} from '@app/components/vehicle-details/edit-vehicle-details.component';
import {CategoryShopComponent} from '@app/components/category-shop/category-shop.component';
import {AddNewsCategoryComponent} from '@app/components/news/add-news-category/add-news-category.component';
import {CommissionComponent} from '@app/components/commission/commission.component';
import {AddCategoryComponent} from '@app/components/category-shop/add-category.component';
import {AddTermsConditionPolicyComponent} from '@app/components/terms-condition-policy/add-terms-condition-policy.component';
import {IndividualChatComponent} from '@app/components/individual-chat/individual-chat.component';
import {MessageToAllComponent} from '@app/components/message-to-all/message-to-all.component';
import {SingleOrderHistoryComponent} from '@app/components/order-history-shop/single-order-history.component';
import {SchRidesComponent} from '@app/components/sch-rides/sch-rides.component';
import {SingleSchRideComponent} from '@app/components/sch-rides/single-sch-ride/single-sch-ride.component';
import {NotificationsComponent} from '@app/components/notifications/notifications.component';
import {SubAdminComponent} from '@app/components/sub-admin/sub-admin.component';
import {AddNewsSubCategoryComponent} from '@app/components/news/add-news-sub-category/add-news-sub-category.component';
import {KekeRidesComponent} from '@app/components/rides/keke-rides/keke-rides.component';
import {SingleBusRideDetailsComponent} from '@app/components/bus-rides/single-bus-ride-details/single-bus-ride-details.component';
import {GeneralNotificationComponent} from '@app/components/general-notification/general-notification.component';
import {cancellationManger} from '@app/components/cancellation-manger/cancellation-manger.component';
import {EarningsComponent} from '@app/components/earnings/earnings.component';
import {LiveUsersComponent} from '@app/components/live-users/live-users.component';
import { AnalysisComponent } from '@app/components/analysis/analysis.component';
import { MileageComponent } from '@app/components/mileages/mileage.component';
import { CouponComponent } from '@app/components/coupon/coupon.component';
import { EditCouponComponent } from '@app/components/coupon/edit-coupon.component';
import { AddCouponComponent } from '@app/components/coupon/add-coupon.component';
import { documentComponent } from '@app/components/document/document.component';
import {SearchComponent} from "@app/components/search/search.component";
import {NotesLogComponent} from "@app/components/notes-log/notes-log.component";
import {RolesComponent} from "@app/components/roles/roles.component";
import {AccessDeniedComponent} from "@app/components/access-denied/access-denied.component";
const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService],
    children: [
      {path: '', component: HomeComponent},
      {path:'unauthorised',component:AccessDeniedComponent},
      {path: 'notes-log', component: NotesLogComponent},
      {path: 'news', component: NewsComponent},
      {path: 'search-results', component: SearchComponent},
      {path: 'addnews', component: AddNewsComponent},
      {path: 'cancellation-manger',component:cancellationManger},
      {path: 'earnings',component:EarningsComponent},
      {path: 'document-view/:id',component:documentComponent},
      {path: 'mileage-travelled',component:MileageComponent},
      {path: 'coupon',component:CouponComponent},
      {path: 'edit-coupon/:id',component:EditCouponComponent},
      {path: 'add-coupon',component:AddCouponComponent},
      {path: 'cancellation-manger-driver',component:cancellationManger},
      {path: 'edit-news/:id', component: EditNewsComponent},
      {path:'live-users',component:LiveUsersComponent},
      {path:'analysis',component:AnalysisComponent},
      {path: 'trackRides', component: LiveRidesComponent},
      {path:'roles',component:RolesComponent},
      {path: 'users', component: UsersComponent},
      {path: 'rides', component: RidesComponent},
      {path: 'ride-details/:id', component: RideDetailsComponent},
      {path: 'started-ride-details/:id', component: StartedRideDetailsComponent},
      {path: 'started-rides', component: StartedRidesComponent},
      {path: 'drivers', component: DriverComponent},
      {path: 'user-details/:id', component: UserDetailsComponent},
      {path: 'edit-user/:id', component: EditUserComponent},
      {path: 'vehicles', component: VehiclesComponent},
      {path: 'add-vehicle', component: AddVehicleComponent},
      {path: 'edit-vehicle/:id', component: EditVehicleComponent},
      {path: 'edit-driver/:id', component: EditDriverComponent},
      {path: 'edit-driver-vehicle/:id', component: EditDriverVehicleComponent},
      {path: 'poolRides', component: PoolRideComponent},
      {path: 'driver-details/:id', component: DriverDetailComponent},
      {path: 'driver-ride-details/:id', component: DriverRideDetailsComponent},
      {path: 'support', component: SupportComponent},
      {path: 'rating-review', component: RatingReviewComponent},
      {path: 'drupp-shop', component: DruppShopComponent},
      {path: 'add-category', component: AddCategoryComponent},
      {path: 'drupp-shop-add', component: DruppShopAddComponent},
      {path: 'edit-product/:id', component: EditDruppShopComponent},
      {path: 'news-letter', component: NewsLetterComponent},
      {path: 'vehicle-details', component: VehicleDetailsComponent},
      {path: 'more-details-vehicle/:id', component: MoreDetailsVehicleComponent},
      {path: 'order-history', component: OrderHistoryShopComponent},
      {path: 'single-order-history/:id', component: SingleOrderHistoryComponent},
      {path: 'bus', component: BusComponent},
      {path: 'add-bus', component: AddBusComponent},
      {path: 'edit-bus/:id', component: EditBusComponent},
      {path: 'add-bus-rides/:id', component: AddBusRidesComponent},
      {path: 'bus-rides/:id', component: BusRidesComponent},
      {path: 'edit-vehicle-details/:id', component: EditVehicleDetailsComponent},
      {path: 'category-shop', component: CategoryShopComponent},
      {path: 'news-category', component: AddNewsCategoryComponent},
      {path: 'commission', component: CommissionComponent},
      {path: 'add-t&c', component: AddTermsConditionPolicyComponent},
      {path: 'chat', component: IndividualChatComponent},
      {path: 'chat/:driver_id', component: IndividualChatComponent},
      {path: 'send-to-all', component: MessageToAllComponent},
      {path: 'sch-rides', component: SchRidesComponent},
      {path: 'single-sch-rides/:id', component: SingleSchRideComponent},
      {path: 'notification', component: NotificationsComponent},
      {path: 'sub-admin', component: SubAdminComponent},
      {path: 'news-sub-category', component: AddNewsSubCategoryComponent},
      {path: 'kekeRides', component: KekeRidesComponent},
      {path: 'track-single-bus-ride/:id', component: SingleBusRideDetailsComponent},
      {path: 'general-notification', component: GeneralNotificationComponent},
    ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
