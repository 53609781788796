import { Component, OnInit } from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {Router,ActivatedRoute} from "@angular/router";
import {Rides} from '@app/shared/models/rides';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '@environments/environment';
declare var $: any;
@Component({
  selector: 'app-cancellation-manger',
  templateUrl: './cancellation-manger.component.html',
  styleUrls: ['./cancellation-manger.component.css']
})
export class cancellationManger implements OnInit {
  
  currentPage = 1;
  totalitems;
  str;
  end;
  locations;
  data;
  val;
  apiUrl:any=environment.apiPrefix
  uzers:any=[];
  calling: boolean;
  checkedUsers = [];
  userId;
  selectAllIndeterminate = false;
  selectAllChecked = false;
  nameSearchRider = '';
  headerLable:any='Drivers';
  userType:any=2;
  todayDate;
  public dateFilterForm: FormGroup;
  public locationFilterForm: FormGroup;
  public blockUserForm: FormGroup;
  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router,
              private route:ActivatedRoute) { }

  ngOnInit() {
   
    if(this.route.snapshot.queryParamMap.get("type")=="rider")
    {
      this.headerLable="Riders";
      this.userType=1;
    }
    else{
      this.headerLable="Drivers";
      this.userType=2;
    }
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.locationFilterForm = this.formBuilder.group({
      location: ['', Validators.required]
    });
    this.blockUserForm = this.formBuilder.group({
      activation_date: ['', Validators.required]
    });
    this.todayDate = new Date().toISOString().slice(0, 10);
   this.getcancellTrips(this.userType)
  }
  getValue(i) {
    $('#blockUserModal').modal('show');
    this.userId = i;
  }

  blockUser() {
    this.data = {
      id: this.userId,
      activation_date: this.blockUserForm.value.activation_date
    };
    this.apiServices.blockUser(this.data).subscribe((res) => {
      $('#blockUserModal').modal('hide');
      this.snotifyService.success('User Blocked', 'Success');
     
      this.getcancellTrips(this.userType);
    }, (err) => {
      console.log(err);
    });
  }
  unBlock(userId) {
    this.apiServices.unblockUserAccount({id: userId}).subscribe((res) => {
      
      this.getcancellTrips(this.userType);
      this.snotifyService.success('User Un-blocked', 'Success');
    }, (err) => {
      this.snotifyService.error(err.error.message, 'Error');
    });
  }
  resetFilter() {
    this.userType = 1;
    this.currentPage = 1;
    this.nameSearchRider = '';
    this.locations = '';
    this.str='';
    this.end='';
    this.getcancellTrips(this.userType)
    
  }
  getcancellTrips(type) {
    this.calling = true;
   
    this.apiServices.getcancellRides(type, this.currentPage, this.nameSearchRider,this.str,this.end).subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.uzers = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getData() {
    this.str = this.dateFilterForm.get('start').value;
    this.end = this.dateFilterForm.get('end').value;
    this.getcancellTrips(this.userType);
  }
  pageChangedRider($event) {
    this.currentPage = $event;
    this.getcancellTrips(this.userType);
  }
 

}
