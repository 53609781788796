import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "@services/local-storage.service";
import { Observable } from "rxjs";
import {environment} from "@environments/environment";

@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private storageService: LocalStorageService
  ) {}

  adminLogin(inputData): Observable<any> {
    // login
    return this.httpClient.post(`${environment.apiPrefix}admin-login`,inputData);
  }

  getUsers(
    type,
    offset,
    search,
    location,
    driverType,
    available
  ): Observable<any> {
    // getUsers-Rider-or-driver
    return this.httpClient.get(`${environment.apiPrefix}`+"get-users?type=" +
        type +
        "&offset=" +
        offset +
        "&search=" +
        search +
        "&filter=" +
        location +
        "&driver_type=" +
        driverType +
        "&availability_filter=" +
        available
    );
  }

  getKekeUserRides(offset, search, filter): Observable<any> {
    // getUsers-Rider-or-driver
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-user-posted-keke-rides?offset=" +
        offset +
        "&search=" +
        search +
        "&filter=" +
        filter
    );
  }
  getKekeDriverRides(offset, search, filter): Observable<any> {
    // getUsers-Rider-or-driver
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-driver-posted-keke-rides?offset=" +
        offset +
        "&search=" +
        search +
        "&filter=" +
        filter
    );
  }

  getUserRides(n, offset): Observable<any> {
    // getListOfRidesOf-user-by-id
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-single-user-rides?user_id=" + n + "&offset=" + offset
    );
  }

  /**
   * get car types
   */
  getVehicle(): Observable<any> {
    // GetListOfVehicles
    return this.httpClient.get(`${environment.apiPrefix}`+"get-vehicle-types");
  }

  getUsersLiveMap(): Observable<any> {
    // getListOfRidesOf-user-by-id
    return this.httpClient.get(`${environment.apiPrefix}`+"get-live-users-map");
  }
  getcancellRides(type, offset, search, startD, endD): Observable<any> {
    // getList-of-UserRides-pooled-or-single
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-cancel-posted-rides?type=" +
        type +
        "&offset=" +
        offset +
        "&search=" +
        search +
        "&startD=" +
        startD +
        "&endD=" +
        endD
    );
  }
  getEarningsCount(): Observable<any> {
    // getEarnings report
    return this.httpClient.get(`${environment.apiPrefix}`+"get-earnings");
  }
  getEarnings(data): Observable<any> {
    // getEarnings report
    return this.httpClient.post(`${environment.apiPrefix}`+"get-earnings", data);
  }
  postGeneralMethod(url, data): Observable<any> {
    // getEarnings report
    return this.httpClient.post(url, data);
  }

  getRides(type, offset, search, filter): Observable<any> {
    // getList-of-UserRides-pooled-or-single
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-user-posted-rides?type=" +
        type +
        "&offset=" +
        offset +
        "&search=" +
        search +
        "&filter=" +
        filter
    );
    // return this.httpClient.get(
    //   "get-user-posted-rides?type=2&offset=1&search=&filter="
    // );
    //return this.httpClient.get("admin-dashboard");
  }

  getDriverRides(type, offset, search, filter): Observable<any> {
    // listOf-DriverRides-by-Type
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-driver-posted-rides?type=" +
        type +
        "&offset=" +
        offset +
        "&search=" +
        search +
        "&filter=" +
        filter
    );
  }

  getSingleRide(n): Observable<any> {
    // detailRide-userRide-table
    return this.httpClient.get(`${environment.apiPrefix}`+"get-user-posted-single-ride?ride_id=" + n);
  }

  getSingleDriverRide(n): Observable<any> {
    // detailRide-DriverRide-table
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-driver-rides?ride_id=" + n);
  }

  getSingleUser(n): Observable<any> {
    // userDetails-driver-or-rider
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-user?id=" + n);
  }

  /**
   *
   * @param n
   */
  getDriverVehicle(n): Observable<any> {
    // Driver-vehicle-details
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-vehicle?driver_id=" + n);
  }

  getDriverAcceptedRides(id, offset, filter, str, end): Observable<any> {
    // ListOfRidesAcceptedByDriver
    return this.httpClient.get(`${environment.apiPrefix}`+
      "driver-accepted-all-rides?driver_id=" +
        id +
        "&offset=" +
        offset +
        "&filter=" +
        filter +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  getDriverPostedRides(id, offset, filter, str, end): Observable<any> {
    // ListOfDriverPostedRides
    return this.httpClient.get(`${environment.apiPrefix}`+
      "driver-posted-all-rides?driver_id=" +
        id +
        "&offset=" +
        offset +
        "&filter=" +
        filter +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //Update Vehicle Details
  updateVehicle(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-vehicle-types", data);
  }

  //Get Vehicle Details By Id
  getSingleVehicle(n): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-vehicle-type?id=" + n);
  }

  //Add New Vehicle
  addVehicle(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-vehicle-type", data);
  }

  //Delete Existing Vehicle
  deleteVehicle(id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"delete-vehicle-types?id=" + id, id);
  }

  //Support (User / Driver )
  getSupport(type, offset): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-support-details?type=" + type + "&offset=" + offset
    );
  }

  //Total upcoming Rides
  getPostedRides(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-total-posted-rides");
  }

  //Total Completed Rides
  getCompletedRides(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-total-completed-rides");
  }

  //All Weekly Rides for Graph
  getDetailsGraph(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-weekly-rides");
  }

  //All Monthly Rides for Graph
  getMonthlyRides(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-monthly-rides");
  }

  // //Total Kilometers Travelled
  //   getKilometersTravelled(): Observable<any> {
  //     return this.httpClient.get('get-total-kilometers-travelled');
  //   }

  //Image Upload for News
  newsImageUpload(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"news-image-upload", data);
  }

  //Add New News
  newsAdd(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-news", data);
  }

  //Get All News
  getNews(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-news-list");
  }

  //Delete News
  deleteNews(id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"delete-news?id=" + id, id);
  }

  // Update News By Id
  editNews(data, id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-news?id=" + id, data);
  }

  // Single news Details by id
  getSingleNews(n): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-news?id=" + n);
  }

  /**
   * All Started Rides
   * @param offset
   * @param search
   */
  getLiveRides(offset, search): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-all-rides-location?offset=" + offset + "&search=" + search
    );
  }

  // Single Started Rides details
  getSingleStarted(n): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-ride-location?ride_id=" + n);
  }

  //All Users Ratings
  getUserRating(offset): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-user-action-ratings?offset=" + offset);
  }

  //All Drivers Ratings
  getDriverRating(offset): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-driver-action-ratings?offset=" + offset);
  }

  // Update User Details
  updateUser(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-user-details", data);
  }

  // Update Driver Details
  updateDriver(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-driver-details", data);
  }

  //Update Driver Vehicle Details
  updateVehicleDriver(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-driver-vehicle-details", data);
  }

  // Block User
  blockUser(data) {
    return this.httpClient.post(`${environment.apiPrefix}`+"block-user-account", data);
  }

  /**
   * remove user
   * @param user_id
   */
  removeUser(user_id:any) {
    return this.httpClient.delete(`${environment.apiPrefix}remove-users/${user_id}`);
  }

  // All User Accepted Rides
  getUserAcceptedRides(id, offset, filter, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "user-accepted-all-rides?user_id=" +
        id +
        "&offset=" +
        offset +
        "&filter=" +
        filter +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //All User Posted Rides
  getUserPostedRides(id, offset, filter, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "user-posted-all-rides?user_id=" +
        id +
        "&offset=" +
        offset +
        "&filter=" +
        filter +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //Started rides Date Filter
  getLiveRidesFilter(str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-all-rides-location?start_at=" + str + "&end_at=" + end
    );
  }

  //Drivers/Users Date Filter
  getUsersFilter(t, n, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-users?type=" +
        t +
        "&offset=" +
        n +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //User Rides (Pool/Single) Date Filter
  getRidesFilter(type, offset, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-user-posted-rides?type=" +
        type +
        "&offset=" +
        offset +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //Driver Rides (Pool/Single) Date Filter
  getDriverRidesFilter(type, offset, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-driver-posted-rides?type=" +
        type +
        "&offset=" +
        offset +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //Driver and User Support Date Filter
  getSupportFilter(type, str, end, n): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-support-details?type=" +
        type +
        "&offset=" +
        n +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  //User Rating Date Filter
  getUserRatingFilter(str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-user-action-ratings?start_at=" + str + "&end_at=" + end
    );
  }

  //Driver Rating Date Filter
  getDriverRatingFilter(str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-driver-action-ratings?start_at=" + str + "&end_at=" + end
    );
  }

  //Driver Rating Sort Filter
  sortRatingDriver(n): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-driver-action-ratings?sort_by=" + n);
  }

  //User Rating Sort Filter
  sortRatingUser(n): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-user-action-ratings?sort_by=" + n);
  }

  //Change Driver/User Support Status
  changeSupportStatus(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"support-action", data);
  }

  getCategory(parentId): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-categories?parent_id=" + parentId);
  }

  /**
   * get all drupp products
   */
  getDrupShopProducts(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}get-all-products`);
  }

  /**
   * filterred products
   * @param offset
   * @param search
   * @param min
   * @param max
   * @param filter
   */
  getFilteredDruppShopProducts(offset, search, min, max, filter): Observable<any> {
    return this.httpClient.get(
        "get-all-products?offset=" +
        offset +
        "&search=" +
        search +
        "&min_price=" +
        min +
        "&max_price=" +
        max +
        "&filter=" +
        filter
    );
  }

  addProduct(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-product", data);
  }

  // uploadImage(data): Observable<any> {
  //   const fd = new FormData();
  //   fd.append('image', data);
  //   return this.httpClient.post('product-image-upload', fd);
  // }
  uploadImage(formData): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"product-image-upload", formData);
  }

  editProduct(data, id): Observable<any> {
    return this.httpClient.post("edit-product?product_id=" + id, data);
  }

  deleteProduct(id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"delete-product?product_id=" + id, id);
  }

  /**
   *
   * @param id
   */

  getSingleProduct(id): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-product?product_id=" + id);
  }

  getVehicleDetails(offset, name, filter): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-vehicle-details?offset=" +
        offset +
        "&search_key=" +
        name +
        "&filter=" +
        filter
    );
  }

  getOrderHistory(offset): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-orders?offset=" + offset);
  }

  getBus(offset, name): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-bus-details?offset=" + offset + "&search_key=" + name
    );
  }

  addBus(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-bus-details", data);
  }

  addBusRides(data, id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-bus-ride?bus_id=" + id, data);
  }

  deleteBus(id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"delete-bus-detail?bus_id=" + id, id);
  }

  downloadRep(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"down-rep");
  }

  editBus(data, id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-bus-detail?bus_id=" + id, data);
  }

  getSingleBus(id): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-bus-details?bus_id=" + id);
  }

  getSingleBusRides(id, offset, name, str, end, filter): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-single-bus-rides?bus_id=" +
        id +
        "&offset=" +
        offset +
        "&search_key=" +
        name +
        "&start_at=" +
        str +
        "&end_at=" +
        end +
        "&filter=" +
        filter
    );
  }
  getSingleBusRide(id): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-bus-ride?bus_ride_id=" + id);
  }

  editSingleRide(data, id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-bus-ride?bus_ride_id=" + id, data);
  }

  // getSingleRideBus(id): Observable<any> {
  //   return this.httpClient.get('get-single-ride-bus?bus_ride_id=' + id);
  // }
  deleteBusRide(id): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"delete-bus-ride?bus_ride_id=" + id, id);
  }

  getBusDriver(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-bus-drivers");
  }

  getPassengers(id, offset, name, filter, sort): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-bus-passengers?bus_ride_id=" +
        id +
        "&offset=" +
        offset +
        "&search_key=" +
        name +
        "&filter=" +
        filter +
        "&sort=" +
        sort
    );
  }

  getSingleVehicleDetails(id): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-vehicle-details?id=" + id);
  }

  editSingleVehicleDetails(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-vehicle-details", data);
  }

  deleteSingleVehicleDetails(id): Observable<any> {
    return this.httpClient.post("delete-vehicle-details?vehicle_id=" + id, id);
  }

  //
  // downloadPassengers(id) {
  //   return this.httpClient.get('download-passengers-pdf?bus_ride_id=' + id);
  // }

  dailySchedule(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"daily-bus-schedule-dashboard");
  }

  deletePass(id, userId): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+
      "cancel-bus-booking?bus_ride_id=" + id + "&user_id=" + userId,
      id
    );
  }

  /**
   * this endpoint returns the general portal
   * totals such as total earnings
   */
  adminDashboardTotal(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"admin-dashboard");
  }

  addNewsCategory(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-news-category", data);
  }

  getNewsCategory(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-news-category");
  }

  commissionPercentage(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"manage-commission-percentage", data);
  }

  getCommission(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-commission-percentage");
  }

  getAllCategories(offset): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-all-categories?&offset=" + offset);
  }

  addCategories(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-categories", data);
  }

  /**
   * remove categroy
   * @param categoryId
   */
  removeCategory(categoryId:number):Observable<any>{
    return this.httpClient.delete(`${environment.apiPrefix}remove-category/${categoryId}`);
  }

  /**
   * edit category
   * @param category_name
   * @param categoryId
   */
  editCategory(category_name:any, categoryId):Observable<any>{
    return this.httpClient.patch(`${environment.apiPrefix}edit-category/${categoryId}`,{category_name});
  }

  categoryImageUpload(data): Observable<any> {
    const fd = new FormData();
    fd.append("image", data);
    return this.httpClient.post(`${environment.apiPrefix}`+"category-image-upload", fd);
  }
  changePassword(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"change-password", data);
  }

  /**
   * get emergency notifications
   * @param limit
   * @param offset
   * @param sort
   * @param str
   * @param end
   */
  getEmergencyNotification(limit, offset, sort, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-emergency-notifications?limit=" +
        limit +
        "&offset=" +
        offset +
        "&sort=" +
        sort +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }

  /**
   * get single order
   * @param orderId
   */
  getSingleOrderDetails(orderId): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-single-order-detail?order_id=" + orderId);
  }
  getTnC(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-tnc");
  }
  addTnC(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"post-tnc", data);
  }

  /**
   * store firebase token
   * @param firebase_token
   */
  storeTokenDetails(firebase_token): Observable<any> {
    // const data = Object.assign({}, {token});
    return this.httpClient.post(`${environment.apiPrefix}`+"store-firebase-token", { firebase_token });
  }

  /**
   *
   * @param type
   * @param search
   * @param filter
   */
  getUsersForChat(type, search, filter): Observable<any> {
    // getUsers-for-chat
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-users-for-chat?type=" +
        type +
        "&search=" +
        search +
        "&filter=" +
        filter
    );
  }

  /**
   *
   * @param data
   */
  manageOrderStatus(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"manage-order-status", data);
  }

  /**
   * remove an order
   * @param orderId
   */
  removeAnOrder(orderId:number): Observable<any>{
    return this.httpClient.delete<any>(`${environment.apiPrefix}remove-order/${orderId}`);
  }

  /**
   * send msg
   * @param data
   */
  sendMessage(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"send-message", data);
  }
  getMessages(id): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-messages?receiver_id=" + id);
  }
  getUserScheduledRides(offset, name, filter, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-user-scheduled-rides?offset=" +
        offset +
        "&search=" +
        name +
        "&filter=" +
        filter +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }
  getDriverScheduledRides(offset, name, filter, str, end): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-driver-scheduled-rides?offset=" +
        offset +
        "&search=" +
        name +
        "&filter=" +
        filter +
        "&start_at=" +
        str +
        "&end_at=" +
        end
    );
  }
  getSingleScheduledRides(rideId): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-user-scheduled-single-ride?ride_id=" + rideId
    );
  }
  getContactUsWeb(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-contact-us");
  }
  getModules(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-modules");
  }
  getAllSubAdmin(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-subadmins");
  }

  /**
   * save admin
   * @param first_name
   * @param middle_name
   * @param last_name
   * @param email
   * @param password
   * @param modules
   */
  saveSubAdmin(first_name,middle_name,last_name,email,password, modules:any[]): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"save-sub-admin", {first_name,middle_name,last_name,email,password, modules});
  }
  deactivateSubAdmin(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"deactivate-subadmin", data);
  }
  activateSubAdmin(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"activate-subadmin", data);
  }

  /**
   * get single admin
   * @param subAdminId
   */
  getSingleSubAdmin(subAdminId): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}get-single-subadmin?sub_admin_id=${subAdminId}`);
  }

  /**
   * update admin
   * @param data
   */
  editSubAdmin(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"edit-subadmin", data);
  }

  /**
   * remove admin
   */
  removeAdmin(admin_id: string): Observable<any>{
    return this.httpClient.delete(`${environment.apiPrefix}remove-admin/${admin_id}`);
  }

  /**
   * roles/permissions
   */
  getOwnModules(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-own-modules");
  }
  getContactUs(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-contact-us");
  }
  sendPromotionalMsg(data): Observable<any> {
    return this.httpClient.post("send-promotional-message", data);
  }
  saveCancellationPercentage(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"save-cancellation-percentage", data);
  }
  getCancellationPercentage(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-cancellation-percentage");
  }
  addNewsSubCategory(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"add-news-subcategory", data);
  }
  getNewsSubCategory(categoryId): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+
      "get-news-subcategories?category_id=" + categoryId
    );
  }
  sendEmail(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"send-email", data);
  }
  unblockUserAccount(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"unblock-user-account", data);
  }
  sendInAppNotification(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"send-in-app-notification", data);
  }
  getUnreadNotificationCount(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-unread-notifications");
  }
  getPieDriverCount(): Observable<any> {
    return this.httpClient.get(`${environment.apiPrefix}`+"get-pie-driver-count");
  }
  approveDriver(data): Observable<any> {
    return this.httpClient.post(`${environment.apiPrefix}`+"approve-driver", data);
  }
}
