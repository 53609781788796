import {BrowserModule} from '@angular/platform-browser';
import {GoogleChartsModule} from 'angular-google-charts';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {LoginComponent} from './components/login/login.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '@app/core/core.module';
import {SnotifyModule, SnotifyPosition, SnotifyService} from 'ng-snotify';
import {MaterialModule} from '@app/material-module';
import {AppComponent} from '@app/app.component';
import {AppRoutingModule} from '@app/app-routing.module';
import {DashboardComponent} from '@app/components/dashboard/dashboard.component';
import {HomeComponent} from './components/home/home.component';
import {UsersComponent} from './components/users/users.component';
import {RidesComponent} from './components/rides/rides.component';
import {DriverComponent} from './components/drivers/driver.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {RideDetailsComponent} from './components/ride-details/ride-details.component';
import {UserDetailsComponent} from './components/user-details/user-details.component';
import {MomentModule} from 'ngx-moment';
import {VehiclesComponent} from './components/vehicles/vehicles.component';
import {AddVehicleComponent} from './components/vehicles/add-vehicle.component';
import {EditVehicleComponent} from './components/vehicles/edit-vehicle.component';
import {PoolRideComponent} from './components/rides/pool-ride.component';
import {DriverDetailComponent} from './components/drivers/driver-detail.component';
import {AgmCoreModule} from '@agm/core'; // @agm/core
import {AgmDirectionModule} from 'agm-direction';
import {DriverRideDetailsComponent} from './components/ride-details/driver-ride-details/driver-ride-details.component';
import {SupportComponent} from './components/support/support.component';
import {NewsComponent} from './components/news/news.component';
import {AddNewsComponent} from './components/news/add-news/add-news.component';
import {EditNewsComponent} from './components/news/edit-news/edit-news.component'; // agm-direction
import {EditorModule} from '@tinymce/tinymce-angular';
import {LiveRidesComponent} from './components/live-rides/live-rides.component';
import {StartedRidesComponent} from './components/started-rides/started-rides.component';
import {StartedRideDetailsComponent} from './components/started-ride-details/started-ride-details.component';
import {RatingReviewComponent} from './components/rating-review/rating-review.component';
import {EditUserComponent} from './components/users/edit-user/edit-user.component';
import {EditDriverComponent} from './components/drivers/edit-driver/edit-driver.component';
import {EditDriverVehicleComponent} from './components/drivers/edit-driver-vehicle/edit-driver-vehicle.component';
import {DruppShopComponent} from './components/drupp-shop/drupp-shop.component';
import {DruppShopAddComponent} from './components/drupp-shop/drupp-shop-add.component';
import {EditDruppShopComponent} from './components/drupp-shop/edit-drupp-shop.component';
import {NewsLetterComponent} from './components/news-letter/news-letter.component';
import {VehicleDetailsComponent} from './components/vehicle-details/vehicle-details.component';
import {MoreDetailsVehicleComponent} from './components/vehicle-details/more-details-vehicle.component';
import {OrderHistoryShopComponent} from './components/order-history-shop/order-history-shop.component';
import {BusComponent} from './components/bus/bus.component';
import {AddBusComponent} from './components/bus/add-bus.component';
import {EditBusComponent} from './components/bus/edit-bus.component';
import {BusRidesComponent} from './components/bus-rides/bus-rides.component';
import {AddBusRidesComponent} from './components/bus-rides/add-bus-rides.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { EditVehicleDetailsComponent } from './components/vehicle-details/edit-vehicle-details.component';
import { CategoryShopComponent } from './components/category-shop/category-shop.component';
import { AddNewsCategoryComponent } from './components/news/add-news-category/add-news-category.component';
import { CommissionComponent } from './components/commission/commission.component';
import { AddCategoryComponent } from './components/category-shop/add-category.component';
import { AddTermsConditionPolicyComponent } from './components/terms-condition-policy/add-terms-condition-policy.component';
import {environment} from '@environments/environment';

import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import { IndividualChatComponent } from './components/individual-chat/individual-chat.component';
import { MessageToAllComponent } from './components/message-to-all/message-to-all.component';
import { SingleOrderHistoryComponent } from './components/order-history-shop/single-order-history.component';
import { SchRidesComponent } from './components/sch-rides/sch-rides.component';
import { SingleSchRideComponent } from './components/sch-rides/single-sch-ride/single-sch-ride.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SubAdminComponent } from './components/sub-admin/sub-admin.component';
import {OwlModule} from 'ngx-owl-carousel';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AddNewsSubCategoryComponent } from './components/news/add-news-sub-category/add-news-sub-category.component';
import { KekeRidesComponent } from './components/rides/keke-rides/keke-rides.component';
import { SingleBusRideDetailsComponent } from './components/bus-rides/single-bus-ride-details/single-bus-ride-details.component';
import { GeneralNotificationComponent } from './components/general-notification/general-notification.component';
import {BsDropdownModule} from 'ngx-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { ChartModule } from 'angular2-chartjs';
import { StarRatingModule } from 'angular-rating-star';
import {cancellationManger} from '@app/components/cancellation-manger/cancellation-manger.component';
import {EarningsComponent} from '@app/components/earnings/earnings.component';
import {LiveUsersComponent} from '@app/components/live-users/live-users.component';
import { AnalysisComponent } from '@app/components/analysis/analysis.component';
import { MileageComponent } from '@app/components/mileages/mileage.component';
import { CouponComponent } from '@app/components/coupon/coupon.component';
import { EditCouponComponent } from '@app/components/coupon/edit-coupon.component';
import { AddCouponComponent } from '@app/components/coupon/add-coupon.component';
import { documentComponent } from '@app/components/document/document.component';
import { DxFunnelModule, DxPieChartModule } from 'devextreme-angular';
import { DxChartModule, DxSelectBoxModule } from 'devextreme-angular';
import { DateAgoPipe } from './core/pipes/date-ago.pipe';
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import { SearchComponent } from './components/search/search.component';
import { NotesLogComponent } from './components/notes-log/notes-log.component';
import {AutoLogoutService} from "@app/core/v2/services/auto-logout.service";
import { RolesComponent } from './components/roles/roles.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';


export const ToastConfig = {
  global: {
    newOnTop: true,
    maxOnScreen: 8,
    maxAtPosition: 8,
    filterDuplicates: false
  },
  toast: {
    type: 'error',
    showProgressBar: false,
    timeout: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    bodyMaxLength: 150,
    titleMaxLength: 16,
    backdrop: -1,
    icon: null,
    iconClass: null,
    html: null,
    position: SnotifyPosition.centerBottom,
    animation: {enter: 'fadeIn', exit: 'fadeOut', time: 400}
  },
  type: {
    prompt: {
      timeout: 0,
      closeOnClick: false,
      buttons: [
        {text: 'Ok', action: null, bold: true},
        {text: 'Cancel', action: null, bold: false},
      ],
      placeholder: 'Enter answer here...',
      type: 'prompt',
    },
    confirm: {
      timeout: 0,
      closeOnClick: false,
      buttons: [
        {text: 'Ok', action: null, bold: true},
        {text: 'Cancel', action: null, bold: false},
      ],
      type: 'confirm',
    },
    simple: {
      type: 'simple'
    },
    success: {
      type: 'success'
    },
    error: {
      showProgressBar: false,
      timeout: 5000,
      type: 'error'
    },
    warning: {
      type: 'warning'
    },
    info: {
      type: 'info'
    },
    async: {
      pauseOnHover: false,
      closeOnClick: false,
      timeout: 0,
      showProgressBar: false,
      type: 'async'
    }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    cancellationManger,
    documentComponent,
    EarningsComponent,
    LiveUsersComponent,
    AnalysisComponent,
    MileageComponent,
    CouponComponent,
    EditCouponComponent,
    AddCouponComponent,
    UsersComponent,
    RidesComponent,
    // AddApartmentDialogComponent,
    DriverComponent,
    RideDetailsComponent,
    UserDetailsComponent,
    VehiclesComponent,
    AddVehicleComponent,
    EditVehicleComponent,
    PoolRideComponent,
    DriverDetailComponent,
    DriverRideDetailsComponent,
    SupportComponent,
    NewsComponent,
    AddNewsComponent,
    EditNewsComponent,
    LiveRidesComponent,
    StartedRidesComponent,
    StartedRideDetailsComponent,
    RatingReviewComponent,
    EditUserComponent,
    EditDriverComponent,
    EditDriverVehicleComponent,
    DruppShopComponent,
    DruppShopAddComponent,
    EditDruppShopComponent,
    NewsLetterComponent,
    VehicleDetailsComponent,
    MoreDetailsVehicleComponent,
    OrderHistoryShopComponent,
    BusComponent,
    AddBusComponent,
    EditBusComponent,
    BusRidesComponent,
    AddBusRidesComponent,
    EditVehicleDetailsComponent,
    CategoryShopComponent,
    AddNewsCategoryComponent,
    CommissionComponent,
    AddCategoryComponent,
    AddTermsConditionPolicyComponent,
    IndividualChatComponent,
    MessageToAllComponent,
    SingleOrderHistoryComponent,
    SchRidesComponent,
    SingleSchRideComponent,
    NotificationsComponent,
    SubAdminComponent,
    AddNewsSubCategoryComponent,
    KekeRidesComponent,
    SingleBusRideDetailsComponent,
    GeneralNotificationComponent,
    DateAgoPipe,
    SearchComponent,
    NotesLogComponent,
    RolesComponent,
    AccessDeniedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CarouselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    GoogleChartsModule.forRoot(),
    HttpClientModule,
    CoreModule,
    NgxEchartsModule,
    SnotifyModule,
    EditorModule,
    MaterialModule,
    NgxPaginationModule,
    MomentModule,
    BsDropdownModule.forRoot(),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    ChartModule,
    StarRatingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AgmCoreModule.forRoot({ // @agm/core
      apiKey: 'AIzaSyAGzbD-bWySCHhvpKti_40Jsvti3TljxB8',
    }),
    AgmDirectionModule,     // agm-direction
    GooglePlaceModule,
    DxChartModule,
    DxSelectBoxModule,
    DxPieChartModule,
    DxFunnelModule
  ],
  providers: [
    {provide: 'SnotifyToastConfig', useValue: ToastConfig},
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    SnotifyService, AutoLogoutService
  ],
  // entryComponents: [AddApartmentDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
