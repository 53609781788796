import {ApplicationRef, Component, ComponentFactoryResolver, Injector, NgZone} from '@angular/core';
import 'firebase/analytics';
import {AngularFireDatabase} from '@angular/fire/database';
import 'firebase/auth';
import 'firebase/firestore';
import {RouterChangeService} from '@services/router-change.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  ignoreRequest = true;
  currentRoute;

  constructor(private fireDatabase: AngularFireDatabase, private routeChange: RouterChangeService) {
    this.routeChange.activeRoute.subscribe((data) => {
      this.currentRoute = data.url;
      console.log(this.currentRoute);
    });
    this.fireDatabase.database.ref('messages').on('child_added', (snapshot) => {
      if (!this.ignoreRequest) {
      }
    });
    this.fireDatabase.database.ref('messages').once('value', () => {
      this.ignoreRequest = false;
    });
    /*
    // Fetch the current user's ID from Firebase Authentication.
        const uid = firebase.auth().currentUser.uid;

    // Create a reference to this user's specific status node.
    // This is where we will store data about being online/offline.
        const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

    // We'll create two constants which we will write to
    // the Realtime database when this device is offline
    // or online.
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        const isOnlineForDatabase = {
          state: 'online',
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

    // Create a reference to the special '.info/connected' path in
    // Realtime Database. This path returns `true` when connected
    // and `false` when disconnected.
        // tslint:disable-next-line:only-arrow-functions
        firebase.database().ref('.info/connected').on('value', function(snapshot) {
          // If we're not currently connected, don't do anything.
          if (snapshot.val() == false) {
            return;
          }

          // If we are currently connected, then use the 'onDisconnect()'
          // method to add a set which will only trigger once this
          // client has disconnected by closing the app,
          // losing internet, or any other means.
          // tslint:disable-next-line:only-arrow-functions
          userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            // The promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

            // We can now safely set ourselves as 'online' knowing that the
            // server will mark us as offline once we lose connection.
            userStatusDatabaseRef.set(isOnlineForDatabase);
          });
        });

    */
  }

//     const firebaseConfig = {};
//
// // Initialize Firebase
//     firebase.initializeApp(firebaseConfig);
//     const messaging = firebase.messaging();
//     messaging.usePublicVapidKey('BPo7NIi7RwRcxXlMffXMJHLxPRG5_RzckK-uueq0MwibYHKj8rojRhIPmjlQ8zofCn1t2woRI4zvnWN5T3755bA');
//     Notification.requestPermission().then((permission) => {
//       if (permission === 'granted') {
//         console.log('Notification permission granted.');
//         // TODO(developer): Retrieve an Instance ID token for use with FCM.
//         // ...
//       } else {
//         console.log('Unable to get permission to notify.');
//       }
//     });
//
//
//   }

}
