import { Component, OnInit } from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {environment} from '@environments/environment';
import {FormBuilder} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-single-sch-ride',
  templateUrl: './single-sch-ride.component.html',
  styleUrls: ['./single-sch-ride.component.css']
})
export class SingleSchRideComponent implements OnInit {
  id;
  currency: any = environment.currency;
  schSingleRide: any = {
    status: '',
    source: '',
    distance: '',
    ride_type: '',
    ride_date: '',
    rider_name: '',
    total_fare: '',
    driver_name: '',
    destination: '',
    cancel_reason: '',
    source_latitude: '',
    source_longitude: '',
    destination_latitude: '',
    destination_longitude: ''
  };

  public lat;
  public lng;
  public origin: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
  };
  public markerOption = {
    origin: {
      icon: '../../../../assets/images/icon/source.png',
      draggable: false,
    },
    destination: {
      icon: '../../../../assets/images/icon/destination.png',

    },
  };

  constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute, private location: Location) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getSingleScheduleRides(this.id);

    });
  }
  getSingleScheduleRides(id) {
    this.apiServices.getSingleScheduledRides(id).subscribe((res) => {
      if (res.response) {
        this.schSingleRide = res.response;
        this.getDirection();
        console.log(this.schSingleRide);
      }
    }, (err) => {
      console.log(err);
    });
  }
  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getDirection() { // map

    this.lat = parseFloat(this.schSingleRide.source_latitude);
    this.lng = parseFloat(this.schSingleRide.source_longitude);
    /*this.origin = {lat: 24.799448, lng: 120.979021 };
    this.destination = {lat: 24.899524, lng: 120.976017 };*/
    /*this.origin = {lat: 22.68610, lng: 75.86016 };
    this.destination = {lat: 23.1765, lng: 75.7885 };*/

    this.origin = {
      lat: parseFloat(this.schSingleRide.source_latitude),
      lng: parseFloat(this.schSingleRide.source_longitude)
    };
    this.destination = {
      lat: parseFloat(this.schSingleRide.destination_latitude),
      lng: parseFloat(this.schSingleRide.destination_longitude)
    };
  }

}
