import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';
import {environment} from '@environments/environment';

declare var $: any;

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css']
})
export class DriverComponent implements OnInit {
  users = [];
  assetUrl: any = environment.assetsUrl;
  avatar_url: string;
  driverDetail:any;
  pa = 1;
  type = 2;
  nameSearchDriver = '';
  driverCurrentPage = 1;
  totalitems;
  str;
  end;
  locations;
  driverId;
  data;
  val;
  temp = true;
  calling: boolean;
  driverType = '';
  availability = '';
  public dateFilterForm: FormGroup;
  public blockDriverForm: FormGroup;
  public locationFilterForm: FormGroup;


  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.blockDriverForm = this.formBuilder.group({
      activation_date: ['', Validators.required],
    });
    this.locationFilterForm = this.formBuilder.group({
      location: ['', Validators.required]
    });

    this.getUsers(2);
  }

  /**
   * users
   * @param type
   */
  getUsers(type) {
    this.calling = true;
    this.apiServices.getUsers(type, this.driverCurrentPage, this.nameSearchDriver, '', this.driverType, this.availability).subscribe((res) => {
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
        this.calling = false;
      }
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
  }

  /**
   * users
   * @param avail
   */
  getAvailableUsers(avail) {
    this.availability = avail;
    this.apiServices.getUsers(this.type, this.driverCurrentPage, this.nameSearchDriver, '', this.driverType, avail).subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
  }

  /**
   *
   * @param filter
   */
  getDriverType(filter) {
    this.apiServices.getUsers(this.type, this.driverCurrentPage, this.nameSearchDriver, '', filter, this.availability).subscribe((res) => {
      if (res.response) {
        this.driverType = filter;
        this.users = res.response.data;
        this.totalitems = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
  }

  /**
   * @param $event
   */
  pageChangedDriver($event) {
    this.driverCurrentPage = $event;
    this.getUsers(2);
  }

  /**
   * get data
   */
  getData() {
    this.str = this.dateFilterForm.get('start').value;
    this.end = this.dateFilterForm.get('end').value;
    this.temp = false;
    this.apiServices.getUsersFilter(this.type, this.pa, this.str, this.end).subscribe((res) => {
        $('#myDateModel').modal('hide');
        this.users = res.response.data;
        this.totalitems = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
        this.router.navigate(['../dashboard/drivers']);
      },
      (err) => {
        this.snotifyService.error(err.error.message,'Error');
        this.calling=false;
      });
  }

  /**
   * location filter
   */
  getLocationData() {
    this.locations = this.locationFilterForm.get('location').value;
    this.apiServices.getUsers(this.type, this.driverCurrentPage, this.nameSearchDriver, this.locations, this.driverType, this.availability).subscribe((res) => {
      $('#myModelLocation').modal('hide');
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
  }

  /**
   *
   * @param userId
   */
  unBlock(userId) {
    this.apiServices.unblockUserAccount({id: userId}).subscribe((res) => {
      this.router.navigate(['../dashboard/drivers']);
      this.getUsers(2);
      this.snotifyService.success('Driver Unblocked', 'Success');
    }, (err) => {
      this.snotifyService.error(err.error.message, 'Error');
    });
  }

  /**
   *
   * @param i
   */
  getValue(i) {
    $('#blockDriverModal').modal('show');
    this.driverId = i;
    console.log(this.driverId);
  }

  /**
   * block driver
   */
  blockDriver() {
    this.data = {
      id: this.driverId,
      activation_date: this.blockDriverForm.value.activation_date
    };
    this.apiServices.removeUser(this.data)
        .subscribe((res) => {
      $('#blockDriverModal').modal('hide');
      this.snotifyService.success('Driver Blocked', 'Success');
      this.router.navigate(['../dashboard/drivers']);
      this.getUsers(2);
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
  }

  /**
   * remove driver
   */
  removeDriver(driver: any) {
    this.snotifyService.confirm(`Click Yes if You Want Permanently Remove ${driver.name}`, 'Are You Sure?', {
      timeout: 1000,
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
              this.apiServices.removeUser(driver.id)
                  .subscribe((res) => {
                    if(res){
                      this.snotifyService.remove();
                      this.snotifyService.success('Driver successfully removed from Drupp', 'Success', {
                        timeout: 1000,
                        showProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true
                      });
                      this.reloadCurrentRoute();
                    }
                  },
                  (err) => {
                    this.snotifyService.error(err.error.message);
                  })
          , bold: false
        },
        {
          text: 'No', action: () =>
              this.snotifyService.remove()
        },
      ]
    });
  }

  /**
   * approve
   * @param id
   */
  approveDrive(id) {
    const data = {
      driver_id: id
    };
    this.apiServices.approveDriver(data).subscribe((res) => {
      this.getUsers(2);
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
  }

  /**
   * filter
   */
  resetFilter() {
    this.type = 2;
    this.driverCurrentPage = 1;
    this.availability = '';
    this.nameSearchDriver = '';
    this.locations = '';
    this.driverType = '';
    this.apiServices.getUsers(this.type, this.driverCurrentPage, this.nameSearchDriver, this.locations, this.driverType , this.availability).subscribe((res) => {
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      this.snotifyService.error(err.error.message,'Error');
      this.calling=false;
    });
    }

  /**
   * currnt owner
   * @param avatar
   * @param driver
   */
    onCurrentDriverOwnerDocument(avatar: string,driver: any) {
     this.avatar_url= avatar;
     this.driverDetail=driver;
    }
  /**
   * reload
   */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
