import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-news-category',
  templateUrl: './add-news-category.component.html',
  styleUrls: ['./add-news-category.component.css']
})
export class AddNewsCategoryComponent implements OnInit {
  addNewsCategoryFormGroup: FormGroup;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.addNewsCategoryFormGroup = this.formBuilder.group({
      category_name: ['', Validators.required],
    });
  }

  addNewsCategory() {
      this.apiServices.addNewsCategory(this.addNewsCategoryFormGroup.value).subscribe((res) => {
          console.log(res);
          this.snotifyService.success('Added Successfully', 'Success');
          this.router.navigate(['../dashboard/news']);
        },
        (err) => {
          console.log(err);
        });
    }
}
