import {Directive, TemplateRef, ViewContainerRef, ComponentFactoryResolver, Input} from '@angular/core';
import {SnotifyService} from "ng-snotify";
import {AccessDeniedComponent} from "@app/components/access-denied/access-denied.component";

@Directive({
  selector: '[appAccessControl]'
})
export class AccessControlDirective {
  @Input('appAccessControl') appAccessControl;

  constructor(
      private snotifyService: SnotifyService,
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private cfr: ComponentFactoryResolver,
  ) { }

  ngOnInit() {
    this.checkPermissionExistence();
  }

  /**
   * check perms
   */
  checkPermissionExistence()
  {
    let type = this.appAccessControl[1];
    let comparison=this.comparePermissions(this.appAccessControl[0]);
    if(comparison)
    {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else
    {
      if (type === 'page')
      {
        const unauthorized = this.cfr.resolveComponentFactory(AccessDeniedComponent);
        this.viewContainer.createComponent(unauthorized);
      }
      else
      {
        //clear nav content
        this.viewContainer.clear();
      }
    }
  }
  /**
   *
   * @param currentUserPermission
   */
  comparePermissions(currentUserPermission): boolean
  {
    // Get the current active user
    let roles:string= localStorage.getItem('drupp_admin_permissions');
    let permissions:any[] = JSON.parse(roles);
    if(permissions!=null)
    {
      for (let perm of permissions)
      {
        if(perm["module"] === currentUserPermission) {
          return true;
        }
      }
      return false;
    }
    else
    {
      // current user localstorage =null;
    }
  }
}
