import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '@environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '@services/local-storage.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@app/core/http/api.service';
import {SnotifyService} from 'ng-snotify';
import {MessagingService} from '@services/messaging.service';
import {RouterChangeService} from '@services/router-change.service';
import {ApiService as ApiV2Services} from '@app/core/v2/services/api.service';
import {AutoLogoutService} from "@app/core/v2/services/auto-logout.service";
declare var $: any;
declare var Picker: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  changePasswordFormGroup: FormGroup;
  notifications = [];
  totalNotifications;
  picker;
  userPresent = false;
  searchForm: FormGroup;
  thisYear: number;

  globalSearchText : string='';

  limit = 3;
  ownModule = {
    modules: [{
      module_id: '',
      access: ''
    }]
  };
  modeId;
  access;
  moduleAccess = {};
  activeUrl = '/';
  adminName = '';
  loading = false;
  unreadCount:number=0;


  constructor(
              private apiServices: ApiService,
              private apiV2Service: ApiV2Services,
              private autoLogOff: AutoLogoutService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router,
              public localStorage: LocalStorageService,
              private route: ActivatedRoute,
              private routerChangeService: RouterChangeService,
              private messagingService: MessagingService,
              private auth: LocalStorageService) {
  }

  ngOnInit() {

    //search form
    this.searchFormValues();
    this.getAdminInfo();
    this.getUnreadNotificationCount();

    let date = new Date();
    this.thisYear = date.getFullYear();
  }

  /**
   * return admin details from localstorage
   */
  getAdminInfo(){
    const userToken = this.localStorage.getValue(environment.authKey, false);
    this.userPresent = !!userToken;
    if (this.userPresent) {
      this.adminName = this.auth.getValue('admin_name', false);
      this.getOwnModule();
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
      // console.log(this.messagingService);
      this.getUnreadNotificationCount();
    }
    this.changePasswordFormGroup = this.formBuilder.group({
      current_password: ['', Validators.required],
      new_password: ['', Validators.required],
    });
    this.routerChangeService.activeRoute.subscribe((e) => {
      this.activeUrl = e.url;
    });
  }

  /**
   * get emergency notifications
   */
  getUnreadNotificationCount() {
    this.loading = true;
    this.apiServices.getUnreadNotificationCount()
        .subscribe((res) => {
          this.loading = false;
          this.notifications=res.response.notifications;
          this.unreadCount = res.response.count;
        });
  }

  doLogout() {
    this.auth.remove(environment.authKey);
    this.router.navigate(['login']);
  }

  openPasswordChange() {
    $('#passwordChange').modal('show');
  }

  changePassword() {
    this.apiServices.changePassword(this.changePasswordFormGroup.value).subscribe((res) => {
          this.snotifyService.success('Updated Successfully', 'Success');
          $('#passwordChange').modal('hide');
          this.router.navigate(['../dashboard']);
        },
        (err) => {
          this.snotifyService.error(err.error.message, 'Failure');
          console.log(err);
        });
  }

  /**
   *
   * @param limit
   */

  notify(limit) {
    this.loading = true;
    this.apiServices.getEmergencyNotification(limit, '', '', '', '')
        .subscribe((res) => {
          this.loading = false;
          // this.notifications = res.response.data;
          this.totalNotifications = res.response.count;
          this.getUnreadNotificationCount();
        });
  }

  /**
   * read notification
   * @param notification_id
   */
  markNotificationAsRead(notification_id: number)
  {
    this.apiServices.getEmergencyNotification(5, '', '', '', '')
        .subscribe((res) => {
          if(res.success)
          {
            //notification read
            //this.toastrService.showSuccessToastr(res.message, "read");
          }
          else
          {
            //this.toastrService.showErrorToastr(res.message, 'error');
          }
        }, error => {
          console.log(error);
        })
  }

  /**
   * redirect
   */
  redirectToAllNotifications()
  {
    this.router.navigate(['dashboard/notification'], { queryParams: {}});// default key holder
  }


  getOwnModule() {
    this.apiServices.getOwnModules().subscribe((res) => {
      if (res.response) {
        this.ownModule = res.response;
        console.log(res);
        for (let i = 0; i < this.ownModule.modules.length; i++) {
          this.moduleAccess[this.ownModule.modules[i].module_id] = this.ownModule.modules[i].access;
          // console.log(this.checkedModule + 'checked');
        }
      } else if (!res.response) {
        this.ownModule = null;
      }
    }, (err) => {
      // console.log(err);
    });
  }


  showSubMenu($event) {
    const $activeItem = $($event.currentTarget);
    const dataItem = $activeItem.data('item');

    if (dataItem) {
      $('.sidebar-left-secondary').addClass('open');
      $('.sidebar-overlay').addClass('open');
    } else {
      $('.sidebar-left-secondary').removeClass('open');
      $('.sidebar-overlay').removeClass('open');
    }

    $('.sidebar-left-secondary').find('.childNav').hide();
    $('.sidebar-left-secondary').find(`[data-parent="${dataItem}"]`).show();
  }

  closeSubMenu() {
    $('.sidebar-left-secondary').removeClass('open');
    $('.sidebar-overlay').removeClass('open');
  }

  /**
   * search
   */
  searchFormValues(){
    this.searchForm=this.formBuilder.group({
      searchTerm:[""]
    })
  }

  /**
   * get value
   */
  get searchFormControl(){
    return this.searchForm.controls;
  }

  /**
   * global search
   */
  globalSearch(){

    let searchTerm=this.searchFormControl.searchTerm.value;
    this.apiV2Service.globalSearch(searchTerm)
        .subscribe((res)=>{
          localStorage.setItem("recently-viewed",JSON.stringify(res.response.result));
          this.router.navigate(['dashboard/search-results']);
        })
  }
}
