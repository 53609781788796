import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-order-history-shop',
  templateUrl: './order-history-shop.component.html',
  styleUrls: ['./order-history-shop.component.css']
})
export class OrderHistoryShopComponent implements OnInit {
  orders = [];
  totalOrders = '';
  orderCurrentPage = 1;
  calling: boolean;
  orderForm:FormGroup;
  currentOrder:any;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.getOrderHistory();
  }

  orderStatusForm(){
    this.orderForm=this.formBuilder.group({

    })
  }
  /**
   * get order history
   */
  getOrderHistory() {
    this.calling = true;
    this.apiServices.getOrderHistory(this.orderCurrentPage).subscribe((res) => {
        this.calling = false;
        this.orders = res.response.data;
        this.totalOrders = res.response.total;
      }, (err) => {
        console.log(err);
      }
    );
  }

  pageChangedOrder($event) {
    this.orderCurrentPage = $event;
    this.getOrderHistory();
  }

  /**
   * current order
   * @param orderDetails
   */
  getCurrentOrder(orderDetails){
    this.currentOrder=orderDetails;
  }
  /**
   * change status
   * @param order
   * @param newOrderStatus
   */
  changeDeliveryStatus(order,newOrderStatus) {
    const data = {
      order_id: order,
      status: newOrderStatus
    };
    this.apiServices.manageOrderStatus(data)
        .subscribe(() => {
          //hide modal
          $('#editOrderStatus').modal('hide');
          this.snotifyService.success('You have successfully updated the order status', 'Success');
          this.reloadCurrentRoute();
        }, (error) => {
          this.snotifyService.error(error.error.message, 'Error');
        });
  }

  /**
   * remove an order
   * @param orderId
   */
  removeOrder(orderId:number){
    this.apiServices.removeAnOrder(orderId)
        .subscribe((res)=>{
          this.snotifyService.success('You have successfully removed this order', 'Success');
        },error => {
          this.snotifyService.error('order removal failed, try again later', 'failed');
        })
  }
  /**
   * reload
   */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
