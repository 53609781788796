import {Component, OnInit, NgZone} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ApiService} from '@app/core/http/api.service';
import {SnotifyService} from 'ng-snotify';
import {environment} from '@environments/environment';
declare var $: any;


@Component({
  selector: 'app-drupp-shop-add',
  templateUrl: './drupp-shop-add.component.html',
  styleUrls: ['./drupp-shop-add.component.css']
})
export class DruppShopAddComponent implements OnInit {
  addProductFormGroup: FormGroup;
  assetUrl: any = environment.assetsUrl;
  categoryList: any = [{
    category: '',
    data: []
  }];
  productImages = [];
  parentId;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router,
              private route: ActivatedRoute,
              private zone: NgZone) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.parentId = params.get('parentId');
    });
  }

  ngOnInit() {
    this.productForm();
    this.getCategory();
  }

  /**
   * product form
   */
  productForm(){
    this.addProductFormGroup = this.formBuilder.group({
      product_name: ['', Validators.required],
      brand: ['', Validators.required],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  /**
   * add drupp shop product
   */
  addProduct() {
    const categoryArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.categoryList.length; i++) {
      categoryArray.push({categories: this.categoryList[i].category});
    }
    const inputData = Object.assign({}, {
      product_images: this.productImages,
      categories: categoryArray
    }, this.addProductFormGroup.value);

    this.apiServices.addProduct(inputData).subscribe((res) => {
          if(res){
            this.snotifyService.success('Added Successfully', 'Success');
            this.router.navigate(['../dashboard/drupp-shop']);
          }
          else{
            this.snotifyService.warning('Something went wrong, try again later!', 'Warning');
          }
        },
        (err) => {
          this.snotifyService.error('Server error occurred while processing your products, try again later', 'Error');
        });
  }

  /**
   * get category
   */
  getCategory() {
    this.apiServices.getCategory(0).subscribe((res) => {
      this.categoryList[0].data = res.response;
    });
  }

  /**
   * change category
   * @param index
   */
  changeCategory(index) {
    const id = this.categoryList[index].category;
    this.apiServices.getCategory(id).subscribe((res) => {
      if (index !== (this.categoryList.length - 1)) {
        this.categoryList.length = index + 1;
      }
      if (res.response.length) {
        this.categoryList.push({
          category: '',
          data: res.response
        });
      }
    }, (error) => {
      this.snotifyService.error('There was an issue with your category, try gain later', 'Error');
    });
  }

  // uploadImage(eve) {
  //   this.apiServices.uploadImage(eve).subscribe((res) => {
  //     this.productImages.push({'file_path': res.response.file_path, 'file_name': res.response.file_name});
  //   });
  // }
  /**
   * handle image upload
   * @param file
   */
  handleFileInput(file) {
    const formData = new FormData();
    formData.append('image', file.target.files[0]);
    $('#add-loader').modal('show');
    this.apiServices.uploadImage(formData)
        .subscribe(res => {
         if(res){
           // tslint:disable-next-line:only-arrow-functions
           setTimeout(function() {
             $('#add-loader').modal('hide');
           }, 300);
           this.zone.run(() => {
             this.productImages.push(res.response);
           });
         }else{
           this.snotifyService.error('There was an issue with your file upload, try gain later', 'Error');
         }
        }, error => {
          this.snotifyService.error(error.error.message+" \nYour image could not be uploaded now, check your image size / try again later", 'Error');
        });
  }

  /**
   * remove
   * @param id
   */
  removeGalleryImage(id) {
    this.productImages.splice(id, 1);
  }
}
