import {Component, OnInit} from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-bus',
  templateUrl: './bus.component.html',
  styleUrls: ['./bus.component.css']
})
export class BusComponent implements OnInit {
  bus = [];
  busSearch = '';
  id;
  busCurrentPage = 1;
  calling: boolean;
  totalitems;

  constructor(private apiServices: ApiService,
              private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getBus();
  }

  getBus() {
    this.calling = true;
    this.apiServices.getBus(this.busCurrentPage, this.busSearch).subscribe((res) => {
        this.calling = false;
        this.bus = res.response.data;
        this.totalitems = res.response.total;
      }, (err) => {
        console.log(err);
      }
    );
  }

  pageChangedBus($event) {
    this.busCurrentPage = $event;
    this.getBus();
  }

  // searchB(id) {
  //   this.apiServices.getBus(id).subscribe((res) => {
  //     console.log(id);
  //   });
  // }

  delete(id) {
    this.snotifyService.confirm('Click Yes if You Want Delete Bus', 'Are You Sure', {
      timeout: 2000,
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deleteBus(id).subscribe((res) => {
                this.snotifyService.remove();
                this.snotifyService.success('Deleted', 'Success', {
                  timeout: 2000,
                  showProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true
                });
                this.getBus();
              },
              (err) => {
                console.log(err);
              })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }
}
