import { Component, OnInit } from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '@services/local-storage.service';
import {MessagingService} from '@services/messaging.service';
import * as moment from 'moment';
declare var $: any;


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  str = '';
  end = '';
  notifications = [];
  totalNotifications;
  notificationCurrentPage = 1;
  totalnotify;
  limit = 10;
  calling: boolean;
  public dateFilterForm: FormGroup;
  sortBy: any = '';


  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router,
              public localStorage: LocalStorageService,
              private route: ActivatedRoute,
              private messagingService: MessagingService,
              private auth: LocalStorageService) { }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.getEmergencyNotification(this.limit);
  }

  /**
   * get emergency notifications
   * @param limit
   */
  getEmergencyNotification(limit) {
    this.calling = true;
    this.apiServices.getEmergencyNotification(limit , this.notificationCurrentPage, this.sortBy, this.str, this.end).subscribe((res) => {
      this.notifications = res.response.data;
      this.totalnotify = res.response.total;
      this.calling = false;
    });
  }

  /**
   * page notify
   * @param $event
   */
  pageChangedNotify($event) {
    this.notificationCurrentPage = $event;
    this.getEmergencyNotification(this.limit);
  }
  getData() {
    this.str = this.dateFilterForm.get('start').value;
    this.end = this.dateFilterForm.get('end').value;
    this.apiServices.getEmergencyNotification(this.limit , this.notificationCurrentPage, this.sortBy, this.str, this.end).subscribe((res) => {
        $('#myDateModel').modal('hide');
        this.notifications = res.response.data;
        this.totalnotify = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
        this.router.navigate(['../dashboard/notification']);
      },
      (err) => {
        console.log(err);
      });
  }
  sortDataNotify(sort) {
    this.apiServices.getEmergencyNotification(this.limit , this.notificationCurrentPage, sort, this.str, this.end).subscribe((res) => {
      this.notifications = res.response.data;
      this.totalnotify = res.response.total;
      },
      (err) => {
        console.log(err);
      });
  }

}
