import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient) { }


  /**
   * dashboard site statistics
   */
  getSiteStatistics(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}site-statistics`);
  }
  /**
   * dashboard ride statistics
   */
  getRideStatistics(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}ride-statistics`);
  }

  /**
   * rider statistics
   */
  getRiderStatistics(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}rider-statistics`);
  }

  /**
   * driver statistics
   */
  getDriverStatistics(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}driver-statistics`);
  }

  /**
   * @param searchTerm
   */
  public globalSearch(searchTerm: string): Observable<any>{
    return this.httpClient.post(`${environment.apiPrefix}search`, {search:searchTerm});
  }

  /**
   * daily reports
   */
  public getDailyReports(): Observable<any>{
    return this.httpClient.get(`${environment.apiPrefix}get-daily-rides` );
  }

  /**
   * yearly reports
   */
  public getYearlyReports(): Observable<any>{
    return this.httpClient.get(`${environment.apiPrefix}get-yearly-rides`);
  }
   /**
   * compare all time rides, pool vs bus vs keke
   */
  public getRidesComparisons(): Observable<any>{
    return this.httpClient.get(`${environment.apiPrefix}get-rides-comparisons`);
  }
  /**
   * compare all time rides, pool vs bus vs keke
   */
  public getRidesReportPerWeekPerYear(): Observable<any>{
    return this.httpClient.get(`${environment.apiPrefix}get-yearly-weeks-rides`);
  }

  /**
   * get earnings totals
   */
  public getTotalEarnings(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}earnings`);
  }
  /**
   * get commissions totals
   */
  public getTotalCommissions(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}drupp-commissions-report`);
  }
  /**
   * get earnings reports for graphs totals
   */
  public getEarningsAnalysisReport(): Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}earnings-analysis`);
  }

  /**
   * admin uses this endpoint to add notes about drivers and users
   */
  addUserNotes(title : string, description: string,user_id:string,category:string):Observable<any>{
    return this.httpClient.post<any>(`${environment.apiPrefix}add-notes`,{title, description,user_id,category});
  }

  /**
   * admin uses this endpoint to report issues on behalf of users
   */
  reportIssues(username : string, title: string,category:string,issue:string):Observable<any>{
    return this.httpClient.post<any>(`${environment.baseURL}report-issue`,{username,title ,category,issue});
  }

  /**
   * get user notes
   */
  getUserNotes(user_id:string):Observable<any>{
    return this.httpClient.post<any>(`${environment.apiPrefix}get-notes`,{user_id});
  }

  /**
   * get user reported issues
   */
  getReportedIssues():Observable<any>{
    return this.httpClient.get<any>(`${environment.apiPrefix}get-reported-issues`);
  }

  /**
   * update the reports
   */
  updateReportedIssues(requests, issue_id): Observable<any>{
    return this.httpClient.patch<any>(`${environment.apiPrefix}update-reported-issues/${issue_id}`,
        {comments:requests.comments, status: requests.status,assignee:requests.assignee});
  }

  /**
   * get user notes
   */
   removeUserNotes(note_id:number):Observable<any>{
    return this.httpClient.delete<any>(`${environment.apiPrefix}remove-notes/${note_id}`);
  }

  /**
   * get all users
   */
  getUsers():Observable<any>{
     return this.httpClient.get<any>(`${environment.apiPrefix}get-unlimited-users`);
  }

}
