import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {Support} from '@app/shared/models/support';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  UserSupport = [];
  DriverSupport = [];
  WebSupport = [];
  currentPageUser = 1;
  currentPageDriver = 1;
  currentPageWeb = 1;
  UserTotal: number;
  webTotal: number;
  nameSearch;
  riderSearch;
  webSearch;
  str;
  data;
  end;
  public dateFilterForm: FormGroup;
  public dateFilterFormDriver: FormGroup;
  DriverTotal: number;
  tempUser = true;
  tempDriver = true;
  tempWeb = true;

  constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });


    this.dateFilterFormDriver = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.getUserSupport();
    this.getDriverSupport();
  }

  getUserSupport() {
    this.apiServices.getSupport(1, this.currentPageUser).subscribe((res) => {
        this.UserSupport = res.response.data;
        this.UserTotal = res.response.total;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getDriverSupport() {
    this.apiServices.getSupport(2, this.currentPageDriver).subscribe((res) => {
        this.DriverSupport = res.response.data;
        this.DriverTotal = res.response.total;
        // console.log(this.DriverSupport);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  pageChangedUser($event: number) {
    this.currentPageUser = $event;
    if (this.tempUser == true) {
      this.getUserSupport();
    } else {
      this.getData();
    }
  }

  pageChangedDriver($eventD: number) {
    this.currentPageDriver = $eventD;
    if (this.tempDriver == true) {
      this.getDriverSupport();
    } else {
      this.getDataDriver();
    }
  }

  getData() {
    if (this.dateFilterForm.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterForm.get('start').value;
      this.end = this.dateFilterForm.get('end').value;
      console.log(this.str + this.end);
      this.apiServices.getSupportFilter(1, this.str, this.end, this.currentPageUser).subscribe((res) => {
          this.UserSupport = res.response;
          this.UserTotal = res.response.length;
          this.tempUser = false;
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/support']);
        },
        (err) => {
          console.log(err);
        });
      // console.log(this.editVehicleFormGroup.value);
    }
  }

  getDataDriver() {
    if (this.dateFilterFormDriver.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterFormDriver.get('start').value;
      this.end = this.dateFilterFormDriver.get('end').value;
      this.apiServices.getSupportFilter(2, this.str, this.end, this.currentPageDriver).subscribe((res) => {
          this.DriverSupport = res.response;
          this.DriverTotal = res.response.length;
          this.tempDriver = false;
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/support']);
        },
        (err) => {
          console.log(err);
        });
    }
  }


  getContactUsWeb() {
      this.apiServices.getContactUs().subscribe((res) => {
          this.WebSupport = res.response;
          this.webTotal = res.response.length;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  //Change Support Status For User/Driver
  changeSupportStatus(id, statusValue) {
    this.data = {
      support_id: id,
      status: statusValue
    };
    this.apiServices.changeSupportStatus(this.data).subscribe((res) => {
        this.ngOnInit();
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
