import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@environments/environment';
import {ApiService} from '@app/core/http/api.service';
import {Location} from '@angular/common';
import {AngularFireDatabase} from '@angular/fire/database';

// import {google} from "@agm/core/services/google-maps-types";


@Component({
  selector: 'app-ride-details',
  templateUrl: './ride-details.component.html',
  styleUrls: ['./ride-details.component.css']
})
export class RideDetailsComponent implements OnInit {
  currency: any = environment.currency;
  rideDetails: any = [{
    source_latitude: '',
    source_longitude: '',
    current_latitude: '',
    current_longitude: '',
    destination_latitude: '',
    destination_longitude: '',
    driver_id: '',
    status: '',
    total_fare: '',
    cancel_reason: '',
    driver_name: '',
    user_id: '',
    rider_name: '',
    source: '',
    destination: '',
    ride_date: '',
    distance: '',
    ride_type: '',
    latitude: '',
    longitude: ''
  }];
  id;


  public lat;
  public lng;
  public origin: any;
  public waypoints: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
  };
  public markerOptions: any = {
    origin: {
      icon: '../../../../assets/images/icon/source.png',
      draggable: false,
    },
   /* waypoints: {
      icon: '../../../assets/images/car.png',
      draggable: false,
    },*/
    destination: {
      icon: '../../../../assets/images/icon/destination.png',

    },
  };

  constructor(private route: ActivatedRoute, private fireDatabase: AngularFireDatabase,
              private apiServices: ApiService, private location: Location) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getRideDetails(this.id);
    });
    this.getLiveLocation();
  }

  getRideDetails(n) {
    this.apiServices.getSingleRide(n).subscribe((res) => {
        this.rideDetails = res.response.data;
        this.getDirection();
        // console.log(this.rideDetails);
        // console.log(parseFloat(this.rideDetails.source_longitude));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getDirection() { // map
    // this.lat = parseFloat(this.rideDetails.source_latitude);
    // this.lng = parseFloat(this.rideDetails.source_longitude);
    /*this.origin = {lat: 24.799448, lng: 120.979021 };
    this.destination = {lat: 24.899524, lng: 120.976017 };*/
    /*this.origin = {lat: 22.68610, lng: 75.86016 };
    this.destination = {lat: 23.1765, lng: 75.7885 };*/

    this.origin = {
      lat: parseFloat(this.rideDetails.source_latitude),
      lng: parseFloat(this.rideDetails.source_longitude)
    };
    console.log(this.origin.lat);
  /*  this.fireDatabase.database.ref('users/ride_info/' + this.id).on('value', (snapshot) => {
      this.waypoints = [{
        lat: parseFloat(snapshot.val().cLatitude),
        lng: parseFloat(snapshot.val().cLongitude)
      }];
    });*/
    this.destination = {
      lat: parseFloat(this.rideDetails.destination_latitude),
      lng: parseFloat(this.rideDetails.destination_longitude)
    };
  }

  getLiveLocation() {
    this.apiServices.getSingleRide(this.id).subscribe((res) => {
      this.rideDetails = res.response.data;
      /*      for (const user of this.rideDetails) {
              console.log(this.rideDetails);*/
      this.fireDatabase.database.ref('users/ride_info/' + this.id).on('value', (snapshot) => {
        // console.log(snapshot.val().cLatitude);
        /*  if (snapshot.val()) {
            this.zone.run(() => {
              user.availability = !!snapshot.val().availability;
            });
          }*/
      });

    });
  }
}
