import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {SnotifyService} from 'ng-snotify';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '@environments/environment';

declare var $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  str:any='';
  end:any='';
  assetUrl: any = environment.assetsUrl;
  avatar_url: string;
  riderDetails: any;
  public dateFilterForm: FormGroup;
  public locationFilterForm: FormGroup;
  public blockUserForm: FormGroup;
  locations = '';
  users = [];
  totalitems;
  p = 1;
  nameSearchRider = '';
  type = 1;
  riderCurrentPage = 1;
  userId;
  data;
  val;
  temp = true;
  todayDate;
  calling = true;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });

    this.blockUserForm = this.formBuilder.group({
      activation_date: ['', Validators.required]
    });
    this.todayDate = new Date().toISOString().slice(0, 10);
    this.getUsers(1);
  }

  getValue(i) {
    $('#blockUserModal').modal('show');
    this.userId = i;
  }

  blockUser() {
    this.data = {
      id: this.userId,
      activation_date: this.blockUserForm.value.activation_date
    };
    this.apiServices.blockUser(this.data).subscribe((res) => {
      $('#blockUserModal').modal('hide');
      this.snotifyService.success('User Blocked', 'Success');
      this.router.navigate(['../dashboard/users']);
      this.getUsers(1);
    }, (err) => {
      console.log(err);
    });
  }
  unBlock(userId) {
    this.apiServices.unblockUserAccount({id: userId}).subscribe((res) => {
      this.router.navigate(['../dashboard/users']);
      this.getUsers(1);
      this.snotifyService.success('User Un-blocked', 'Success');
    }, (err) => {
      this.snotifyService.error(err.error.message, 'Error');
    });
  }

  getUsers(type) {
    this.calling = true;
    this.apiServices.getUsers(type, this.riderCurrentPage, this.nameSearchRider, this.locations, '', '').subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  // pageChanged($event: number) {
  //   this.p = $event;
  //   if (this.temp == true) {
  //     this.getUsers(this.type, this.p);
  //   } else {
  //     this.getData();
  //   }
  // }
  pageChangedRider($event) {
    this.riderCurrentPage = $event;
    this.getUsers(1);
  }

  // Date Filter Users
  getData() {
    this.str = this.dateFilterForm.get('start').value;
    this.end = this.dateFilterForm.get('end').value;
    this.temp = false;
    this.apiServices.getUsersFilter(this.type, this.p, this.str, this.end).subscribe((res) => {
        $('#myDateModel').modal('hide');
        this.users = res.response.data;
        this.totalitems = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
        this.router.navigate(['../dashboard/users']);
      },
      (err) => {
        console.log(err);
      });
  }

  // For Location Filter
  getLocationData() {
    this.locations = this.locationFilterForm.get('location').value;
    console.log(this.locationFilterForm.value);
    this.apiServices.getUsers(this.type, this.riderCurrentPage, this.nameSearchRider, this.locations, '', '').subscribe((res) => {
      $('#myModelLocation').modal('hide');
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  resetFilter() {
    this.type = 1;
    this.riderCurrentPage = 1;
    this.nameSearchRider = '';
    this.locations = '';
    this.apiServices.getUsers(this.type, this.riderCurrentPage, this.nameSearchRider, this.locations, '', '').subscribe((res) => {
      if (res.response) {
        this.users = res.response.data;
        this.totalitems = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  /**
   * remove driver
   */
  removeRider(rider: any) {
    this.apiServices.removeUser(rider.id)
        .subscribe((res)=>{
          this.snotifyService.success('You have successfully removed a rider form Drupp', 'Success');
          this.reloadCurrentRoute();
        },error => {
          this.snotifyService.error(error.error.message,'Error');
        })
  }
  /**
   * reload
   */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  /**
   * view docs
   */
  onCurrentDriverDocument(avatar: string, rider: string){
    this.avatar_url=avatar;
    this.riderDetails=rider;
  }
}
