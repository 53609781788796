import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'supportStatus'
})
export class SupportStatusPipe implements PipeTransform {

  transform(value: any): any {
    if (value === 1) {
      return 'Under Review';
    } else if (value === 2) {
      return  'Treated';
    } else if (value === 3) {
      return  'Decline';
    } else if (value === 4) {
      return  'Resolved';
    }
  }

}
