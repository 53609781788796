import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notification'
})
export class NotificationPipe implements PipeTransform {

  transform(message: boolean, args?: any): string {
    if(message){
      return "read";
    }
    else{
      return "unread";
    }
  }

}
