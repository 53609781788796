import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {ApiService} from "@app/core/http/api.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-terms-condition-policy',
  templateUrl: './add-terms-condition-policy.component.html',
  styleUrls: ['./add-terms-condition-policy.component.css']
})
export class AddTermsConditionPolicyComponent implements OnInit {
  AddTnCGroup: FormGroup;
  id;
  terms = {
    updated_at: ''
  };
  tnc = {
    privacy_policy: '',
    terms_and_condition: ''
  };

  constructor(private snotifyService: SnotifyService,
              private formBuilder: FormBuilder,
              private apiServices: ApiService,
              private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getTnC();
    this.AddTnCGroup = this.formBuilder.group({
      terms_and_condition: ['', Validators.required],
      privacy_policy: ['', Validators.required],
    });
  }

  addTnC() {
    this.apiServices.addTnC(this.AddTnCGroup.value).subscribe((res) => {
        this.tnc.terms_and_condition = '';
        this.tnc.privacy_policy = '';
        this.snotifyService.success('TnC Successfully Uploaded', 'Success');
        this.router.navigate(['../../dashboard/add-t&c']);
        this.getTnC();
      },
      (err) => {
        console.log(err);
      });
  }

  getTnC() {
    this.apiServices.getTnC().subscribe((res) => {
      this.terms = res.response;
      this.AddTnCGroup.patchValue({
        terms_and_condition: res.response.terms_and_condition,
        privacy_policy: res.response.privacy_policy,
      });
      }, (err) => {
        console.log(err);
    });
  }
}
