import {Component, OnInit} from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-edit-vehicle-details',
  templateUrl: './edit-vehicle-details.component.html',
  styleUrls: ['./edit-vehicle-details.component.css']
})
export class EditVehicleDetailsComponent implements OnInit {
  editVehicleDetailsFormGroup: FormGroup;
  id;
  vehicles = [];

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editVehicleDetailsFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      vehicle_type_id: ['', Validators.required],
      license_number: ['', Validators.required],
      vehicle_name: ['', Validators.required],
      vehicle_model: ['', Validators.required],
      vehicle_number: ['', Validators.required],
      model_year: ['', Validators.required],
      vehicle_color: ['', Validators.required],
      chassis_number: ['', Validators.required],
      vehicle_brand: ['', Validators.required],
    });
    this.getSingleVehicleDetails(this.id);
    console.log(this.id);
    this.getVehicles();
  }

  getVehicles() {
    this.apiServices.getVehicle().subscribe((res) => {
        console.log(res);
        this.vehicles = res.response.data;
      }, ( err) => {
        console.log(err);
      }
    );
  }

  getSingleVehicleDetails(id) {
    this.apiServices.getSingleVehicleDetails(id).subscribe((res) => {
        this.editVehicleDetailsFormGroup.patchValue({
          id: res.response.id,
          vehicle_type_id: res.response.vehicle_type_id,
          license_number: res.response.license_number,
          vehicle_name: res.response.vehicle_name,
          vehicle_model: res.response.vehicle_model,
          vehicle_number: res.response.vehicle_number,
          model_year: res.response.model_year,
          vehicle_color: res.response.vehicle_color,
          chassis_number: res.response.chassis_number,
          vehicle_brand: res.response.vehicle_brand,
        });
      },
      (err) => {
        console.log(err);
      });
  }

  editVehicleDetails() {
    if (this.editVehicleDetailsFormGroup.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.apiServices.editSingleVehicleDetails(this.editVehicleDetailsFormGroup.value).subscribe((res) => {
          this.snotifyService.success('Edited Successfully', 'Success');
          this.router.navigate(['../dashboard/vehicle-details']);
        },
        (err) => {
          console.log(err);
        });
    }
  }
}
