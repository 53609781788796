import { Component, OnInit } from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from "ng-snotify";

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  permissions=[];
  roleForm: FormGroup;
  selectedPermissions: any[]=[];
  searchTerm: string;
  page = 1;
  count = 0;
  tableSize = 4;
  tableSizes = [2, 5, 10, 20, 30, 40];
  loading=true;
  submitted=false;


  constructor(
      private apiServices: ApiService,
      private snotifyService: SnotifyService,
      private fb:FormBuilder) { }

  ngOnInit() {
    this.newStaffRoles();
    this.getPermissions();
  }

  /**
   * form fields
   */
  newStaffRoles(){
    this.roleForm = this.fb.group({
      staffPassword:['',Validators.required],
      staffEmail:['',Validators.required],
      first_name:['',Validators.required],
      middle_name:['',Validators.required],
      last_name:['',Validators.required],
    })
  }

  /**
   * get form controls
   */
  get formControls(){
    return this.roleForm.controls;
  }

  /**
   * staff permissions
   */
  getPermissions() {
    this.apiServices.getModules()
        .subscribe((res) => {
          this.permissions = res.response;
          console.log(this.permissions);
          this.loading=false;
        }, (err) => {
          console.log(err);
        });
  }

  /**
   *
   * @param event
   */
  onPermissionCheckBoxChange(event: MouseEvent)
  {
    if (event.target['checked'] === true)
    {
      const selectedItems =event.target['id'];
      this.selectedPermissions.push({module_id:selectedItems});
    }
  }

  /**
   * add new staff
   */
  onAddingNewStaff() {
    this.submitted=true;
    let roles: any[] = this.selectedPermissions;
    let first_name = this.formControls.first_name.value;
    let middle_name = this.formControls.middle_name.value;
    let last_name = this.formControls.last_name.value;
    let email = this.formControls.staffEmail.value;
    let password = this.formControls.staffPassword.value;

    this.apiServices.saveSubAdmin(first_name,middle_name,last_name, email, password, roles)
        .subscribe((res) => {
          this.snotifyService.success(res.response.message, 'Success');
          this.submitted=false;
        }, error => {
          this.submitted=false;
          this.snotifyService.error(error.error.message, 'Error');
        })
  }

  /**
   * @param event
   */
  onTableDataChange(event)
  {
    this.page = event;
    this.getPermissions();
  }

  onTableSizeChange(event): void
  {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getPermissions();
  }
}
