import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRider'
})
export class FilterRiderPipe implements PipeTransform {

  transform(rides , riderSearch: string, emailSearch: string, companySearch: string){
    if (rides && rides.length){
        return rides.filter(item =>{
          if(item.rider_name == null){
            if ( riderSearch && item.Rider_name.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            &&  riderSearch && item.phone.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            &&  riderSearch && item.date.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            &&  riderSearch && item.issue.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            ){
              return false;
          }
          }
            else if (riderSearch && item.rider_name.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            && riderSearch && item.driver_name.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            && riderSearch && item.created_at.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            && riderSearch && item.review.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            && riderSearch && item.ride_source.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            && riderSearch && item.ride_destination.toLowerCase().indexOf(riderSearch.toLowerCase()) === -1
            ){
                return false;
            }
        
            return true;
       })
    }
    else{
        return rides;
    }
}
}
