import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-add-news-sub-category',
  templateUrl: './add-news-sub-category.component.html',
  styleUrls: ['./add-news-sub-category.component.css']
})
export class AddNewsSubCategoryComponent implements OnInit {
  category = {
    catId: '',
    subCatName: ''
  };
  newsCategory = [];
  addNewsSubCategoryFormGroup: FormGroup;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getNewsCategory();
    this.addNewsSubCategoryFormGroup = this.formBuilder.group({
      category_id: ['', Validators.required],
      news_subcategory: ['', Validators.required],
    });
  }
  getNewsCategory() {
    this.apiServices.getNewsCategory().subscribe((res) => {
      this.newsCategory = res.response;
    }, (err) => {
      console.log(err);
    });
  }
  addNewsSubCategory() {
    this.apiServices.addNewsSubCategory(this.addNewsSubCategoryFormGroup.value).subscribe((res) => {
        console.log(res);
        this.snotifyService.success('Added Successfully', 'Success');
        this.router.navigate(['../dashboard/news']);
      },
      (err) => {
        console.log(err);
      });
  }
}
