import {Component, NgZone, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {Router} from '@angular/router';
import * as firebase from 'firebase/app';
import {AngularFireDatabase} from '@angular/fire/database';

@Component({
  selector: 'app-live-rides',
  templateUrl: './live-rides.component.html',
  styleUrls: ['./live-rides.component.css']
})
export class LiveRidesComponent implements OnInit {
  zoom = 16;
  i = 0;
  id;
  source;
  destination;
  timer;
  ignoreRequest = true;
  private geoCoder;

  // initial center position for the map

  cpos: point = {
    lat: 8.4799,
    lng:  4.5418,
    id: 0
  };
  points: point[] = [];

  tmpPoints: any[]=[{
    latitude: 0,
    longitude: 0,
    rideId: 0,
  }];

  user = [];
  rideInfo = [];
  allRide: any = [];

  constructor(private apiServices: ApiService, private router: Router, private fireDatabase: AngularFireDatabase, private zone: NgZone) {
  }


  ngOnInit() {
    this.geoCoder = new google.maps.Geocoder;
    this.trackRides();

    // for (let user of this.user) {
    //   return firebase.database().ref('/users/drivers/' + user.id).once('value').then(snapshot => {
    //     const username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
    //     console.log(user.id);
    //   });
    // }

  }

  /**
   * track rides
   */
  trackRides() {
    this.apiServices.getLiveRides('', '')
        .subscribe((res) => {
              this.user = res.response.data;
              this.rideInfo = res.response.data;
              //ride-info info refers to all started rides
              for (let point of this.rideInfo ){
                this.tmpPoints.push({
                  lat:point["latitude"],
                  lng: point["longitude"],
                  id: point['id']
                });
              }
              this.i = 0;
              for (let y = 0; y < res.response.ride_info.length; y++) {
                const pos = this.tmpPoints[this.i];
                this.points.push(pos);
                this.i++;
              }
              this.getValue();
            },
            (err) => {
              console.log(err);
            }
        );
  }

  /**
   *
   * @param lat
   * @param long
   * @param infoWindow
   * @param gm
   * @param i
   */
  onMouseOver(lat, long, infoWindow, gm, i) {
    this.geoCoder.geocode({ 'location': { lat: lat, lng: long } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.source = results[0].formatted_address;
          console.log(this.source);
        }
      }
    });
    console.log(gm.address);
    // console.log(infoWindow.address);
    // this.source = this.rideInfo[i].source;
    // this.destination = this.rideInfo[i].destination;
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  onClickInfoView(id) {
    this.id = id;
    console.log(this.id);
    clearTimeout(this.timer);
    this.router.navigate(['../dashboard/started-ride-details', this.id]);
  }

// Clear The TimeOut
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  // Set Timeout for changing the Values
  getValue() {
    this.timer = setTimeout(() => {
      this.tmpPoints.length = 0;
      this.trackRides();
    }, 20000);
  }

  /*runLive() {

    for (const user of this.user) {
      this.fireDatabase.database.ref('users/ride_info').once('value', (snapshot) => {
        const data = snapshot.val();
        if (data.hasOwnProperty(user.id)) {
          this.zone.run(() => {
            user.latitude = !!data[user.id].cLatitude;
          });
          this.tmpPoints.push({lat: data[user.id].cLongitude, lng: data[user.id].cLatitude, id: data[user.id].rideID});
          console.log(this.tmpPoints);
          // tslint:disable-next-line:prefer-for-of

        } else {
          user.availability = false;
        }
        this.ignoreRequest = false;
      });
    }
    for (const user of this.user) {
      this.fireDatabase.database.ref('users/ride_info' + user.id).on('value', (snapshot) => {
        if (this.ignoreRequest) {
          const data = snapshot.val();
          console.log(snapshot.val());
          if (data.hasOwnProperty(user.id)) {
            this.zone.run(() => {
              user.latitude = !!data[user.id].cLatitude;
            });
            console.log(user.latitude);
          } else {
            user.availability = false;
          }
        }
      });
    }
  }*/
}

// just an interface for type safety.
// tslint:disable-next-line:class-name
interface point {
  lat: number;
  lng: number;
  id: number;
}


// tslint:disable-next-line:class-name
interface userInfo {
  base_fare: string;
  cancel_reason: null;
  complete_time: null;
  created_at: string;
  destination: string;
  destination_latitude: string;
  destination_longitude: string;
  distance: null;
  driver_id: number;
  driver_ride_id: null;
  duration: null;
  id: number;
  latitude: string;
  longitude: string;
  passengers_preference: null;
  per_km: string;
  per_minute_wait_charge: string;
  posted_by_driver: number;
  requested_at: null;
  ride_date: string;
  ride_option: number;
  ride_time: null;
  ride_type: number;
  source: string;
  source_latitude: string;
  source_longitude: string;
  start_time: null;
  status: number;
  total_fare: string;
  updated_at: string;
  user_fare: string;
  user_id: number;
  vehicle_name: string;
  vehicle_number: string;
  vehicle_type: number;
}
