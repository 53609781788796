import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      if (value === 1) {
        return 'Rider';
      } else if (value === 2) {
        return 'Driver';
      }
    }
  }

}
