import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'rideType'
})
export class RideTypePipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      if (value === 1) {
        return 'Single Ride';
      } else if (value === 2) {
        return 'Pooled Ride';
      }
    }
  }

}
