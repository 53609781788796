import {Component, Input, OnInit} from '@angular/core';
import {environment} from "@environments/environment";
import {FormGroup} from "@angular/forms";
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  totalSearchResults: number;
  userSearchResults: string[]=[];
  loading: any;
  assetUrl=environment.assetsUrl;
  riderCurrentPage=1;
  searchForm: FormGroup;
  searchResults: string;

  ngOnInit() {
    this.getSearchResults();
  }
  getSearchResults(){
    this.userSearchResults = JSON.parse(localStorage.getItem('recently-viewed'));
  }
  ngOnDestroy(){
    localStorage.removeItem('recently-viewed')
  }

  pageChangedRider($event: number) {

  }
  /**
   * view docs
   */
  onCurrentDriverDocument(search: string){
    this.searchResults=search
  }
}
