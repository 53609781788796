import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  NgZone,
  OnInit,
  Output
} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {LocalStorageService} from '@services/local-storage.service';
import {environment} from '@environments/environment';
import {SharedServiceService} from '@services/shared-service.service';
import {ApiService} from '@app/core/http/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';
import {FormBuilder} from '@angular/forms';

declare var $: any;


@Component({
  selector: 'app-individual-chat',
  templateUrl: './individual-chat.component.html',
  styleUrls: ['./individual-chat.component.css']
})
export class IndividualChatComponent implements OnInit {
  @Output() closed = new EventEmitter();
  ignoreRequest = true;
  adminId;
  chats = [];
  users = [];
  type = 2;
  temp = true;
  calling: boolean;
  totalitems;
  nameSearchDriver = '';
  driverCurrentPage = '';
  assetUrl: any = environment.assetsUrl;
  driverId = '';
  message;
  messages = [];

  allMessages = [];
  user = {
    first_name: '',
    last_name: '',
    profile_picture: ''
  };
  totalMsg = '';
  userId = [];
  availability;
  filter = 1;
  currentRoute;


  constructor(private fireDatabase: AngularFireDatabase, private zone: NgZone, private injector: Injector,
              public localStorage: LocalStorageService, private sharedService: SharedServiceService,
              public auth: LocalStorageService, private componentFactoryResolver: ComponentFactoryResolver,
              private applicationRef: ApplicationRef, private apiServices: ApiService, private router: Router,
              private apiService: ApiService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.fireDatabase.database.ref('users/drivers').on('child_added', (snapshot) => {
      // console.log(snapshot.val());
      if (!this.ignoreRequest) {
        // console.log(snapshot.val());
        this.adminId = parseInt(this.localStorage.getValue(environment.adminId, false), 10);
        const messageObj = snapshot.val();
        if (parseInt(messageObj.receiver_id, 10) === this.adminId) {
          if (this.chats.indexOf(messageObj.sender_id) === -1) {
            this.zone.run(() => {
              this.chats.push(messageObj.sender_id);
            });
          } else {
            messageObj.sender = 0;
            this.sharedService.pushMessageExistingChat.emit(messageObj);
          }
        }
      }
    });
    this.fireDatabase.database.ref('users/drivers').once('value', () => {
      this.ignoreRequest = false;
    });
    this.getUsersForChat(2, '');
    this.route.paramMap.subscribe(params => {
      this.driverId = params.get('driver_id');
    });
    if (this.driverId) {
      console.log(this.driverId);
      this.startChat(this.driverId);
    }
    // this.localStorage.remove('driverId');
  }

  getUsersForChat(type, filter) {
    this.calling = true;
    this.apiServices.getUsersForChat(type, this.nameSearchDriver, filter).subscribe((res) => {
      this.calling = false;
      this.users = res.response.data;
      this.totalitems = res.response.total;
      this.onlineStatus();
    }, (err) => {
      // console.log(err);
    });
  }

  /* showAsComponent(id) {
     // Create element
     const popup = document.createElement('chat-window-component');
     popup.style.cssFloat = 'right';
     popup.style.marginRight = '10px';

     // Create the component and wire it up with the element
     const factory = this.componentFactoryResolver.resolveComponentFactory(IndividualChatComponent);
     const popupComponentRef = factory.create(this.injector, [], popup);

     // Attach to the view so that the change detector knows to run
     this.applicationRef.attachView(popupComponentRef.hostView);

     // Listen to the close event
     popupComponentRef.instance.closed.subscribe((res) => {
       document.getElementById('chatWindowDiv').removeChild(popup);
       this.applicationRef.detachView(popupComponentRef.hostView);
       this.chats.splice(this.chats.indexOf(res), 1);
     });

     // Set the receiver id
     popupComponentRef.instance.adminId = id;

     // Add to the DOM
     document.getElementById('chatWindowDiv').appendChild(popup);
   }
   // sendMessage() {
   //   this.apiService.sendMessage({receiver_id: this.userId, message: this.message}).subscribe((res) => {
   //     this.zone.run(() => {
   //       this.message = '';
   //       res.response.sender = 1;
   //       this.messages.push(res.response);
   //       this.scrollingToBottom();
   //     });
   //   }, () => {
   //   });
   // }

   // if (!this.ignoreMsg) {
   //   const userId = parseInt(this.storageService.getValue(environment.userId, false), 10);
   //   const messageObj = snapshot.val();
   //   if (parseInt(messageObj.receiver_id, 10) === userId) {
   //     if (this.chats.indexOf(messageObj.sender_id) === -1) {
   //       this.zone.run(() => {
   //         this.chats.push(messageObj.sender_id);*/

  /*startChat(id) {
    this.auth.setValue('driverId', id);
    this.driverId = this.localStorage.getValue('driverId', false);
    this.apiService.getMessages(id).subscribe((res) => {
      this.allMessages = res.response.messages;
      this.user = res.response.user;
      this.totalMsg = res.response.total;
      this.scrollingToBottom();
    }, (error) => {
    });
  }*/
  startChat(id) {
    this.driverId = id;
    this.router.navigate(['../../dashboard/chat/' + id]);
    this.route.paramMap.subscribe(params => {
      this.driverId = params.get('driver_id');
    });
    this.driverId = id;
    this.getMessage(this.driverId);
  }

  getMessage(id) {
    console.log(id);
    console.log(this.driverId);
    this.apiService.getMessages(id).subscribe((res) => {
      this.allMessages = res.response.messages;
      this.user = res.response.user;
      this.totalMsg = res.response.total;
      this.scrollingToBottom();
    }, (error) => {
    });
  }

  sendMessage() {
    this.driverId = this.localStorage.getValue('driverId', false);
    // this.message = this.message.replace(/\n/g, '<br />');
    this.apiService.sendMessage({
      receiver_id: this.driverId,
      message: this.message,
      chat_id: 1 + '_' + this.driverId
    }).subscribe((res) => {
      this.zone.run(() => {
        this.message = '';
        res.response.sender = 1;
        this.messages.push(res.response);
        this.scrollingToBottom();
      });
      this.startChat(this.driverId);
    }, () => {
    });
  }

  scrollingToBottom() {
    $('.msg_card_body').animate({scrollTop: $('.msg_card_body').prop('scrollHeight')}, 1000);
  }

  onlineStatus() {
    // refresh data for all users: -->
    this.fireDatabase.database.ref('users/drivers').once('value', (snapshot) => {
      const data = snapshot.val();
      for (const user of this.users) {
        if (data.hasOwnProperty(user.id)) {
          user.availability = !!data[user.id].availability;
        } else {
          user.availability = false;
        }
      }
      this.ignoreRequest = false;
    });

    this.fireDatabase.database.ref('users/drivers').on('value', (snapshot) => {
      if (!this.ignoreRequest) {
        const data = snapshot.val();
        for (const user of this.users) {
          if (data.hasOwnProperty(user.id)) {
            this.zone.run(() => {
              user.availability = !!data[user.id].availability;
            });
          } else {
            user.availability = false;
          }
        }
      }
    });
    // refresh data for particular user: -->
    for (const user of this.users) {
      this.fireDatabase.database.ref('users/drivers/' + user.id).on('value', (snapshot) => {
        if (snapshot.val()) {
          this.zone.run(() => {
            user.availability = !!snapshot.val().availability;
          });
        }
      });
    }
  }
}
