import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService as ApiV2Service} from "@app/core/v2/services/api.service";
declare var $: any;
@Component({
  selector: 'app-notes-log',
  templateUrl: './notes-log.component.html',
  styleUrls: ['./notes-log.component.css']
})
export class NotesLogComponent implements OnInit {

    loading=true;
    submitting=false;
    issues: any[]=[];
    issue_id:number;
    issuesForm: FormGroup;

    //paging
    tableSize = 10;
    page = 1;
    count = 0;
    tableSizes = [2, 4, 10, 20];
    totalRecords: number;
    currentIndex: number;
    searchTerm: string="";


    constructor( private formBuilder: FormBuilder,
                 private snotifyService: SnotifyService,
                 private router: Router,
                 private route: ActivatedRoute,
                 private apiV2Service: ApiV2Service) {
    }

    ngOnInit() {
        this.getReportedIssues()
        this.issueForm();
        console.log(this.searchTerm);
    }

    /**
     * issue form
     */
    issueForm(){
        this.issuesForm=this.formBuilder.group({
            'username':['',Validators.required],
            'title':['',Validators.required],
            'category':['',Validators.required],
            'issue':['',Validators.required],
            'status':[''],
            'comments':[''],
            'assignee':[''],
        })
    }
    /**
     * get control values
     */
    get issueFormControls(){
        return this.issuesForm.controls;
    }

    /**
     * report an issue
     */
    reportIssue(){
        let report={
            username: this.issueFormControls.username.value,
            title: this.issueFormControls.title.value,
            category: this.issueFormControls.category.value,
            issue: this.issueFormControls.issue.value,
        }
        this.apiV2Service.reportIssues(report.username,report.title,report.category,report.issue)
            .subscribe((res)=>{
                if(res){
                    //hide modal
                    $('#reportUserIssue').modal('hide');
                    this.submitting = true;
                    this.snotifyService.success(`you have successfully submitted this issue`, 'Success');
                    this.reloadCurrentRoute();
                }else{
                    this.snotifyService.warning(`something went wrong reporting this issue, try again later`, 'Failed');
                }
            },error=>{
                this.snotifyService.error(`${error.error.message} something went wrong with this note`, 'Error');
            })
    }

    /**
     * retrieve issues from  riders and drivers
     */
    getReportedIssues(){
        this.apiV2Service.getReportedIssues()
            .subscribe((res)=>{
                if(res){
                    this.issues=res.response;
                    this.loading=false;
                }
                else{
                    this.snotifyService.warning(`something went wrong with this note`, 'Warning');
                }
            },error => {
                this.snotifyService.error(`something went wrong with this note`, 'Error');
            })
    }

    /**
     * @param id
     */
    getIssueId(id:number){
        this.issue_id=id;
    }

    /**
     * update reported issues
     */
    updateReportedIssue(){
        let report={
            comments: this.issueFormControls.comments.value,
            status: this.issueFormControls.status.value,
            assignee: this.issueFormControls.assignee.value,
        };
        this.apiV2Service.updateReportedIssues(report, this.issue_id)
            .subscribe((res)=>{
                if(res){
                    //hide modal
                    $('#updatedReportedUserIssue').modal('hide');
                    this.submitting = true;
                    this.snotifyService.success(`you have successfully updated this issue`, 'Success');
                    this.reloadCurrentRoute();
                }else{
                    this.snotifyService.warning(`something went wrong reporting this issue, try again later`, 'Failed');
                }
            },error=>{
                this.snotifyService.error(`${error.error.message} something went wrong with this note`, 'Error');
            })
    }
    /**
     * reload
     */
    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }
    /**
     * table pagination
     * @param event
     */
    onTableDataChange(event) {
        this.page = event;
        this.getReportedIssues();
    }

    onTableSizeChange(event): void {
        this.tableSize = event.target.value;
        this.page = 1;
        this.getReportedIssues();
    }
}
