import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@app/core/http/api.service';
import {Location} from '@angular/common';
import {SnotifyService} from 'ng-snotify';

@Component({
  selector: 'app-edit-driver-vehicle',
  templateUrl: './edit-driver-vehicle.component.html',
  styleUrls: ['./edit-driver-vehicle.component.css']
})
export class EditDriverVehicleComponent implements OnInit {

  vehicleDetails;
  public editDriverVehicle: FormGroup;
  id;
  vehicles = [];

  constructor(private route: ActivatedRoute, private apiServices: ApiService, private location: Location,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService, private router: Router) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editDriverVehicle = this.formBuilder.group({
      id: ['', Validators.required],
      vehicle_type_id: ['', Validators.required],
      license_number: ['', Validators.required],
      vehicle_name: ['', Validators.required],
      vehicle_number: ['', Validators.required],
      vehicle_model: ['', Validators.required],
      vehicle_brand: ['', Validators.required],
      vehicle_color: ['', Validators.required],
      chassis_number: ['', Validators.required]
    });
    this.getDriverDetails(this.id);
    this.getVehicles();
  }
  getVehicles() {
    this.apiServices.getVehicle().subscribe((res) => {
        console.log(res);
        this.vehicles = res.response.data;
      }, ( err) => {
        console.log(err);
      }
    );
  }

  getDriverDetails(n) {
    this.apiServices.getDriverVehicle(n).subscribe((res) => {
        this.vehicleDetails = res.response.data[0];
        this.editDriverVehicle.patchValue({
          id: this.id,
          vehicle_type_id: this.vehicleDetails.vehicle_type_id,
          license_number: this.vehicleDetails.license_number,
          vehicle_name: this.vehicleDetails.vehicle_name,
          vehicle_number: this.vehicleDetails.vehicle_number,
          vehicle_model: this.vehicleDetails.vehicle_model,
          vehicle_brand: this.vehicleDetails.vehicle_brand,
          vehicle_color: this.vehicleDetails.vehicle_color,
          chassis_number: this.vehicleDetails.chassis_number
        });
        console.log(res);
      },
      (err) => {
        console.log(err);
      }
    );

  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  edit() {
      this.apiServices.updateVehicleDriver(this.editDriverVehicle.value).subscribe((res) => {
          console.log(res);
          this.snotifyService.success('Edited Successfully', 'Success');
          this.router.navigate(['../dashboard/drivers']);
        },
        (err) => {
          this.snotifyService.error('Invalid Data Inserted', 'Failure');
          console.log(err);
        });
    }
}
