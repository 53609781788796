import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-edit-bus',
  templateUrl: './edit-bus.component.html',
  styleUrls: ['./edit-bus.component.css']
})
export class EditBusComponent implements OnInit {
  editBusFormGroup: FormGroup;
  id;
  name;
  data;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editBusFormGroup = this.formBuilder.group({
      bus_registration_number: ['', Validators.required],
      bus_number: ['', Validators.required],
      capacity: ['', Validators.required],
    });
    this.getBusDetails(this.id);
  }

  getBusDetails(id) {
    this.apiServices.getSingleBus(id).subscribe((res) => {
        this.editBusFormGroup.patchValue({
          bus_registration_number: res.response.bus_registration_number,
          bus_number: res.response.bus_number,
          capacity: res.response.capacity,
        });
      },
      (err) => {
        console.log(err);
      });
  }

  editBus() {
    if (this.editBusFormGroup.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.apiServices.editBus(this.editBusFormGroup.value, this.id).subscribe((res) => {
          console.log(res);
          this.snotifyService.success('Edited Successfully', 'Success');
          this.router.navigate(['../dashboard/bus']);
        },
        (err) => {
          console.log(err);
        });
    }
  }
}
