import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'onlineStatus'
})
export class OnlineStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value==1){
      return "online";
    }else {
      return "offline";
    }
  }

}
