import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {environment} from '@environments/environment';

import {ApiService} from '@app/core/http/api.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {GeoLocationService} from '@services/geo-location.service';

@Component({
  selector: 'app-started-ride-details',
  templateUrl: './started-ride-details.component.html',
  styleUrls: ['./started-ride-details.component.css']
})
export class StartedRideDetailsComponent implements OnInit {
  id;
  ignoreRequest = true;
  currency: any = environment.currency;
  rideDetails: any;
  markerlat;
  markerlong;
  time;
  coordinates;


  public lat;
  public lng;
  public origin: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
  };
  public markerOptions = {
    origin: {
      icon: '../../../../assets/images/icon/source.png',
      draggable: false,
    },
    current: {
      icon: '../../../../assets/images/car.png',
    },
    destination: {
      icon: '../../../../assets/images/icon/destination.png',
    },
  };
  private intVal: any;

  constructor(private route: ActivatedRoute,
              private fireDatabase: AngularFireDatabase,
              private router: Router,
              private apiServices: ApiService,
              private location: Location,
              private geoLocation: GeoLocationService,
              private zone: NgZone ) {

      this.fireDatabase.database.ref('users/drivers').on('child_added', (snapshot) => {
        console.log(snapshot.val());
        snapshot.forEach((child) => {
          console.log(child);
          this.intVal.push(child.val());
          console.log("intVal",this.intVal);
        });
        if (!this.ignoreRequest) {
        }
      });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.getRideDetails();
    this.getLocation(this.id);

    this.geoLocation.getPosition().subscribe(
      (pos: Position) => {
        this.coordinates = {
          latitude:  +(pos.coords.latitude),
          longitude: +(pos.coords.longitude)
        };
        console.log(this.coordinates);
      });
  }

  /**
   * ride details
   */
  getRideDetails() {
    this.apiServices.getSingleStarted(this.id)
        .subscribe((res) => {
              if (res.response) {
                this.rideDetails=res.response;
                this.lat = parseFloat(this.rideDetails.source_latitude);
                this.lng = parseFloat(this.rideDetails.source_longitude);
                this.origin = {
                  lat: parseFloat(this.rideDetails.source_latitude),
                  lng: parseFloat(this.rideDetails.source_longitude)
                };
                this.destination = {
                  lat: parseFloat(this.rideDetails.destination_latitude),
                  lng: parseFloat(this.rideDetails.destination_longitude)
                };
                this.getLiveLocation();
              }
            },
            (err) => {
              console.log(err);
            }
        );
  }

  ngOnDestroy() {
    clearTimeout(this.time);
  }

  /**
   * location
   * @param n
   */
  getLocation(n) {
    this.apiServices.getSingleStarted(n).subscribe((res) => {
          this.rideDetails = res.response;
          this.fireDatabase
              .database.ref('users/drivers/' + this.rideDetails.driver_id).on('value', (snapshot) => {
            if (snapshot.val()) {
              this.zone.run(() => {
                console.log(snapshot.val().longitude);
                this.markerlat = snapshot.val().latitude ;
                this.markerlong = snapshot.val().longitude;
              });
            }
          });
          // console.log(this.rideDetails.longitude + this.rideDetails.latitude);
          // console.log(res.response);
          this.getValue(n);
          // console.log(parseFloat(this.rideDetails.source_longitude));
        },
        (err) => {
          console.log(err);
        }
    );
  }

  /**
   *
   * @param n
   */
  getValue(n) {
    this.time = setTimeout(() => {
      this.getLocation(n);
    }, 10000);
  }

  /**
   * get live location
   */
  getLiveLocation() {
    // for (const user of this.rideDetails) {
    this.fireDatabase
        .database.ref('users/drivers/' + this.rideDetails.driver_id).on('value', (snapshot) => {
      console.log(snapshot.val().latitude);
      if (snapshot.val()) {
        this.zone.run(() => {
          console.log(snapshot.val().longitude);
          this.rideDetails.driver_id = !!snapshot.val().driver_id;
          console.log(this.rideDetails.driver_id);
        });
      }
    });
  }

}
