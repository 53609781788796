import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {environment} from '@environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';

@Component({
  selector: 'app-started-rides',
  templateUrl: './started-rides.component.html',
  styleUrls: ['./started-rides.component.css']
})
export class StartedRidesComponent implements OnInit {

  public dateFilterForm: FormGroup;
  rides = [];
  currency: any = environment.currency;
  nameSearch= '';
  str;
  end;
  calling: boolean;
  startedRidePage = 1;
  totalStartedRides = '';

  constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.getRides();
  }

  getRides() {
    this.calling = true;
    this.apiServices.getLiveRides(this.startedRidePage, this.nameSearch).subscribe((res) => {
      this.calling = false;
      this.rides = res.response.data;
      this.totalStartedRides = res.response.total;
    }, (err) => {
      console.log(err);
    });
  }

  // Date Filter Started Rides
  getData() {
    if (this.dateFilterForm.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterForm.get('start').value;
      this.end = this.dateFilterForm.get('end').value;
      this.calling = true;
      this.apiServices.getLiveRidesFilter(this.str, this.end).subscribe((res) => {
          this.calling = false;
          this.rides = res.response.data;
          console.log(res);
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/started-rides']);
        },
        (err) => {
          console.log(err);
        });
    }
  }
  pageChangedStartedRides($event) {
    this.startedRidePage = $event;
    this.getRides();
  }


}

interface Rides {
  base_fare: string;
  cancel_reason: null;
  complete_time: null;
  created_at: string;
  destination: string;
  destination_latitude: string;
  destination_longitude: string;
  distance: null;
  driver_id: number;
  driver_ride_id: null;
  duration: null;
  id: number;
  latitude: string;
  longitude: string;
  passengers_preference: null;
  per_km: string;
  per_minute_wait_charge: string;
  posted_by_driver: number;
  requested_at: null;
  ride_date: string;
  ride_option: number;
  ride_time: null;
  ride_type: number;
  source: string;
  source_latitude: string;
  source_longitude: string;
  start_time: null;
  status: 3;
  total_fare: string;
  updated_at: string;
  user_fare: string;
  user_id: number;
  vehicle_name: string;
  vehicle_number: string;
  vehicle_type: number;
}

