import {Component, OnInit} from '@angular/core';
import {ApiService as ApiV2Service} from '@app/core/v2/services/api.service';
import * as echarts from 'echarts';
import * as moment from 'moment';
@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: ['../../../assets/styles/css/material-dashboard.css', './earnings.component.css']
})
export class EarningsComponent implements OnInit {
  loading=true;
  totalCommissions:any;
  totalEarnings:any;
  dailyEarningsAnalysis: any[]=[];
  weeklyEarningsAnalysis: any[]=[];
  monthlyEarningsAnalysis: any[]=[];
  yearlyEarningsAnalysis: any[]=[];

  constructor(private apiService: ApiV2Service) {}

  ngOnInit() {
    this.getEarningsAnalysisReport();
    this.getTotalCommissions();
    this.getTotalEarnings();

    setTimeout(()=>{
        this.drawMonthlyIncomeAnalysis();
        this.drawYearlyIncomeAnalysis();
        this.drawTodayActivityGraph();
        this.drawWeeklyEarningsGraph();
        this.drawPastThirtyDaysEarningsBarCharts();
    },5000)
  }

  /**
   * get earnings totals
   */
  getTotalEarnings(){
    try{
      this.apiService.getTotalEarnings()
          .subscribe((res)=>{
            if(res){
                this.totalEarnings=res;
                this.loading = false;
            }else{
              //do sth
              console.log(res)
            }
          },error => {
            //do sth
            console.log(error)
            this.loading=false;
          })
    }catch (e) {
      console.log(e)
    }
  }
  /**
   * get commissions totals
   */
  getTotalCommissions(){
    try{
      this.apiService.getTotalCommissions()
          .subscribe((res)=>{
            if(res){
              this.totalCommissions=res;
                this.loading=false;
            }else{
              //do sth
              console.log(res)
            }
          },error => {
            //do sth
            console.log(error)
            this.loading=false;
          })
    }catch (e) {
      console.log(e)
    }
  }
  /**
   * get earnings reports for graphs totals
   */
  getEarningsAnalysisReport(){
    try{
      this.apiService.getEarningsAnalysisReport()
          .subscribe((res)=>{
            if(res){
              this.dailyEarningsAnalysis=res.drupp_earnings_today;
              this.weeklyEarningsAnalysis=res.drupp_earnings_this_week;
              this.monthlyEarningsAnalysis=res.drupp_earnings_this_month;
              this.yearlyEarningsAnalysis=res.drupp_earnings_this_year;
              this.loading=false;
            }else{
              //do sth
              console.log(res)
            }
          },error => {
            //do sth
            console.log(error)
            this.loading=false;
          })
    }catch (e) {
      console.log(e)
    }
  }

    /**
     * today activities graph
     */
    drawTodayActivityGraph(){
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("today-activities"));
        //Specify the configuration items and data of the icon

        let earnings=[];
        for (let earning of this.dailyEarningsAnalysis){
            earnings.push(earning['earnings']);
        }

        let option;
        option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Earnings', 'Commission', 'Tax', 'Driver Earnings', 'Fare']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                name:"Hours",
                type: 'category',
                boundaryGap: true,
                data: ['12','1', '2','3', '4','5', '6','7', '8','9', '10','11', '12','13','14','15','16','17','18','19','20','21','22','23']
            },
            yAxis: {
                name:"Naira",
                type: 'value'
            },
            series: [
                {
                    name: 'Earnings',
                    type: 'line',
                    stack: '总量',
                    data: earnings
                },
                {
                    name: 'Commissions',
                    type: 'line',
                    stack: '总量',
                    data: []
                },
                {
                    name: 'Tax',
                    type: 'line',
                    stack: '总量',
                    data: []
                },
                {
                    name: 'Driver Earnings',
                    type: 'line',
                    stack: '总量',
                    data: []
                },
                {
                    name: 'Fare Today',
                    type: 'line',
                    stack: '总量',
                    data: []
                }
            ]
        };
        //Display the data to the table
        myCharts.setOption(option);
    }
    /**
     * past 30 days rides bar charts
     */
    drawPastThirtyDaysEarningsBarCharts() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("last-thirty-days-activities"));
        //Specify the configuration items and data of the icon
        let option;
        let earnings:number[];
        let date:string[];
        for (let rides of this.monthlyEarningsAnalysis) {
            date.push(rides['created_at']);
            earnings.push(rides['drupp_earnings']);
        }

        option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                name:"Month",
                type: 'category',
                boundaryGap: true,
                data: date,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter: (function(value){
                        // @ts-ignore
                        return echarts.format.formatTime("d-MM", value);
                    }),
                    interval: 0
                },
            },
            yAxis: {
                name:"Naira",
                type: 'value'
            },
            series: [{
                data: earnings,
                type: 'bar',
                name: 'Daily Earnings',
                barWidth: '80%',
            }],
            color:['#0177D9'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

    /**
     * last 7 days
     */
    drawWeeklyEarningsGraph(){
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("weekly-activities"));
        //Specify the configuration items and data of the icon
        let option;
        let earnings=[];
        for (let  earning of this.weeklyEarningsAnalysis) {
            earnings.push(earning['earnings']);
        }
        option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                axisTick: {
                    alignWithLabel: true
                },
                name:"Days"
            },
            yAxis: {
                type: 'value',
                name:"Naira"
            },
            series: [{
                data: earnings,
                type: 'line',
                smooth: true
            }]
        };
        //Display the data to the table
        myCharts.setOption(option);
    }
    /**
     * past 30 days earnings bar charts done!
     */
    drawMonthlyIncomeAnalysis() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("monthly-earnings-bar"));
        //Specify the configuration items and data of the icon
        let option;
        let date=[];
        let earnings=[];
        for (let activity of this.monthlyEarningsAnalysis) {
            date.push(activity['day']);
            earnings.push(activity['earnings']);
        }

        option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: date,
                axisTick: {
                    alignWithLabel: true
                },
                name:"Date"

            },
            yAxis: {
                type: 'value',
                name:"Earnings"
            },
            series: [{
                data: earnings,
                type: 'bar',
                name: 'Earning',
            }],
            color:['#0177D9'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

    /**
     * past 1 year  earnings
     */
    drawYearlyIncomeAnalysis() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("yearly-rides-bar"));
        //Specify the configuration items and data of the icon
        let option;
        let date=[];
        let months=[1,2,3,4,5,6,7,8,9,10,11,12];
        let earnings=[];
        for (let earning of this.yearlyEarningsAnalysis) {
            if(months.includes(earning['month'])) {
                date.push(earning['month']);
                earnings.push(earning['earnings']);
            }
            else{
                earnings.push(0);
            }
        }
        option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
                axisTick: {
                    alignWithLabel: true
                },
                name:"Months"
            },
            yAxis: {
                type: 'value',
                name:"Earnings"
            },
            series: [{
                data: earnings,
                type: 'bar',
                name: 'Daily Rides',
                barWidth: '80%',
            }],
            color:['#0177D9'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

    /**
     * random number
     */
     getRandomInt() {
       return Array.from({length: 40}, () => Math.floor(Math.random() * 40));
    }
}
