import { Component, OnInit } from '@angular/core';
import {Rides} from '@app/shared/models/rides';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@environments/environment';
import {ApiService} from '@app/core/http/api.service';
import {Location} from '@angular/common';
import {Validators} from '@angular/forms';

@Component({
  selector: 'app-driver-ride-details',
  templateUrl: './driver-ride-details.component.html',
  styleUrls: ['./driver-ride-details.component.css']
})
export class DriverRideDetailsComponent implements OnInit {
  currency: any = environment.currency;
  rideDetails: any = [{
    source_latitude: '',
    source_longitude: '',
    destination_latitude: '',
    destination_longitude: '',
    driver_id: '',
    status: '',
    total_fare: '',
    cancel_reason: '',
    driver_name: '',
    user_id: '',
    rider_name: '',
    source: '',
    destination: '',
    ride_date: '',
    distance: '',
    ride_type: '',
    latitude: '',
    longitude: ''
  }];  id;
  public lat;
  public lng ;
  public origin: any;
  public destination: any;

  constructor(private route: ActivatedRoute, private apiServices: ApiService, private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getDriverRideDetails(this.id);
    });
  }

  getDriverRideDetails(n) {
    this.apiServices.getSingleDriverRide(n).subscribe((res) => {
        this.rideDetails = res.response.data[0];
        this.getDirection();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getDirection() { // map
    this.lat = parseFloat(this.rideDetails.source_latitude);
    this.lng = parseFloat(this.rideDetails.source_longitude);
    this.origin = {lat: parseFloat(this.rideDetails.source_latitude), lng: parseFloat(this.rideDetails.source_longitude)};
    this.destination = {lat: parseFloat(this.rideDetails.destination_latitude), lng: parseFloat(this.rideDetails.destination_longitude)};
  }
}
