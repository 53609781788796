import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'driverType'
})
export class DriverTypePipe implements PipeTransform {

  transform(driver_type: any, args?: any): any {
    if(driver_type==1){
      return "Car Driver";
    }
    else if(driver_type==2){
      return "Bus Driver";
    }
    else{
      return "Keke Driver";
    }
  }

}
