import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {Rides} from '@app/shared/models/rides';
import {environment} from '@environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';

@Component({
  selector: 'app-pool-ride',
  templateUrl: './pool-ride.component.html',
  styleUrls: ['./pool-ride.component.css']
})
export class PoolRideComponent implements OnInit {
  userRides = [];
  driverRides = [];
  currency: any = environment.currency;
  userPage = 1;
  driverPage = 1;
  rideSearch = '';
  nameSearchDriverRide = '';
  str;
  end;
  public dateFilterForm: FormGroup;
  public dateFilterFormDriver: FormGroup;
  temp;
  Ridetype = 2;
  userTotal: number;
  driverTotal: number;
  tempUser = true;
  tempDriver = true;
  calling = true;

  constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.dateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });


    this.dateFilterFormDriver = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.getUserRides(this.Ridetype);
    this.getDriverPooledRides(this.Ridetype);
  }

  getUserRides(type) {
    this.calling = true;
    this.apiServices.getRides(type, this.userPage , this.rideSearch, '').subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.userRides = res.response.data;
        this.userTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getDriverPooledRides(type) {
    this.calling = true;
    this.apiServices.getDriverRides(type, this.driverPage, this.nameSearchDriverRide, '').subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.driverRides = res.response.data;
        this.driverTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  pageChangedUser($event) {
    this.userPage = $event;
    if (this.tempUser == true) {
      this.getUserRides(this.Ridetype);
    } else {
      this.getData();
    }
  }

  // pageChangedDriver($eventD: number) {
  //   this.driverPage = $eventD;
  //   if (this.tempDriver == true) {
  //     this.getDriverPooledRides(this.Ridetype, this.driverPage);
  //   } else {
  //     this.getDataDriver();
  //   }
  // }
  pageChangedDriver($event) {
    this.driverPage = $event;
    if (this.tempDriver == true) {
      this.getDriverPooledRides(this.Ridetype);
    } else {
      this.getData();
    }
  }

//User Date Filter
  getData() {
    if (this.dateFilterForm.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterForm.get('start').value;
      this.end = this.dateFilterForm.get('end').value;
      this.calling = true;
      this.apiServices.getRidesFilter(this.Ridetype, this.userPage, this.str, this.end).subscribe((res) => {
          this.calling = false;
          this.userRides = res.response.data;
          this.userTotal = res.response.total;
          this.tempUser = false;
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/poolRides']);
        },
        (err) => {
          console.log(err);
        });
      // console.log(this.editVehicleFormGroup.value);
    }
  }

  //Driver Date Filter
  getDataDriver() {
    if (this.dateFilterFormDriver.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterFormDriver.get('start').value;
      this.end = this.dateFilterFormDriver.get('end').value;
      this.apiServices.getDriverRidesFilter(this.Ridetype, this.driverPage, this.str, this.end).subscribe((res) => {
          this.driverRides = res.response.data;
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/poolRides']);
        },
        (err) => {
          console.log(err);
        });
    }
  }
  getFilter(filter) {
    this.apiServices.getRides(this.Ridetype, this.userPage, this.rideSearch, filter).subscribe((res) => {
      if (res.response) {
        this.userRides = res.response.data;
        this.userTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getFilterDriver(filter) {
    this.apiServices.getDriverRides(this.Ridetype, this.driverPage, this.nameSearchDriverRide, filter).subscribe((res) => {
      if (res.response) {
        this.driverRides = res.response.data;
        this.driverTotal = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  resetFilterUser() {
    this.Ridetype = 2;
    this.userPage = 1;
    this.rideSearch = '';
    this.apiServices.getRides(this.Ridetype, this.userPage , this.rideSearch, '').subscribe((res) => {
      if (res.response) {
        this.userRides = res.response.data;
        this.userTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  resetFilterDriver() {
    this.Ridetype = 1;
    this.driverPage = 1;
    this.nameSearchDriverRide = '';
    this.apiServices.getDriverRides(this.Ridetype, this.driverPage, this.nameSearchDriverRide, '').subscribe((res) => {
      if (res.response) {
        this.driverRides = res.response.data;
        this.driverTotal = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
}
