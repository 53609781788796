import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@app/core/http/api.service';
import {Location} from '@angular/common';
import {SnotifyService} from 'ng-snotify';


@Component({
  selector: 'app-edit-driver',
  templateUrl: './edit-driver.component.html',
  styleUrls: ['./edit-driver.component.css']
})
export class EditDriverComponent implements OnInit {
  userDetails;
  public editDriverDetails: FormGroup;
  id;

  constructor(private route: ActivatedRoute, private apiServices: ApiService,
              private location: Location, private formBuilder: FormBuilder,
              private snotifyService: SnotifyService, private router: Router) {
  }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editDriverDetails = this.formBuilder.group({
      id: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      country_code: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required]
    });
    this.getDriverDetails(this.id);
  }

  getDriverDetails(n) {
    this.apiServices.getSingleUser(n).subscribe((res) => {
        this.userDetails = res.response.data;
        this.editDriverDetails.patchValue({
          id: res.response.data.id,
          first_name: res.response.data.first_name,
          last_name: res.response.data.last_name,
          email: res.response.data.email,
          country_code: '+' + res.response.data.country_code,
          phone: res.response.data.phone,
          address: res.response.data.address
        });
        console.log(this.userDetails);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  edit() {
    if (this.editDriverDetails.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      console.log(this.editDriverDetails.value);
      this.apiServices.updateDriver(this.editDriverDetails.value).subscribe((res) => {
          console.log(res);
          this.snotifyService.success('Edited Successfully', 'Success');
          this.router.navigate(['../dashboard/drivers']);
        },
        (err) => {

          this.snotifyService.error('Invalid Data Inserted', 'Failure');
          console.log(err);
        });
    }
  }

}
