import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.css']
})
export class EditCouponComponent implements OnInit {
  editBusFormGroup: FormGroup;
  id;
  name;
  data;
  disabled = false;
  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editBusFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      status: ['', Validators.required],

    });
    this.getBusDetails(this.id);
  }

  getBusDetails(id) {
    this.apiServices.postGeneralMethod("get-coupon-category",{id:id}).subscribe((res) => {
        this.editBusFormGroup.patchValue({
          name: res.response.data.name,
          price: res.response.data.price,
          status: res.response.data.status,
        });
      },
      (err) => {
        console.log(err);
      });
  }

  editBus() {

    if (this.editBusFormGroup.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.disabled = true;
      this.editBusFormGroup.value.id=this.id
      this.apiServices.postGeneralMethod('edit-coupon-category',this.editBusFormGroup.value).subscribe((res) => {
          console.log(res);
          this.snotifyService.success('Edited Successfully', 'Success');
          this.router.navigate(['../dashboard/coupon']);
        },
        (err) => {
          console.log(err);
          this.disabled = false;
          this.snotifyService.error('Db action error', 'Error');
        });
    }
  }
}
