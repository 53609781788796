import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'busFilter'
})
export class BusFilterPipe implements PipeTransform {

  transform(busSearch, Search: string) {
    if (busSearch && busSearch.length) {
      return busSearch.filter(item => {
        if (busSearch && item.bus_registration_number.toLowerCase().indexOf(busSearch.toLowerCase()) === -1
          && busSearch && item.bus_number.toLowerCase().indexOf(busSearch.toLowerCase()) === -1
          && busSearch && item.capacity.toLowerCase().indexOf(busSearch.toLowerCase()) === -1) {
          return false;
        }
        return true;
      });
    } else {
      return busSearch;
    }
  }

}
