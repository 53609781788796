import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(status: any, args?: any): any {
    if(status==1){
      return "ordered";
    }
    else if(status==2){
      return "Shipped"
    }
    else if(status==3){
      return "Delivered"
    }
    else if(status==4){
      return "Cancelled"
    }
    else if(status==5){
      return "Failed"
    }
    else if(status==6){
      return "Applied For Return"
    }
    else{
      return "Returned Order";
    }
  }

}
