import { Component, OnInit } from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {Vehicles} from '@app/shared/models/vehicles';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
vehicles = [];
calling: boolean;
  constructor(private apiServices: ApiService,
              private snotifyService: SnotifyService,
              private router: Router) { }

  ngOnInit() {
    this.getVehicles();
  }

  getVehicles() {
    this.calling = true;
    this.apiServices.getVehicle().subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.vehicles = res.response.data;
      }
    }, ( err) => {
        console.log(err);
      }
    );
  }
  delete(id) {
    this.apiServices.deleteVehicle(id).subscribe((res) => {
      console.log(res);
      this.snotifyService.success('Deleted', 'Success');
      this.getVehicles();
      // this.router.navigate(['../dashboard/vehicles']);
    },
      (err) => {console.log(err);
    });
  }
}
