import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vehicleType'
})
export class VehicleTypePipe implements PipeTransform {

  transform(value: string): string {
    if(value=="1"){
      return "Non - AC";
    }else if(value=="2"){
      return "AC";
    }
    else if(value=="3"){
      return "Keke";
    }
    else{
      return "N/A";
    }
  }

}
