import {Component, OnInit} from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {FormBuilder} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-single-order-history',
  templateUrl: './single-order-history.component.html',
  styleUrls: ['./single-order-history.component.css']
})
export class SingleOrderHistoryComponent implements OnInit {
  id;
  assetUrl: any = environment.assetsUrl;
  singleOrder: any;
  loading=true;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit() {
    // this.getSingleOrderHistory();
  }
}
