import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {ApiService} from '@app/core/http/api.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit {
  addNewsGroup: FormGroup;
  id;
  news = [];
  file: File;
  mimeType;
  reader;
  imagePath;
  uploadData;
  imgURL: any;
  mypath;
  head;
  des;
  data;
  subCategoryId = '';
  catId = '';
  newsCategory = [];
  newsSubCategory = [];

  constructor(private snotifyService: SnotifyService,
              private formBuilder: FormBuilder,
              private apiServices: ApiService,
              private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getNewsCategory();
    this.addNewsGroup = this.formBuilder.group({
      category_id: ['', Validators.required],
      sub_category_id: ['', Validators.required],
      headline: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  preview(files) {
    this.file = files[0];
    if (files.length === 0) {
      return;
    }
    this.mimeType = files[0].type;
    if (this.mimeType.match(/image\/*/) == null) {
      this.snotifyService.error('Invalid Image', 'Error');
      return;
    }
    this.reader = new FileReader();
    this.imagePath = files;
    this.reader.readAsDataURL(files[0]);
    this.reader.onload = (event) => {
      this.imgURL = this.reader.result;
    };

    this.uploadData = new FormData();
    this.uploadData.append('image', this.file, this.file.name);

    this.apiServices.newsImageUpload(this.uploadData).subscribe((res) => {
      this.mypath = res.response.file_path;
      console.log(this.mypath);
    }, (err) => {
      console.log(err);
    });
  }

  addNews() {
    this.head = this.addNewsGroup.value.headline;
    this.des = this.addNewsGroup.value.description;
    this.subCategoryId = this.addNewsGroup.value.sub_category_id;
    this.data = {
      news_image: this.mypath,
      news_detail: this.des,
      headline: this.head,
      sub_category_id: this.subCategoryId
    };
    this.apiServices.newsAdd(this.data).subscribe((res) => {
        this.snotifyService.success('News Successfully Added', 'Success');
        this.router.navigate(['../../dashboard/news']);
        this.getNews();
      },
      (err) => {
        console.log(err);
      });
  }

  getNews() {
    this.apiServices.getNews().subscribe((res) => {
        this.news = res.response;
      }, (err) => {
        console.log(err);
      }
    );
  }

  getNewsCategory() {
    this.apiServices.getNewsCategory().subscribe((res) => {
      this.newsCategory = res.response;
    }, (err) => {
      console.log(err);
    });
  }

  changeCategory(id) {
    this.apiServices.getNewsSubCategory(id).subscribe((res) => {
    this.newsSubCategory = res.response;
  }, (err) => {
    console.log(err);
  });
  }
}
