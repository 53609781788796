import { Pipe, PipeTransform } from '@angular/core';
import { Rides } from '@app/shared/models/rides';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(rides , nameSearch: string, emailSearch: string, companySearch: string){
    if (rides && rides.length){
        return rides.filter(item =>{
          if(item.driver_name == null){
            if (nameSearch && item.Rider_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
             && nameSearch && item.phone.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.date.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
             && nameSearch && item.issue.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1){
              return false;
          }
          }
            else if (nameSearch && item.driver_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.rider_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1  
            && nameSearch && item.created_at.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.review.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.ride_source.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.ride_destination.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          ){
                return false;
            }
            return true;
       })
    }
    else{
        return rides;
    }
}
}
