import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';
import {environment} from '@environments/environment';

declare var $: any;
@Component({
  selector: 'app-drupp-shop',
  templateUrl: './drupp-shop.component.html',
  styleUrls: ['./drupp-shop.component.css']
})
export class DruppShopComponent implements OnInit {
  assetUrl: any = environment.assetsUrl;
  loading = true;
  products:any[]=[];
  productDetails:any;

  //filter
  productSearch = '';
  min = '';
  max = '';
  public priceRangeFilterForm: FormGroup;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    //get products
    this.getAllDruppShopProducts();
    this.priceRangeFilter();
  }

  /**
   * price range filter
   */
  priceRangeFilter(){
    this.priceRangeFilterForm = this.formBuilder.group({
      min: ['', Validators.required],
      max: ['', Validators.required],
    });
  }

  /**
   * get all drupp shop products
   */
  getAllDruppShopProducts() {
    this.apiServices.getDrupShopProducts()
    .subscribe((res) => {
      if (res.response) {
          this.products = res.response.data;
          this.loading = false;
        }
        else{
          console.log('error');
        }
      }, (err) => {
        console.log(err);
      }
    );
  }
  /**
   * redirect to product edit
   * @param product_id
   */
  editProduct(product_id){
    this.router.navigate([`dashboard/edit-product/${product_id}`]);
  }

  /**
   * remove an item from list
   * @param productId
   */
  removeDruppShopProduct(productId:number) {
    this.snotifyService.confirm('Click Yes if You Want Delete Product', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deleteProduct(productId).subscribe((res) => {
                this.snotifyService.remove();
                this.snotifyService.success('Product Deleted', 'Success');
                this.getAllDruppShopProducts();
              },
              (err) => {
                console.log(err);
              })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }

  /**
   * show product details on product click
   * @param product
   */
  showProductDetailsModal(product){
    this.productDetails=product;
  }

  /**
   * filter
   */
  getFilteredDruppShopProducts() {
    this.min = this.priceRangeFilterForm.get('min').value;
    this.max = this.priceRangeFilterForm.get('max').value;
    if (this.max < this.min)  {
      this.snotifyService.error('Min Price cannot be greater than max price', 'Error');
      return false;
    }
    this.apiServices.getFilteredDruppShopProducts('', this.productSearch, this.min, this.max, '' )
        .subscribe((res) => {
          $('#filterPriceRangeModel').modal('hide');
          this.products = res.response.data;
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/drupp-shop']);
        },
        (err) => {
          console.log(err);
        });
  }

  /**
   * check stock
   * @param check
   */
  stockCheck(check) {
    this.apiServices.getFilteredDruppShopProducts('', this.productSearch, this.min, this.max, check).subscribe((res) => {
          this.products = res.response.data;
        },
        (err) => {
          console.log(err);
        });
  }
}
