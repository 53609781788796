import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "@app/core/http/api.service";
import {Location} from "@angular/common";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-more-details-vehicle',
  templateUrl: './more-details-vehicle.component.html',
  styleUrls: ['./more-details-vehicle.component.css']
})
export class MoreDetailsVehicleComponent implements OnInit {
  id;
  moreDetails: any = {
    vehicle_number: '',
    vehicle_name: '',
    vehicle_brand: '',
    vehicle_model: '',
    vehicle_color: '',
    vehicle_images: [{
      boot: '',

    }]
  };
  assetUrl: any = environment.assetsUrl;
  loading: boolean=true;
  imageUrl: string;


  constructor(private route: ActivatedRoute, private apiServices: ApiService, private location: Location) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.getSingleVehicleDetails(this.id);
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  getSingleVehicleDetails(id) {
    this.apiServices.getSingleVehicleDetails(id).subscribe((res) => {
      if (res.response) {
        this.moreDetails = res.response;
        this.loading=false;
      }
    });
  }

  onVehicleImageClick(vehicle_image: string){
    this.imageUrl = vehicle_image;
  }
}
