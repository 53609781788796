import { Component, OnInit } from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {ActivatedRoute, Router} from '@angular/router';
import {News} from '@app/shared/models/news';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})
export class EditNewsComponent implements OnInit {
  public EditNewsGroup: FormGroup;
  id;
  head;
  des;
  img;
  subCategoryId;
  news: News;
  file: File = null;
  mimeType;
  reader;
  imagePath;
  uploadData;
  imgURL: any;
  mypath;
  data;
  catId;
  newsCategory = [];
  newsSubCategory = [];

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,  private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.EditNewsGroup = this.formBuilder.group({
      headline: ['', Validators.required],
      description: ['', Validators.required],
      category_id: ['', Validators.required],
      sub_category_id: ['', Validators.required],
    });
    this.getSingleNews();
    this.getNewsCategory();

  }
  getSingleNews() {
    this.apiServices.getSingleNews(this.id).subscribe((res) => {
        this.EditNewsGroup.patchValue({
          headline: res.response.headline,
          description: res.response.news_detail,
          category_id: res.response.category_id,
          sub_category_id: res.response.sub_category_id,
        });
        this.img = res.response.news_image;
        this.changeCategory(this.EditNewsGroup.value.sub_category_id);
      },
      (err) => {console.log(err); } );
  }

  preview(files) {
    this.img = null;
    this.file = files[0];
    if (files.length === 0) {
      return;
    }

    this.mimeType = files[0].type;
    if (this.mimeType.match(/image\/*/) == null) {
      this.snotifyService.error('Invalid Image', 'Error');
      return;
    }
    this.reader = new FileReader();
    this.imagePath = files;
    this.reader.readAsDataURL(files[0]);
    this.reader.onload = (event) => {
      this.imgURL = this.reader.result;
    };

    this.uploadData = new FormData();
    this.uploadData.append('image', this.file, this.file.name);
    this.apiServices.newsImageUpload(this.uploadData).subscribe((res) => {
      this.mypath = res.response.file_path;
      console.log(this.mypath);
    }, (err) => {
      console.log(err);
    });
  }
  editNews() {
    if ( this.file == null) {
      this.mypath = this.img;
    }

 /*  // this.head = this.EditNewsGroup.get('headline').value;
    this.des = this.EditNewsGroup.controls[('news_detail')].value;
    this.head = this.EditNewsGroup.controls[('headline')].value;
   // this.des = this.EditNewsGroup.get('news_detail').value;
    this.data = {
      news_image: this.mypath,
      news_detail: this.des,
      headline: this.head
    };*/
    this.head = this.EditNewsGroup.value.headline;
    this.des = this.EditNewsGroup.value.description;
    this.subCategoryId = this.EditNewsGroup.value.sub_category_id;
    this.data = {
      news_image: this.mypath,
      news_detail: this.des,
      headline: this.head,
      sub_category_id: this.subCategoryId
    };
    this.apiServices.editNews(this.data , this.id).subscribe((res) => {
      this.snotifyService.success('News Successfully Updated', 'Success');
      setTimeout(() => {
        this.router.navigate(['../../dashboard/news']);
      }, 1000);
      }, (error) => {
      this.snotifyService.error(error.error.message, 'Error');
      });
  }

  getNewsCategory() {
    this.apiServices.getNewsCategory().subscribe((res) => {
      this.newsCategory = res.response;
    }, (error) => {
      this.snotifyService.error(error.error.message, 'Error');
    });
  }
  changeCategory(id) {
    this.apiServices.getNewsSubCategory(id).subscribe((res) => {
      this.newsSubCategory = res.response;
    }, (err) => {
      console.log(err);
    });
  }

}
