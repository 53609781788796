import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '@app/core/http/api.service';
import {LocalStorageService} from '@services/local-storage.service';
import {environment} from '@environments/environment';
import {SnotifyService} from 'ng-snotify';
import {Observable} from "rxjs";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  assetUrl: any = environment.assetsUrl;
  signInObj = {
    email: '',
    password: '',
    disabled: false
  };
  ownModule = {
    modules: [{
      module_id: '',
      access: '',
      module: '',
      module_url: ''
    }]
  };
  moduleAccess = {};
  Url;
  accessedModule = [];

  constructor(private apiServices: ApiService,
              private router: Router,
              private auth: LocalStorageService,
              private snotifyService: SnotifyService) {
  }

  /**
   * login user
   */
  doSignIn() {
    this.signInObj.disabled = true;
    this.apiServices.adminLogin(this.signInObj)
        .subscribe(res => {
          this.auth.setValue('admin_name', res.response.admin_name);
          this.auth.setValue(environment.authKey, res.response.token);
          this.auth.setValue(environment.adminId, res.response.admin_id);
          this.getOwnModule();
          // this.router.navigate(['/']);
        }, (error) => {
          this.signInObj.disabled = false;
          this.snotifyService.error(error.error.message, 'Error');
        });
  }

  /**
   * roles
   */
  getOwnModule() {
    this.apiServices.getOwnModules()
        .subscribe((res) => {
          if (res.response) {
            this.ownModule = res.response;

            //save current user roles in the localstorage
            localStorage.setItem("drupp_admin_permissions",JSON.stringify(this.ownModule.modules));

            for (let i = 0; i < this.ownModule.modules.length; i++) {
              this.moduleAccess[this.ownModule.modules[i].module_id] = this.ownModule.modules[i].access;
              this.accessedModule.push(this.ownModule.modules[i].module_url);
              this.auth.setValue('module', this.accessedModule);
              this.auth.getValue('module', false);
              if (this.ownModule.modules[i].module_id == '1') {
                this.router.navigate(['/']);
              } else if (this.ownModule.modules[i].module_id != '1') {
                this.Url = this.ownModule.modules[0].module_url;
                this.router.navigate([this.Url]);
              }
            }
          } else {
            this.signInObj.disabled = false;
            this.snotifyService.error("Access Denied. You're not authorised to access this portal","ACCESS DENIED");
            setTimeout(()=>{
              this.router.navigate(['/login']);
            },5000);
          }
        }, (err) => {
          this.snotifyService.error(err.error.message,'Error');
        });
  }
}
