import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@app/core/http/api.service';
import {Location} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {environment} from '@environments/environment';
declare var $: any;

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.css']
})
export class DriverDetailComponent implements OnInit {
  // driverDetails: User;
  assetUrl: any = environment.assetsUrl;
  currency: any = environment.currency;
  avatar_url: string;
  driverDetails: any = {
    id: '',
    first_name: '',
    last_name: '',
    country_code: '',
    phone: '',
    email: '',
    average_rating: '',
    city: '',
    created_at: '',
    updated_at: '',
  };
  acceptedRide: any = {
    total : '',
    data: []
  };
  postedRide: any = {
    total: '',
    data: []
  };
  nameSearch;
  id;
  rideSearch;
  transactionSearch;
  vehicleDetails: any[]=[];
  totalTransactions;
  AcceptedCurrentPage = 1;
  PostedCurrentPage = 1;
  TransactionCurrentPage = 1;
  public dateFilterFormTransaction: FormGroup;
  str;
  end;
  loading=true;
  searchName = '';
  vehCurrentPage = 1;
  totalItems = '';
  filterVeh = '';

  public dateFilterFormPosted: FormGroup;
  public dateFilterFormAccepted: FormGroup;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private apiServices: ApiService,
              private location: Location, private snotifyService: SnotifyService, private router: Router) {
  }

  ngOnInit() {
    //forms
    this.driverForms();
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getDriverDetails(this.id);
      this.getDriverPostedRides(this.id, this.PostedCurrentPage);
      this.getDriverAcceptedRides(this.id, this.AcceptedCurrentPage);
      this.getDriverVehicleDetails(this.id);
    });

  }

  driverForms(){
    this.dateFilterFormPosted = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.dateFilterFormAccepted = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.dateFilterFormTransaction = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }

  getDriverDetails(n) {
    this.apiServices.getSingleUser(n).subscribe((res) => {
          if (res.response) {
            this.driverDetails = res.response.data;
            this.loading=false;
          }
        },
        (err) => {
          console.log(err);
        }
    );
  }

  /**
   * get vehcile details
   * @param driverId
   */
  getDriverVehicleDetails(driverId:string){
    this.apiServices.getDriverVehicle(driverId)
        .subscribe((res) => {
              if (res.response) {
                this.vehicleDetails = res.response.data;
                this.loading=false;
              }
            },
            (err) => {
              console.log(err);
            }
        );
  }

  /**
   *
   * @param id
   * @param offset
   */
  getDriverPostedRides(id, offset) {
    this.apiServices.getDriverPostedRides(id, offset, '', '', '').subscribe((res) => {
        if (res.response) {
          this.postedRide.data = res.response.data;
          this.postedRide.total = res.response.total;
          this.loading=false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   *
   * @param id
   * @param offset
   */
  getDriverAcceptedRides(id, offset) {
    this.apiServices.getDriverAcceptedRides(id, offset, '', '', '').subscribe((res) => {
        if (res.response) {
          this.acceptedRide.data = res.response.data;
          this.acceptedRide.total = res.response.total;
          this.loading=false;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   *
   * @param filter
   */
  getFilterAccepted(filter) {
    this.apiServices.getDriverAcceptedRides(this.id, this.AcceptedCurrentPage, filter, '', '').subscribe((res) => {
      if (res.response) {
        this.acceptedRide.data = res.response.data;
        this.acceptedRide.total = res.response.total;
        this.loading=false;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  /**
   *
   * @param filter
   */
  getFilterPosted(filter) {
    this.apiServices.getDriverPostedRides(this.id, this.PostedCurrentPage, filter, '', '').subscribe((res) => {
      if (res.response) {
        this.postedRide.data = res.response.data;
        this.postedRide.total = res.response.total;
        this.loading=false;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }


  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  pageChangedPosted($event: number) {
    this.PostedCurrentPage = $event;
    this.getDriverPostedRides(this.id, this.PostedCurrentPage);
    this.loading=false;
  }

  pageChangedAccepted($event: number) {
    this.AcceptedCurrentPage = $event;
    this.getDriverAcceptedRides(this.id, this.AcceptedCurrentPage);
  }

  getDataTransaction() {
    if (this.dateFilterFormTransaction.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterFormTransaction.get('start').value;
      this.end = this.dateFilterFormTransaction.get('end').value;
/*      console.log(this.str + this.end);
      this.apiServices.getDriverRidesFilter(this.t, this.d, this.str, this.end).subscribe((res) => {
          this.driverRides = res.response.data;
          this.driverTotal = res.response.total;
          this.tempDriver = false;
          console.log(res);
          this.snotifyService.success('Filtered Successfully', 'Success');
          // this.router.navigate(['../dashboard/rides']);
        },
        (err) => {
          console.log(err);
        });*/
    }
  }
  getFilterDataPosted() {
    this.str = this.dateFilterFormPosted.get('start').value;
    this.end = this.dateFilterFormPosted.get('end').value;
    this.apiServices.getDriverPostedRides(this.id, this.PostedCurrentPage, '', this.str, this.end).subscribe((res) => {
        $('#myDateModelPosted').modal('hide');
        this.postedRide.data = res.response.data;
        this.postedRide.total = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
          this.loading=false;
      },
      (err) => {
        console.log(err);
      });
  }
  getFilterDataAccepted() {
    this.str = this.dateFilterFormAccepted.get('start').value;
    this.end = this.dateFilterFormAccepted.get('end').value;
    this.apiServices.getDriverAcceptedRides(this.id, this.AcceptedCurrentPage, '', this.str, this.end).subscribe((res) => {
        $('#myDateModelAccepted').modal('hide');
        this.acceptedRide.data = res.response.data;
        this.acceptedRide.total = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
          this.loading=false;
      },
      (err) => {
        console.log(err);
      });
  }

  /**
   * driver details
   * @param avatar
   */
  onCurrentDriverDocumentClick(avatar: string) {
    this.avatar_url=avatar;
  }
  pageChangedVehicle($event) {
    this.vehCurrentPage = $event;
    this.getDriverVehicleDetails(this.id);
  }
  delete(id) {
    this.snotifyService.confirm('Click Yes if You Want Delete Vehicle', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
              this.apiServices.deleteSingleVehicleDetails(id).subscribe((res) => {
                    this.snotifyService.remove();
                    this.snotifyService.success('Deleted', 'Success');
                    this.getDriverVehicleDetails(this.id);
                  },
                  (err) => {
                    console.log(err);
                  })
          , bold: false
        },
        {
          text: 'No', action: () =>
              this.snotifyService.remove()
        },
      ]
    });
  }
}
