import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireDatabase} from '@angular/fire/database';
import {environment} from '@environments/environment';
import {ApiService} from '@app/core/http/api.service';
import {Location} from '@angular/common';
import {GeoLocationService} from '@services/geo-location.service';
import * as moment from 'moment';

@Component({
  selector: 'app-single-bus-ride-details',
  templateUrl: './single-bus-ride-details.component.html',
  styleUrls: ['./single-bus-ride-details.component.css']
})
export class SingleBusRideDetailsComponent implements OnInit {
  id;
  ignoreRequest = true;
  currency: any = environment.currency;
  singlerideDetails: any = {
    finish_time: '',
    start_time: '',
    source_latitude: '',
    source_longitude: '',
    destination_latitude: '',
    destination_longitude: '',
    driver_id: '',
    status: '',
    total_fare: '',
    cancel_reason: '',
    driver_name: '',
    user_id: '',
    rider_name: '',
    source: '',
    destination: '',
    ride_date: '',
    distance: '',
    ride_type: '',
    latitude: '',
    longitude: ''
  };
  markerlati;
  markerlongi;
  time;
  coordinates;
  public zoom ;
  public lat;
  public lng;
  public origin: any;
  public destination: any;
  public renderOptions = {
    suppressMarkers: true,
  };
  public markersOption = {
    origin: {
      icon: '../../../../assets/images/icon/source.png',
      draggable: false,
    },
    current: {
      icon: '../../../../assets/images/bus.png',
    },
    destination: {
      icon: '../../../../assets/images/icon/destination.png',
    },
  };

  constructor(private route: ActivatedRoute,
              private fireDatabase: AngularFireDatabase,
              private router: Router,
              private apiServices: ApiService,
              private location: Location,
              private geoLocation: GeoLocationService,
              private zone: NgZone) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.getSingleBusRideDetails(this.id);
    // this.getLocation(this.id);
  }

  getSingleBusRideDetails(id) {
    this.apiServices.getSingleBusRide(id).subscribe((res) => {
        if (res.response) {
          this.singlerideDetails = res.response;
          this.singlerideDetails.start_time = moment(this.singlerideDetails.start_time * 1000).toDate();
          this.singlerideDetails.finish_time = moment(this.singlerideDetails.finish_time * 1000).toDate();
          this.lat = parseFloat(this.singlerideDetails.source_latitude);
          this.lng = parseFloat(this.singlerideDetails.source_longitude);
          this.origin = {
            lat: parseFloat(this.singlerideDetails.source_latitude),
            lng: parseFloat(this.singlerideDetails.source_longitude)
          };
          this.destination = {
            lat: parseFloat(this.singlerideDetails.destination_latitude),
            lng: parseFloat(this.singlerideDetails.destination_longitude)
          };
          this.getLiveLocation();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  goBack() {
    clearTimeout(this.time);
    this.location.back(); // <-- go back to previous location on cancel
  }

  ngOnDestroy() {
    clearTimeout(this.time);
  }

  // getLocation(id) {
  //   this.apiServices.getSingleStarted(id).subscribe((res) => {
  //       this.singlerideDetails = res.response;
  //       this.markerlati = this.singlerideDetails.latitude;
  //       this.markerlongi = this.singlerideDetails.longitude;
  //       // console.log(this.rideDetails.longitude + this.rideDetails.latitude);
  //       // console.log(res.response);
  //       this.getValue(id);
  //       // console.log(parseFloat(this.rideDetails.source_longitude));
  //     },
  //     (err) => {
  //       console.log(err);
  //     }
  //   );
  // }

  // getValue(id) {
  //   this.time = setTimeout(() => {
  //     this.getLocation(id);
  //   }, 10000);
  // }
  getLiveLocation() {
    // for (const user of this.rideDetails) {
    this.fireDatabase.database.ref('users/drivers/' + this.singlerideDetails.driver_id).on('value', (snapshot) => {
      console.log(snapshot.val());
      if (snapshot.val()) {
        this.zone.run(() => {
          this.singlerideDetails.driver_id = !!snapshot.val().driver_id;
          this.markerlati = snapshot.val().latitude;
          console.log(this.markerlati);
          this.markerlongi = snapshot.val().longitude;
        });
      }
    });
  }
}
