import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';
import {Location} from "@angular/common";

declare var $: any;

@Component({
  selector: 'app-sub-admin',
  templateUrl: './sub-admin.component.html',
  styleUrls: ['./sub-admin.component.css']
})
export class SubAdminComponent implements OnInit {

  //paging
  tableSize = 10;
  page = 1;
  count = 0;
  tableSizes = [2, 4, 10, 20];
  totalRecords: number;
  currentIndex: number;
  searchTerm: string="";
  loading=true;
  submitting=false;

  subAdmin = {
    name: '',
    email: '',
    password: ''
  };
  allmodules = [{
    id: '',
    module: '',
    access: ''
  }];
  moduleArray = [];
  allSubAdmin = [{
    id: '',
    email: '',
    last_name: '',
    status: '',
    created_at: '',
    updated_at: ''
  }];
  SinglesubadminDetails: any = {
    id: '',
    last_name: '',
    email: '',
    Modules: [{
      id: '',
      module: '',
      access: 0
    }],
  };


  constructor(private apiServices: ApiService, private formBuilder: FormBuilder,
              private location: Location, private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.getAllSubAdmins();
  }

  getAllSubAdmins() {
    this.apiServices.getAllSubAdmin().subscribe((res) => {
      if (res.response) {
        this.allSubAdmin = res.response;
        this.loading=false;
      }
    }, (err) => {
      console.log(err);
    });
  }

  activateSubAdmin(id) {
    this.snotifyService.confirm('Click Yes if You Want Activate Sub-Admin', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.activateSubAdmin({sub_admin_id: id}).subscribe((res) => {
              this.snotifyService.remove();
              this.snotifyService.success('Activated', 'Success');
              this.getAllSubAdmins();
              this.loading=false;
            }, (err) => {
              this.snotifyService.error(err.error.message, 'Error');
            })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }

  deactivateSubAdmin(id) {
    this.snotifyService.confirm('Click Yes if You Want Deactivate Sub-Admin', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deactivateSubAdmin({sub_admin_id: id}).subscribe((res) => {
              this.snotifyService.remove();
              this.snotifyService.success('Deactivated', 'Success');
              this.getAllSubAdmins();
              this.loading=false;
            }, (err) => {
              this.snotifyService.error(err.error.message, 'Error');
            })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }

  // ..................................... modal functions...............................................
  getModules() {
    this.apiServices.getModules().subscribe((res) => {
      if (res.response) {
        this.allmodules = res.response;
      }
    }, (err) => {
      console.log(err);
    });
  }

  SelectModules(event, id) {
    console.log(event);
    if (event.checked == true) {
      this.moduleArray.push({module_id: id});
    } else if (event.checked == false) {
      this.moduleArray.splice(this.moduleArray.indexOf(id), 1);
    }
  }

  /**
   * admins
   * @param id
   */
  getSingleSubAdmin(id) {
    $('#editModalSubAdmin').modal('show');
    this.apiServices.getSingleSubAdmin(id).subscribe((res) => {
      console.log(res.response);
      if (res.response) {
        this.SinglesubadminDetails = res.response.subadmin_detail;
        this.SinglesubadminDetails.Modules = res.response.modules;
        // console.log(this.SinglesubadminDetails.Modules);
        this.SinglesubadminDetails.id = id;
      }
    }, (err) => {
      console.log(err);
    });
  }

  /**
   * edit admin
   */
  editSubAdmin() {
    this.submitting=true;
    const inputData = Object.assign({}, {modules: this.moduleArray, sub_admin_id: this.SinglesubadminDetails.id});
    console.log(inputData);
    this.apiServices.editSubAdmin(inputData)
        .subscribe((res) => {
          this.submitting=false;
          $('#editModalSubAdmin').modal('hide');
          setTimeout(()=>{
            this.snotifyService.success('You have successfully updated admin details', 'Success');
          },2000);

        },err=>{
          this.snotifyService.error(err.error.message, 'Error');
          this.submitting=false;
        });
  }

  /**
   * remove admin
   */
  removeAdmin(admin: any){
    this.snotifyService.confirm(`Are You Sure you want remove ${admin.first_name} ${admin.middle_name} ${admin.last_name} ?`, 'Remove Admin', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
              this.apiServices.removeAdmin(admin.id)
                  .subscribe((res) => {
                    this.snotifyService.remove();
                    this.snotifyService.success(res.message, 'Success');
                    this.reloadCurrentRoute();
                    this.loading=false;
                  }, (err) => {
                    this.snotifyService.error(err.error.message, 'Error');
                    this.loading=false;
                  })
          , bold: false
        },
        {
          text: 'No', action: () =>
              this.snotifyService.remove()
        },
      ]
    });
  }

  /**
   * reload
   */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  /**
   * table pagination
   * @param event
   */
  onTableDataChange(event) {
    this.page = event;
    this.getAllSubAdmins();
  }

  onTableSizeChange(event): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getAllSubAdmins();
  }

}
