import { Component, OnInit } from "@angular/core";
import { ApiService } from "@app/core/http/api.service";
import { environment } from "@environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SnotifyService } from "ng-snotify";
import { MatDialog } from "@angular/material";
import { Rides } from "@app/shared/models/rides";
import { AuthGuardService } from "@app/core/guards/auth.guard.service";

@Component({
  selector: "app-apartments",
  templateUrl: "./rides.component.html",
  styleUrls: ["./rides.component.css"],
})
export class RidesComponent implements OnInit {
  rides = [];
  userPage = 1;
  currency: any = environment.currency;
  driverPage = 1;
  type = 2;
  nameSearch = "";
  tempUser = true;
  tempDriver = true;
  filter = "";
  str;
  temp;
  end;
  public dateFilterForm: FormGroup;
  public dateFilterFormDriver: FormGroup;
  rideSearch = "";
  driverRides = [];
  driverTotal = "";
  total: number;
  calling: boolean;

  constructor(
    private apiServices: ApiService,
    private formBuilder: FormBuilder,
    private snotifyService: SnotifyService,
    private router: Router,
    public dialog: MatDialog,
    public authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    // console.log(this.authGuardService.modules);

    this.dateFilterForm = this.formBuilder.group({
      start: ["", Validators.required],
      end: ["", Validators.required],
    });

    this.dateFilterFormDriver = this.formBuilder.group({
      start: ["", Validators.required],
      end: ["", Validators.required],
    });

    this.getRides(this.type);
    this.getDriverRides(this.type);
  }

  getRides(type) {
    this.calling = true;
    this.apiServices
      .getRides(1, this.userPage, this.rideSearch, "")
      .subscribe(
        (res) => {
          this.calling = false;
          if (res.response) {
            this.rides = res.response.data;
            this.rides.sort((a: any, b: any) => {
              return b.ride_date - a.ride_date;
            });
            this.total = res.response.total;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getDriverRides(type) {
    this.apiServices
      .getDriverRides(type, this.driverPage, this.nameSearch, "")
      .subscribe(
        (res) => {
          if (res.response) {
            this.driverRides = res.response.data;
            this.driverTotal = res.response.total;
            this.driverRides.sort((a: any, b: any) => {
              return b.ride_date - a.ride_date;
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  /* pageChanged($event: number) {
     this.p = $event;
     if (this.tempUser == true) {
       this.getRides(this.t, this.p);
     } else {
       this.getData();
     }
   }*/
  pageChangedUser($event) {
    this.userPage = $event;
    if (this.tempUser == true) {
      this.getRides(this.type);
    } else {
      this.getData();
    }
  }

  pageChangedDriver($event) {
    this.driverPage = $event;
    if (this.tempDriver == true) {
      this.getDriverRides(this.type);
    } else {
      this.getDataDriver();
    }
  }

  //User Single Ride Date Filter
  getData() {
    if (this.dateFilterForm.invalid) {
      this.snotifyService.error("All Fields Are Mandatory", "Error");
    } else {
      this.str = this.dateFilterForm.get("start").value;
      this.end = this.dateFilterForm.get("end").value;
      this.apiServices
        .getRidesFilter(this.type, this.userPage, this.str, this.end)
        .subscribe(
          (res) => {
            this.rides = res.response.data;
            this.total = res.response.total;
            this.tempUser = false;
            this.snotifyService.success("Filtered Successfully", "Success");
            this.router.navigate(["../dashboard/rides"]);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  //Driver Single Ride Date Filter
  getDataDriver() {
    if (this.dateFilterFormDriver.invalid) {
      this.snotifyService.error("All Fields Are Mandatory", "Error");
    } else {
      this.str = this.dateFilterFormDriver.get("start").value;
      this.end = this.dateFilterFormDriver.get("end").value;
      this.apiServices
        .getDriverRidesFilter(this.type, this.driverPage, this.str, this.end)
        .subscribe(
          (res) => {
            this.driverRides = res.response.data;
            this.driverTotal = res.response.total;
            this.tempDriver = false;
            this.snotifyService.success("Filtered Successfully", "Success");
            this.router.navigate(["../dashboard/rides"]);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }
  getFilter(filter) {
    this.apiServices
      .getRides(this.type, this.userPage, this.rideSearch, filter)
      .subscribe(
        (res) => {
          if (res.response) {
            this.rides = res.response.data;
            this.total = res.response.total;
            // this.total = res.response.total;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  getFilterDriver(filter) {
    this.apiServices
      .getDriverRides(this.type, this.driverPage, this.nameSearch, filter)
      .subscribe(
        (res) => {
          if (res.response) {
            this.driverRides = res.response.data;
            this.driverTotal = res.response.total;
            // this.total = res.response.total;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  resetFilterUser() {
    this.type = 1;
    this.userPage = 1;
    this.filter = "";
    this.rideSearch = "";
    this.apiServices
      .getRides(this.type, this.userPage, this.rideSearch, this.filter)
      .subscribe(
        (res) => {
          if (res.response) {
            this.rides = res.response.data;
            this.total = res.response.total;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  resetFilterDriver() {
    this.type = 1;
    this.driverPage = 1;
    this.filter = "";
    this.nameSearch = "";
    this.apiServices
      .getRides(this.type, this.driverPage, this.nameSearch, this.filter)
      .subscribe(
        (res) => {
          if (res.response) {
            this.rides = res.response.data;
            this.total = res.response.total;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
