import {Directive, ElementRef, OnInit, Output, EventEmitter} from '@angular/core';

declare var google: any;

@Directive({
  selector: '[appGooglePlaces]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() atSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(private elRef: ElementRef) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    // tslint:disable-next-line:only-arrow-functions
    this.element.addEventListener('keypress', function(eve) {
      if (eve.keyCode === 13) {
        eve.preventDefault();
      }
    });

    const options = {
      // types: ['(cities)'],
      // componentRestrictions: {country: 'us'}
    };

    const autocomplete = new google.maps.places.Autocomplete(this.element, options);
    // autocomplete.setTypes();
    // Set initial restrict to the greater list of countries.
    // autocomplete.setComponentRestrictions({'country': ['us']});
    // Specify only the data fields that are needed.
    autocomplete.setFields(['address_component', 'formatted_address', 'geometry', 'place_id']);
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      // Emit the new address object for the updated place
      this.atSelect.emit(autocomplete.getPlace());
    });  }
}
