import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
import {Router} from '@angular/router';

@Component({
  selector: 'app-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.css']
})
export class AddVehicleComponent implements OnInit {
  addVehicleFormGroup: FormGroup;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.addVehicleFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      capacity: ['', Validators.required],
      base_fare: ['', Validators.required],
      per_minute_wait_charge: ['', Validators.required],
      rate_per_km: ['', Validators.required],
      icon: ['icon_image'],
    });
  }

  addVehicle() {
    console.log(this.addVehicleFormGroup.value);
    this.apiServices.addVehicle(this.addVehicleFormGroup.value).subscribe((res) => {
        console.log(res);
        this.snotifyService.success('Added Successfully', 'Success');
        this.router.navigate(['../dashboard/vehicles']);
      },
      (err) => {
        console.log(err);
      });
  }
  changeType(type) {

  }
}
