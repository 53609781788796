// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //apiPrefix: 'https://api.qualwebs.com/drupp/api/admin/',
  apiPrefix: 'https://africarider.net/arul/drupp-api/api/admin/',
  baseURL : 'https://africarider.net/arul/drupp-api/api/',
  assetsUrl: 'https://africarider.net/arul/drupp-api/storage/app/public',
  currency: '&#8358; ',
  authKey: 'drupp_token',
  adminId: 'admin_id',
  firebase: {
    apiKey: "AIzaSyBt96iMqR2IL7wJhPR-GyOkJXBX_tk0gsw",
    authDomain: "drupp-c0154.firebaseapp.com",
    databaseURL: "https://drupp-c0154.firebaseio.com",
    projectId: "drupp-c0154",
    storageBucket: "drupp-c0154.appspot.com",
    messagingSenderId: "666604725649",
    appId: "1:666604725649:web:95538bc3ca83d0a4ed2630",
    measurementId: "G-MLJ543J5BK"
    // apiKey: 'AIzaSyDOcDbk1PO_qgVJdLDkmNJBF6LAnugEwBM',
    // authDomain: 'drupp-app.firebaseapp.com',
    // databaseURL: 'https://drupp-app.firebaseio.com',
    // projectId: 'drupp-app',
    // storageBucket: 'drupp-app.appspot.com',
    // messagingSenderId: '673734351855',
    // appId: '1:673734351855:web:fcbc7b2faec26a68'
  },
  //version API
  developmentURL:'https://africarider.net/druppx-api-v2/drupp-api/api/admin/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
