import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {SnotifyService} from 'ng-snotify';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-bus-rides',
  templateUrl: './bus-rides.component.html',
  styleUrls: ['./bus-rides.component.css']
})
export class BusRidesComponent implements OnInit {
  id;
  busRideId;
  singleBusRides = [];
  editRideFormGroup: FormGroup;
  getRide = [];
  getRideId;
  getPassengers = [];
  busDriver = [];
  minDate = new Date() + Date();
  startMin;
  calling: boolean;
  searchPass = '';
  totalRides = '';
  rideSearch = '';
  totalPass = '';
  rideCurrentPage = 1;
  passCurrentPage = 1;
  totalRidesonDate = '';
  rideDateFilter = '';
  rideDateFilterForm: FormGroup;
  temp = true;
  str = '';
  end = '';
  busRideStatusFilter = '';
  filtId = '';
  filterRide = '';
  busDetails = {
    bus_registration_number: '',
    bus_number: '',
    capacity: ''
  };
  sortPass = '';
  thisSource;
  thisDestination;
  sourceLatitude;
  destinationLatitude;
  destinationLongitude;
  sourceLongitude;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private location: Location,
              private router: Router, private route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit() {
    this.getSingleBusRides();
    this.editRideFormGroup = this.formBuilder.group({
      bus_ride_id: [this.id, Validators.required],
      bus_id: ['', Validators.required],
      source: ['', Validators.required],
      destination: ['', Validators.required],
      driver_id: ['', Validators.required],
      start_time: ['', Validators.required],
      finish_time: ['', Validators.required],
      per_seat_price: ['', Validators.required],
      source_latitude: ['', Validators.required],
      source_longitude: ['', Validators.required],
      destination_latitude: ['', Validators.required],
      destination_longitude: ['', Validators.required]
    });
    this.getBusDrivers();
    const today = new Date();
    this.startMin = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    this.rideDateFilterForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }

  getSingleBusRides() {
    this.calling = true;
    this.apiServices.getSingleBusRides(this.id, this.rideCurrentPage, this.rideSearch, this.str, this.end, this.filterRide).subscribe((res) => {
      this.calling = false;
      this.singleBusRides = res.response.data;
      this.totalRides = res.response.total;
      this.busDetails = res.response.bus_details;
      for (let i = 0; i < this.singleBusRides.length; i++) {
        this.singleBusRides[i].start_time = moment(this.singleBusRides[i].start_time * 1000).toDate();
        this.singleBusRides[i].finish_time = moment(this.singleBusRides[i].finish_time * 1000).toDate();
      }
    }, (err) => {
      console.log(err);
    });
  }

  editModal(id) {
    $('#editRide').modal('show');
    this.apiServices.getSingleBusRides(this.id, this.rideCurrentPage, this.rideSearch, this.str, this.end, this.filterRide).subscribe((res) => {
      this.getRide = res.response;
      this.editRideFormGroup.patchValue({
        bus_ride_id: id.id,
        bus_id: id.bus_id,
        source: id.source,
        destination: id.destination,
        driver_id: id.driver_id,
        start_time: id.start_time,
        finish_time: id.finish_time,
        per_seat_price: id.per_seat_price,
      });
    });
  }

  editSingleRide(id) {

    if (this.editRideFormGroup.value.source == '' ||
      this.editRideFormGroup.value.destination == '' ||
      this.editRideFormGroup.value.driver_id == '') {
      this.snotifyService.error('All Fields are Mandatory', 'Error');
    } else if (this.editRideFormGroup.value.start_time === '') {
      this.snotifyService.error('Start Time Fields Is Mandatory', 'Error');
    } else if (this.editRideFormGroup.value.finish_time === '') {
      this.snotifyService.error('Finish Time Fields Is Mandatory', 'Error');
    } else {
      this.editRideFormGroup.value.source = this.thisSource;
      this.editRideFormGroup.value.destination = this.thisDestination;
      this.editRideFormGroup.value.source_latitude = this.sourceLatitude;
      this.editRideFormGroup.value.source_longitude = this.sourceLongitude;
      this.editRideFormGroup.value.destination_latitude = this.destinationLatitude;
      this.editRideFormGroup.value.destination_longitude = this.destinationLongitude;
      this.editRideFormGroup.value.start_time = moment(this.editRideFormGroup.value.start_time, 'HH:mm:ss').format('X');
      this.editRideFormGroup.value.finish_time = moment(this.editRideFormGroup.value.finish_time, 'HH:mm:ss').format('X');
      this.apiServices.editSingleRide(this.editRideFormGroup.value, this.editRideFormGroup.value.bus_ride_id).subscribe((res) => {
          this.snotifyService.success('Added Successfully', 'Success');
          $('#editRide').modal('hide');
          this.getSingleBusRides();
        },
        (err) => {
          console.log(err);
        });
    }
  }

  selectCitySource(city) {
    this.editRideFormGroup.value.source = city.formatted_address;
    this.thisSource = city.formatted_address;
    this.sourceLatitude = city.geometry.location.lat().toString();
    this.sourceLongitude = city.geometry.location.lng().toString();
  }

  selectCityDestination(city) {
    this.editRideFormGroup.value.destination = city.formatted_address;
    this.thisDestination = city.formatted_address;
    this.destinationLatitude = city.geometry.location.lat().toString();
    this.destinationLongitude = city.geometry.location.lng().toString();
  }


  delete(id) {
    this.snotifyService.confirm('Click Yes if You Want Delete Ride', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deleteBusRide(id).subscribe((res) => {
                this.snotifyService.remove();
                this.snotifyService.success('Deleted', 'Success');
                this.getSingleBusRides();
              },
              (err) => {
                console.log(err);
              })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }

  pageChangedRides($event) {
    this.rideCurrentPage = $event;
    this.getSingleBusRides();
  }

  viewPassengers(id) {
    this.busRideId = id;
    $('#getpassengers').modal('show');
    // if (this.getPassengers) {}
    this.calling = true;
    this.apiServices.getPassengers(id, this.passCurrentPage, this.searchPass, this.filtId, this.sortPass).subscribe((res) => {
      this.calling = false;
      console.log(res);
      this.getPassengers = res.response.data;
      this.totalPass = res.response.total;
    });
  }

  getDateData() {
    if (this.rideDateFilterForm.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.rideDateFilterForm.get('start').value;
      this.end = this.rideDateFilterForm.get('end').value;
      this.apiServices.getSingleBusRides(this.id, this.passCurrentPage, this.rideSearch, this.str, this.end, this.filterRide).subscribe((res) => {
          this.singleBusRides = res.response.data;
          this.totalRides = res.response.total;
          for (let i = 0; i < this.singleBusRides.length; i++) {
            this.singleBusRides[i].start_time = moment(this.singleBusRides[i].start_time * 1000).toDate();
            this.singleBusRides[i].finish_time = moment(this.singleBusRides[i].finish_time * 1000).toDate();
          }
          this.temp = false;
          this.snotifyService.success('Filtered Successfully', 'Success');
          console.log(this.id);
          console.log(this.str);
          this.router.navigate(['../dashboard/bus-rides/' + this.id]);
          console.log(this.router.navigate);
        },
        (err) => {
          console.log(err);
        });
    }
  }

  filterBusRide(filterRideId) {
    this.filterRide = filterRideId;
    if (filterRideId == '') {
      this.rideSearch = '';
      this.str = '';
      this.end = '';
      this.apiServices.getSingleBusRides(this.id, this.passCurrentPage, this.rideSearch, this.str, this.end, filterRideId).subscribe((res) => {
        this.singleBusRides = res.response.data;
        this.totalRides = res.response.total;
        for (let i = 0; i < this.singleBusRides.length; i++) {
          this.singleBusRides[i].start_time = moment(this.singleBusRides[i].start_time * 1000).toDate();
          this.singleBusRides[i].finish_time = moment(this.singleBusRides[i].finish_time * 1000).toDate();
        }
      }, (err) => {
        console.log(err);
      });
      // this.rideSearch = '';
      // this.str = '';
      // this.end = '';
    } else {
      this.apiServices.getSingleBusRides(this.id, this.passCurrentPage, this.rideSearch, this.str, this.end, filterRideId).subscribe((res) => {
        this.singleBusRides = res.response.data;
        this.totalRides = res.response.total;
        for (let i = 0; i < this.singleBusRides.length; i++) {
          this.singleBusRides[i].start_time = moment(this.singleBusRides[i].start_time * 1000).toDate();
          this.singleBusRides[i].finish_time = moment(this.singleBusRides[i].finish_time * 1000).toDate();
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  pageChangedPass($event) {
    this.passCurrentPage = $event;
    this.viewPassengers(this.busRideId);
  }

  getBusDrivers() {
    this.apiServices.getBusDriver().subscribe((res) => {
      this.busDriver = res.response;
    });
  }

  /**
   *
   * @param filterid
   */
  filterPassengers(filterid) {
    this.filtId = filterid;
    this.apiServices.getPassengers(this.busRideId, this.passCurrentPage, this.searchPass, filterid, this.sortPass)
        .subscribe((res) => {
          this.getPassengers = res.response.data;
          console.log(res);
          this.totalPass = res.response.total;
          this.filterRide = '';

        });
  }

  /**
   * sort passgenrs
   * @param newest
   */
  sortPassengers(newest) {
    this.sortPass = newest;
    this.apiServices.getPassengers(this.busRideId, this.passCurrentPage, this.searchPass, this.filterRide, newest)
        .subscribe((res) => {
          this.getPassengers = res.response.data;
          this.totalPass = res.response.total;
        });
  }

  downloadPassengers() {
    window.location.href = 'https://api.qualwebs.com/drupp/api/admin/download-passengers-pdf/?bus_ride_id=' + this.busRideId;
  }

  deletePassengers(id, user) {
    console.log(user);
    console.log(this.busRideId);
    this.snotifyService.confirm('Click Yes if You Want Delete Booking', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deletePass(this.busRideId, user).subscribe((res) => {
                console.log(res);
                this.snotifyService.remove();
                this.snotifyService.success('Deleted', 'Success');
                this.viewPassengers(this.busRideId);
                this.getSingleBusRides();
              },
              (err) => {
                console.log(err);
              })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}
