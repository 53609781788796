import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';
import {Vehicles} from '@app/shared/models/vehicles';

@Component({
  selector: 'app-edit-vehicle',
  templateUrl: './edit-vehicle.component.html',
  styleUrls: ['./edit-vehicle.component.css']
})
export class EditVehicleComponent implements OnInit {
  public editVehicleFormGroup: FormGroup;
  id;
  vehicle: Vehicles;

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editVehicleFormGroup = this.formBuilder.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      type: ['', Validators.required],
      capacity: ['', Validators.required],
      base_fare: ['', Validators.required],
      per_minute_wait_charge: ['', Validators.required],
      rate_per_km: ['', Validators.required],
      icon: ['icon_image'],
    });
    this.getVehicleDetails(this.id);
  }

  getVehicleDetails(id) {
    this.apiServices.getSingleVehicle(id).subscribe((res) => {
        // this.editVehicleFormGroup.setValue(res.response.data[0]);
        console.log(res.response);
        this.editVehicleFormGroup.patchValue({
          id: this.id,
          name: res.response.data[0].name,
          type: res.response.data[0].type,
          capacity: res.response.data[0].capacity,
          base_fare: res.response.data[0].base_fare,
          per_minute_wait_charge: res.response.data[0].per_minute_wait_charge,
          rate_per_km: res.response.data[0].rate_per_km,
          icon: res.response.data[0].icon
        });
      },
      (err) => {
        console.log(err);
      });
  }

  edit() {
    this.editVehicleFormGroup.value.id = this.id;
    console.log(this.editVehicleFormGroup.value);
    this.apiServices.updateVehicle(this.editVehicleFormGroup.value).subscribe((res) => {
        this.snotifyService.success('Edited Successfully', 'Success');
        this.router.navigate(['../dashboard/vehicles']);
      },
      (err) => {
        console.log(err);
      });
  }
}
