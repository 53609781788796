import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.css']
})
export class CommissionComponent implements OnInit {

  addCommissionFormGroup: FormGroup;
  addCancellationFormGroup: FormGroup;
  commission = [];
  data;
  currentCommission = {
    commission: ''
  };
  currentCancellation = {
    commission: ''
  };

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.getCommission();
    this.getCancellation();
    this.addCommissionFormGroup = this.formBuilder.group({
      commission_percentage: ['', Validators.required],
    });
    this.addCancellationFormGroup = this.formBuilder.group({
      cancellation_percentage: ['', Validators.required],
    });
  }

  addCommission() {
    this.apiServices.commissionPercentage(this.addCommissionFormGroup.value).subscribe((res) => {
        this.snotifyService.success('Added Successfully', 'Success');
        this.getCommission();
      },
      (err) => {
        this.snotifyService.error(err.error.message, err.error.response);
      });
  }
  addCancellation() {
    this.apiServices.saveCancellationPercentage(this.addCancellationFormGroup.value).subscribe((res) => {
        this.snotifyService.success('Added Successfully', 'Success');
        this.getCancellation();
      },
      (err) => {
        this.snotifyService.error(err.error.message, err.error.response);
      });
  }

  getCommission() {
    this.apiServices.getCommission().subscribe((res) => {
      this.commission = res.response.previous_commission;
      this.currentCommission = res.response.current_commission;
      this.data = res.response.data;
    });
  }
  getCancellation() {
    this.apiServices.getCancellationPercentage().subscribe((res) => {
      this.currentCancellation = res.response;
    });
  }

  delete(id) {

  }
}

