import { Component, OnInit } from '@angular/core';
import {MessagingService} from '@services/messaging.service';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-bus',
  templateUrl: './add-bus.component.html',
  styleUrls: ['./add-bus.component.css']
})
export class AddBusComponent implements OnInit {
  addBusFormGroup: FormGroup;
  disabled = false;



  constructor( private messagingService: MessagingService,
               private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {

  }

  ngOnInit() {
    this.addBusFormGroup = this.formBuilder.group({
      bus_registration_number: ['', Validators.required],
      bus_number: ['', Validators.required],
      capacity: ['', Validators.required],
    });
  }

  addBus() {
    this.disabled = true;
    this.apiServices.addBus(this.addBusFormGroup.value).subscribe((res) => {
        this.snotifyService.success('Added Successfully', 'Success');
        this.disabled = false;
        this.router.navigate(['../dashboard/bus']);
      },
      (err) => {
        console.log(err);
        this.disabled = false;
      });
  }
}
