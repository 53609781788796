import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "@environments/environment";

declare var $: any;

@Component({
  selector: 'app-edit-drupp-shop',
  templateUrl: './edit-drupp-shop.component.html',
  styleUrls: ['./edit-drupp-shop.component.css']
})
export class EditDruppShopComponent implements OnInit {
  editProductGroup: FormGroup;
  assetUrl: any = environment.assetsUrl;
  id;
  productName;
  categories;
  description;
  image;
  imgURL: any;
  data;
  categoryList: any = [{
    category: '',
    selected_cat: '',
    data: []
  }];
  productImages = [];
  product: any = [{
    name: '',
    productImg: [{
      file_name: '',
      file_path: ''
    }]
  }];


  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute, private zone: NgZone) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.editProductGroup = this.formBuilder.group({
      product_name: ['', Validators.required],
      brand: ['', Validators.required],
      quantity: ['', Validators.required],
      price: ['', Validators.required],
      description: ['', Validators.required],
      product_images: ['', Validators.required],
    });
    this.getSingleProduct(this.id);
    this.getCategory();
  }

  getSingleProduct(id) {
    this.apiServices.getSingleProduct(id).subscribe((res) => {
        this.product.productImg = res.response.data.product_images;
        this.editProductGroup.patchValue({
          product_name: res.response.data.product_name,
          brand: res.response.data.brand,
          quantity: res.response.data.quantity,
          categories: res.response.data.categories,
          price: res.response.data.price,
          description: res.response.data.description,
          productImages: res.response.data.product_images,
        });
        this.image = res.response.image;
      },
      (err) => {
        console.log(err);
      });
  }

  editProduct() {
    const categoryArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.categoryList.length; i++) {
      categoryArray.push({categories: this.categoryList[i].category});
    }
    const inputData = Object.assign({}, {
      product_images: this.productImages,
      categories: categoryArray
    }, this.editProductGroup.value);

    this.apiServices.editProduct(inputData, this.id).subscribe((res) => {
        this.snotifyService.success('Product Successfully Updated', 'Success');
        setTimeout(() => {
          this.router.navigate(['../../dashboard/drupp-shop']);
        }, 1000);
      },
      (err) => {
        console.log(err);
      });
  }

  // getCategory(ind) {
  //   this.apiServices.getCategory(this.editProductGroup.value.categories).subscribe((res) => {
  //     this.categoryList[ind].data = res.response;
  //   });
  // }
  getCategory() {
    this.apiServices.getCategory(0).subscribe((res) => {
      this.categoryList[0].data = res.response;
    });
  }

  changeCategory(index) {
    const id = this.categoryList[index].category;
    this.apiServices.getCategory(id).subscribe((res) => {
      if (index !== (this.categoryList.length - 1)) {
        this.categoryList.length = index + 1;
      }
      if (res.response.length) {
        this.categoryList.push({
          category: '',
          data: res.response
        });
      }
    }, (error) => {
    });
  }

  // uploadImage(eve) {
  //   console.log(eve);
  //   this.apiServices.uploadImage(eve).subscribe((res) => {
  //     console.log(res.response);
  //     this.productImages.push({'file_path': res.response.file_path, 'file_name': res.response.file_name});
  //   });
  // }
  handleFileInput(file) {
    const formData = new FormData();
    formData.append('image', file.target.files[0]);
    $('#add-loader').modal('show');
    this.apiServices.uploadImage(formData).subscribe(res => {
      // tslint:disable-next-line:only-arrow-functions
      setTimeout(function () {
        $('#add-loader').modal('hide');
      }, 300);
      this.zone.run(() => {
        this.productImages.push({'file_path': res.response.file_path, 'file_name': res.response.file_name});
      });
    }, error => {
      this.snotifyService.error(error.error.message, 'Error');
    });
  }

  removeGalleryImage(id) {
    console.log(id);
    this.productImages.splice(id, 1);
  }

}
