import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@app/core/http/api.service';
import {SnotifyService} from 'ng-snotify';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  addCategoryFormGroup: FormGroup;
  disabled = false;
  allCategories = [];
  categoryImages = '';

  constructor(private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.addCategoryFormGroup = this.formBuilder.group({
      parent_id: ['', Validators.required],
      category_name: ['', Validators.required],
    });
    this.getAllCategories();
  }

  getAllCategories() {
    this.disabled = true;
    this.apiServices.getAllCategories(1).subscribe((res) => {
      this.disabled = false;
      this.allCategories = res.response.data;
    });
  }

  addCategory() {
    const inputData = Object.assign({}, {image: this.categoryImages}, this.addCategoryFormGroup.value);
    this.apiServices.addCategories(inputData).subscribe((res) => {
        this.snotifyService.success('Added Successfully', 'Success');
        this.disabled = false;
        this.router.navigate(['../dashboard/category-shop/']);
      },
      (err) => {
        this.snotifyService.error(err.error.message, err.error.response);
        this.disabled = false;
      });
  }
  uploadImageCategory(eve) {
    this.apiServices.categoryImageUpload(eve).subscribe((res) => {
      this.categoryImages = res.response.file_path;
    });
  }
}
