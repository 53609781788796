import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acceptedFilter'
})
export class AcceptedFilterPipe implements PipeTransform {

  transform(ride , nameSearch: string) {
    if (ride && ride.length) {
        return ride.filter(item => {
            if (nameSearch && item.source.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
             && nameSearch && item.destination.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
             && nameSearch && item.total_fare.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1) {
              return false;
          }
            return true;
       });
    } else {
        return ride;
    }
}

}
