import {Component, OnInit} from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {environment} from '@environments/environment';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnotifyService} from "ng-snotify";
import {Router} from "@angular/router";

@Component({
  selector: 'app-sch-rides',
  templateUrl: './sch-rides.component.html',
  styleUrls: ['./sch-rides.component.css']
})
export class SchRidesComponent implements OnInit {
  schRides = [];
  driverSchRides = [];
  currency: any = environment.currency;
  totalSch;
  totalDriverSch;
  schPage = 1;
  schPageDriver = 1;
  schSearch = '';
  schDriverSearch = '';
  calling = false;
  filtId = '';
  str = '';
  end = '';
  public dateFilterFormSchedule: FormGroup;


  constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
              private router: Router) {
  }

  ngOnInit() {
    this.dateFilterFormSchedule = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.getScheduleRides();
    this.getDriverScheduleRides();
  }

  getScheduleRides() {
    this.calling = true;
    this.apiServices.getUserScheduledRides(this.schPage, this.schSearch, this.filtId, this.str, this.end).subscribe((res) => {
      this.calling = false;
      if (res.response) {
          this.schRides = res.response.data;
          this.totalSch = res.response.total;
          // this.total = res.response.total;
        }
      }, (err) => {
        console.log(err);
      });
  }
  getDriverScheduleRides() {
    this.calling = true;
    this.apiServices.getDriverScheduledRides(this.schPageDriver, this.schDriverSearch, this.filtId, this.str, this.end).subscribe((res) => {
      this.calling = false;
      if (res.response) {
        this.driverSchRides = res.response.data;
        this.totalDriverSch = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getFilter(filter) {
    this.apiServices.getUserScheduledRides(this.schPage, this.schSearch, filter, this.str, this.end).subscribe((res) => {
      if (res.response) {
        this.schRides = res.response.data;
        this.totalSch = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getDriverFilter(filter) {
    this.apiServices.getDriverScheduledRides(this.schPageDriver, this.schDriverSearch, filter, this.str, this.end).subscribe((res) => {
      if (res.response) {
        this.schRides = res.response.data;
        this.totalSch = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }

  getScheduledData() {
    if (this.dateFilterFormSchedule.invalid) {
      this.snotifyService.error('All Fields Are Mandatory', 'Error');
    } else {
      this.str = this.dateFilterFormSchedule.get('start').value;
      this.end = this.dateFilterFormSchedule.get('end').value;
      this.calling = true;
      this.apiServices.getUserScheduledRides(this.schPage, this.schSearch, this.filtId, this.str, this.end).subscribe((res) => {
          this.calling = false;
          this.schRides = res.response.data;
          this.totalSch = res.response.total;
          this.snotifyService.success('Filtered Successfully', 'Success');
          this.router.navigate(['../dashboard/sch-rides']);
        },
        (err) => {
          console.log(err);
        });
    }
  }

  pageChangedSch($event) {
    this.schPage = $event;
    this.getScheduleRides();
  }
  pageChangedDriverSch($event) {
    this.schPageDriver = $event;
    this.getScheduleRides();
  }

  resetFilterUser() {
    this.str = '';
    this.end = '';
    this.schPage = 1;
    this.filtId = '';
    this.schSearch = '';
    this.apiServices.getUserScheduledRides(this.schPage, this.schSearch, this.filtId, this.str, this.end).subscribe((res) => {
      if (res.response) {
        this.schRides = res.response.data;
        this.totalSch = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  resetFilterDriver() {
    this.str = '';
    this.end = '';
    this.schPageDriver = 1;
    this.filtId = '';
    this.schDriverSearch = '';
    this.apiServices.getDriverScheduledRides(this.schPageDriver, this.schDriverSearch, this.filtId, this.str, this.end).subscribe((res) => {
      if (res.response) {
        this.driverSchRides = res.response.data;
        this.totalDriverSch = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
}

