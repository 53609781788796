import {Component, OnInit} from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {environment} from "@environments/environment";
import {SnotifyService} from "ng-snotify";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { ApiService as ApiV2Service } from '@app/core/v2/services/api.service';
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-more-details-vehicle',
  templateUrl: './more-details-vehicle.component.html',
  styleUrls: ['./more-details-vehicle.component.css']
})
export class documentComponent implements OnInit {
  id;
  user: any;
  noteTitle:string;
  assetUrl: any = environment.assetsUrl;
  loading=true;
  submitting=false;
  notesForm: FormGroup;
  notes: any[]=[];
  constructor( private formBuilder: FormBuilder,
               private snotifyService: SnotifyService,
               private router: Router,
              private route: ActivatedRoute,
               private apiV2Service: ApiV2Service,
               private apiServices: ApiService) {
  }

  ngOnInit() {
    this.getCurrentRoutes();
    this.getSingleUserDetails();
    this.getNotesForm();
    this.getUserNotes();
  }

  /**
   * current route
   */
  getCurrentRoutes(){
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  /**
   * forms
   */
  getNotesForm(){
    this.notesForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      category: ['', Validators.required],
    });
  }

  get userNotes(){
    return this.notesForm.controls;
  }

  /**
   * get single user details
   */
  getSingleUserDetails(){
    this.apiServices.getSingleUser(this.id)
        .subscribe((res)=>{
          if(res){
            this.user= res.response.data;
            this.loading = false;
          }
        },error=>{
          this.snotifyService.error(`something went wrong with this note`, 'Error');
        })
  }

  /**
   * add users notes
   */
  onAddingUserNotes(){
    this.submitting=true;
    let notes= {
      title: this.userNotes.title.value,
      description: this.userNotes.description.value,
      user_id: this.id,
      category: this.userNotes.category.value,
    };
    this.noteTitle=notes.title;
    //invoke the API endpoints
    this.apiV2Service.addUserNotes(notes.title, notes.description,notes.user_id,notes.category)
        .subscribe((res)=>{
          if(res){
            this.snotifyService
            .success(`You have successfully added ${this.noteTitle} note about ${this.user['first_name']}`, 'Success');
            this.reloadCurrentRoute();
            this.loading=false;
          }
          else{
            this.snotifyService.warning(`something went wrong with this note`, 'Warning');
          }
        },error => {
          this.snotifyService.error(`something went wrong with this note`, 'Error');
        })
  }

  /**
   * retrieve notes about riders and drivers
   */
  getUserNotes(){
    this.apiV2Service.getUserNotes(this.id)
        .subscribe((res)=>{
          if(res){
            this.notes=res.response.notes;
            this.loading=false;
          }
          else{
            this.snotifyService.warning(`something went wrong with this note`, 'Warning');
          }
        },error => {
          this.snotifyService.error(`something went wrong with this note`, 'Error');
        })
  }
  /**
   * remove the note
   */
   removeUserNote(noteId:number){
    this.apiV2Service.removeUserNotes(noteId)
      .subscribe((res)=>{
        if(res){
          this.snotifyService.success(`You have successfully removed this note`, 'Success');
          this.reloadCurrentRoute();
          this.loading=false;
        }
        else{
          this.snotifyService.warning(`something went wrong with this note`, 'Warning');
        }
      },error => {
        this.snotifyService.error(error, 'Error');
      })
   }
  /**
   * reload
   */
   reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
