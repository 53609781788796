import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {
  transform(users, nameSearch: string, emailSearch: string, companySearch: string) {
    if (users && users.length) {
      return users.filter(item => {
        if (nameSearch && item.name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && item.email.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1) {
          return false;
        }
        return true;
      });
    } else {
      return users;
    }
  }
}
