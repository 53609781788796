import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor() {
  }

  create(title: string, options ?: PushNotification): any {
    return new Observable(obs => {
      if (!('Notification' in window)) {
        console.log('Notifications are not available in this environment');
        obs.complete();
      }

      const notify = new Notification(title, options);

      notify.onshow = e => obs.next({
        notification: notify,
        event: e
      });

      notify.onclick = e => obs.next({
        notification: notify,
        event: e
      });

      notify.onerror = e => obs.error({
        notification: notify,
        event: e
      });

      notify.onclose = () => obs.complete();
    });
  }

  generateNotification(notification: any): void {
    const options = {
      body: notification.body,
      data: {
        url: notification.url
      }
    };

    this.create(notification.title, options).subscribe();
  }
}

export interface PushNotification {
  body?: string;
  icon?: string;
  tag?: string;
  data?: any;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noscreen?: boolean;
  sticky?: boolean;
  dir?: 'auto' | 'ltr' | 'rtl';
  lang?: string;
  vibrate?: number[];
}
