import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SnotifyService} from 'ng-snotify';
import * as Highcharts from 'highcharts';
import * as echarts from 'echarts';
declare var $: any;
declare var require: any;
import {environment} from '@environments/environment';
import ExportingModule from 'highcharts/modules/exporting';


ExportingModule(Highcharts);

@Component({
    selector: 'app-rating-review',
    templateUrl: './rating-review.component.html',
    styleUrls: ['./rating-review.component.css']
})
export class RatingReviewComponent implements OnInit {
    userRating = [];
    driverRating = [];
    users:any=[];
    assetUrl: any = environment.assetsUrl;
    temp = null;
    nameSearch;
    riderSearch;
    str;
    end;
    strR;
    endR;
    totalDrivers;
    totalUsers;
    driverCurrentPage = 1;
    userCurrentPage = 1;
    calling=true;
    callingF=true;
    filter:any='';
    dataPass:any={};
    dayReports:any={driver_ride:{},rider_ride:{}}
    showChart:any=0;
    showDChart:any=0;
    page:any=5;
    chartTypeLany='doughnut';
    driverChartOption:any={data:{},options:{}};
    riderChartOption:any={data:{},options:{}};
    public dateFilterForm: FormGroup;
    public dateFilterFormDriver: FormGroup;
    public dateFilterFormR: FormGroup;
    constructor(private apiServices: ApiService, private formBuilder: FormBuilder, private snotifyService: SnotifyService,
                private router: Router) {
    }

    ngOnInit() {
        this.dateFilterForm = this.formBuilder.group({
            start: ['', Validators.required],
            end: ['', Validators.required],
        });
        this.dateFilterFormR = this.formBuilder.group({
            startR: ['', Validators.required],
            endR: ['', Validators.required],
        });

        this.dateFilterFormDriver = this.formBuilder.group({
            start: ['', Validators.required],
            end: ['', Validators.required],
        });
        this.getDriverRating();
        this.getUserRating();
        this.getUsersR();

        setTimeout(()=>{
            this.riderPieChart();
            this.driverPieChart();
        },5000)
    }


    /**
     * get driver ratings
     */
    getDriverRating() {
        this.apiServices.getDriverRating(this.driverCurrentPage).subscribe((res) => {
            this.driverRating = res.response.data;
            this.totalDrivers = res.response.total;
            this.dayReports.driver_ride=res.response.data;
            this.calling = false;
        }, (err) => {
            console.log(err);
        });
    }
    pageChangedDriver($event) {
        this.driverCurrentPage = $event;
        this.getDriverRating();
    }

    getUserRating() {
        this.apiServices.getUserRating(this.userCurrentPage)
            .subscribe((res) => {
                this.userRating = res.response.data;
                this.totalUsers = res.response.total;
                this.dayReports.rider_ride=res.response.data;
                this.calling = false;
            }, (err) => {
                console.log(err);
            });
    }
    pageChangedUser($event) {
        this.userCurrentPage = $event;
        this.getUserRating();
    }

    //Date Filter For User
    getData() {
        if (this.dateFilterForm.invalid) {
            this.snotifyService.error('All Fields Are Mandatory', 'Error');
            this.calling=false;
        } else {
            this.str = this.dateFilterForm.get('start').value;
            this.end = this.dateFilterForm.get('end').value;
            // console.log(this.str + this.end);
            this.apiServices.getUserRatingFilter(this.str, this.end).subscribe((res) => {
                    this.userRating = res.response.data;
                    this.snotifyService.success('Filtered Successfully', 'Success');
                    this.router.navigate(['../dashboard/rating-review']);
                    this.calling = false;
                },
                (err) => {
                    console.log(err);
                });
        }
    }

    //Date Filter for Filter
    getDataDriver() {
        if (this.dateFilterFormDriver.invalid) {
            this.snotifyService.error('All Fields Are Mandatory', 'Error');
            this.calling=false;
        } else {
            this.str = this.dateFilterFormDriver.get('start').value;
            this.end = this.dateFilterFormDriver.get('end').value;
            // console.log(this.str + this.end);
            this.apiServices.getDriverRatingFilter(this.str, this.end).subscribe((res) => {
                    this.driverRating = res.response.data;
                    this.snotifyService.success('Filtered Successfully', 'Success');
                    this.router.navigate(['../dashboard/rating-review']);
                    this.calling = false;
                },
                (err) => {
                    console.log(err);
                });
        }
    }

    //Sort Driver Data By Rating
    sortDataDriver(n) {
        this.apiServices.sortRatingDriver(n).subscribe((res) => {
            this.driverRating = res.response.data;
            this.calling = false;

        }, (err) => {
            console.log(err);
        });
    }

    //Sort User Data By User
    sortDataUser(n) {
        this.apiServices.sortRatingUser(n).subscribe((res) => {
            this.userRating = res.response.data;
            this.calling = false;
        }, (err) => {
            console.log(err);
        });
    }

    /**
     * driver pie chart
     */
    driverPieChart()
    {
        let chartDom = <HTMLCanvasElement> document.getElementById('driver-rating');
        let myChart = echarts.init(chartDom);
        let option;

        option = {
            tooltip: {
                trigger: 'item'
            },
            // legend: {
            //     orient: 'horizontal',
            //     top: 'top',
            // },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: 'Ratings Count',
                    type: 'pie',
                    radius: '100%',
                    data: [
                        {value: 10, name: 'Below 1 star'},
                        {value: 20, name: '1-2 star'},
                        {value: 12, name: '2-3 star'},
                        {value: 10, name: '3-4 star'},
                        {value: 35, name: '5 star'}
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        option && myChart.setOption(option);
    }

    /**
     * rider pie chart
     */
    riderPieChart()
    {
        let chartDom = <HTMLCanvasElement> document.getElementById('rider-rating');
        let myChart = echarts.init(chartDom);
        let option;

        option = {
            tooltip: {
                trigger: 'item'
            },
            // legend: {
            //     orient: 'vertical',
            //     right: 'right',
            // },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: 'Ratings Count',
                    type: 'pie',
                    radius: '100%',
                    data: [
                        {value: 20, name: 'Below 1 star'},
                        {value: 27, name: '1-2 star'},
                        {value: 29, name: '2-3 star'},
                        {value: 30, name: '3-4 star'},
                        {value: 45, name: '5 star'}
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };

        option && myChart.setOption(option);
    }

    /**
     * get users
     */
    getUsersR() {
        this.callingF = true;
        this.dataPass={
            filter:this.filter,
            startDate:this.str,
            endDate:this.end,
            page:this.page

        }

        this.apiServices.postGeneralMethod('get-ratings-filter',this.dataPass).subscribe((res) => {
            this.callingF = false;
            if (res.response) {
                this.users = res.response;
                this.calling = false;
            }
        }, (err) => {
            console.log(err);
        });
    }

    /**
     * get data
     * @param filter
     */
    getDataR(filter) {

        if(filter=='date')
        {
            this.strR = this.dateFilterFormR.get('start').value;
            this.endR = this.dateFilterFormR.get('end').value;
            this.temp = false;
            $('#myDateModelR').modal('hide');

        }
        else
        {
            this.strR='';
            this.endR='';

        }
        this.filter=filter;
        this.getUsersR();

    }

    resetFilterR() {
        this.filter = '';
        this.strR = '';
        this.endR='';

        this.getUsersR();

    }
}
