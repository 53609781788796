import {Component, OnInit} from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {
  bus:any= [];
  busSearch = '';
  id;
  busCurrentPage = 1;
  transactions:any=[];
  calling: boolean;
  callingT: boolean;
  totalitems;
  coupon:any=[];
  showcoupon:any='transaction';
  constructor(private apiServices: ApiService,
              private snotifyService: SnotifyService,
              private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getBus();
    this.getTransaction();
    this.getCoupon();
  }
  changeCoupon(t)
  {
    this.showcoupon=t;
    if(t=='transaction') this.getTransaction(); else this.getCoupon();
  }
  getBus() {
    this.calling = true;
    this.apiServices.postGeneralMethod('get-coupon',{page:1}).subscribe((res) => {
        this.bus = res.response.data;
      this.calling = false;
      }, (err) => {
        console.log(err);
      }
    );
  }
  removeCoupon(id,i)
  {
    this.apiServices.postGeneralMethod('update-coupon',{id:id}).subscribe((res) => {

        this.coupon.splice(i,1)
        this.totalitems=this.coupon.length;
      }, (err) => {
        console.log(err);
      }
    );
  }
  getCoupon() {
    this.callingT = true;
    this.apiServices.postGeneralMethod('get-coupon-admin',{page:1}).subscribe((res) => {
        this.coupon = res.response.data;
        this.totalitems=this.coupon.length;
        this.callingT = false;
      }, (err) => {
        console.log(err);
      }
    );
  }
  getTransaction(){
    this.callingT=true
    this.apiServices.postGeneralMethod('get-coupon-transaction',{page:this.busCurrentPage}).subscribe((res) => {
        this.transactions = res.response.data;
        this.totalitems = res.response.total;
        this.callingT = false;
      }, (err) => {
        console.log(err);
      }
    );
  }

  pageChangedBus($event) {
    this.busCurrentPage = $event;
    this.getTransaction();
  }

  // searchB(id) {
  //   this.apiServices.getBus(id).subscribe((res) => {
  //     console.log(id);
  //   });
  // }


}
