import {Component, OnInit} from '@angular/core';
import {ApiService} from '@app/core/http/api.service';
import {ApiService as ApiV2Service} from '@app/core/v2/services/api.service';
import * as echarts from 'echarts';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {
    monthlyRides: any[]=[];
    weeklyRides: any[]=[];
    yearlyRides: any[]=[];
    dailyRides: any;
    pastThirtyDaysRides: any[]=[];
    allTimeRides:any[]=[];
    ridesComparisons:any;
    weeklyRidesPerYear:any[]=[];
    loading=true;
    //ride statistics
    totalKilometers: number;
    totalEarnings: number;
    happyCustomers: number;
    totalCancelledRides: number;
    totalScheduledRides: number;
    totalCompletedRides: number;

    //driver statistics
    totalKekeDrivers: number;
    totalCarDrivers: number;
    totalBusDrivers: number;

    constructor(private apiServices: ApiService, private apiV2Service: ApiV2Service) { }

    ngOnInit() {
      this.getMonthlyRidesReport();
      this.getWeeklyRidesReport();
      this.getRidesStatisticsReport();
      this.drawDriverStatistics();
      this.getCompletedRides();
      this.getDriverCounters();
      this.getYearlyRidesReport();
      this.getDailyRidesReport();
      this.getRidesReportPerWeekPerYear();
      this.getRidesComparisonsReport();
      setTimeout(()=>{
          this.drawMonthlyRidesBarCharts();
          this.drawYearlyRidesBarCharts();
          this.drawWeeklyRidesBarCharts();
          this.drawRiderStatistics();
          this.drawDriverStatistics();
          this.drawPastThirtyDaysRidesBarCharts();
          this.drawRidesComparisonsLineGraph();
          this.drawStackedLineGraphWeeklyYearlyRides();
      },5000);
    }

    /**
     * yearly reports
     */
    getYearlyRidesReport(){
        try{
            this.apiV2Service.getYearlyReports()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.yearlyRides=res.response;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    /**
     * monthly reports
     */
    getMonthlyRidesReport(){
        try{
            this.apiServices.getMonthlyRides()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.monthlyRides=res.response;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * weekly reports
     */
    getWeeklyRidesReport(){
        try{
            this.apiServices.getDetailsGraph()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.weeklyRides=res.response;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * daily reports
     */
    getDailyRidesReport(){
        try{
            this.apiV2Service.getDailyReports()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.pastThirtyDaysRides=res.response['lastThirtyDays'];
                        this.allTimeRides=res.response['allTimeRides'];
                        let ridesToday=res.response['ridesToday'];
                        if(ridesToday!=null){
                            for (let  ridesTodayElement of ridesToday) {
                                this.dailyRides= ridesTodayElement['rides']| 0;
                            }
                        }
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * daily reports
     */
    getRidesComparisonsReport(){
        try{
            this.apiV2Service.getRidesComparisons()
                .subscribe((res)=>{
                    if(res.response){
                        this.ridesComparisons=res.response;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * compare all weeks reports per year
     */
    public getRidesReportPerWeekPerYear(){
        try{
            this.apiV2Service.getRidesReportPerWeekPerYear()
                .subscribe((res)=>{
                    if(res.response){
                        this.weeklyRidesPerYear=res.response;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * ride statistics reports
     */
    getRidesStatisticsReport(){
        try{
            this.apiServices.adminDashboardTotal()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.totalKilometers=res.response.total_km;
                        this.totalCancelledRides=res.response.total_canceled_rides;
                        this.totalScheduledRides=res.response.total_scheduled_rides;
                        this.totalEarnings=res.response.total_earnings;
                        this.happyCustomers=res.response.happy_customers;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    /**
     * get all completed rides
     */
    getCompletedRides(){
        try{
            this.apiServices.getCompletedRides()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.totalCompletedRides=res.response.total;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    /**
     * get driver counts
     */
    getDriverCounters(){
        try{
            this.apiServices.getPieDriverCount()
                .subscribe((res)=>{
                    if(res.response){
                        //do sth
                        this.totalCarDrivers=res.response.total_car_drivers;
                        this.totalBusDrivers=res.response.total_bus_drivers;
                        this.totalKekeDrivers=res.response.total_keke_drivers;
                        this.loading=false;
                    }
                    else{
                        //do sth
                        console.log(res);
                    }
                },error=>{
                    console.log(error);
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    /**
     * draw a driver statistics
     */
    drawRidesComparisonsLineGraph(){
        let chartDom = <HTMLCanvasElement> document.getElementById('rides-comparisons');
        let myChart = echarts.init(chartDom);
        let option;

        option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'horizontal',
                bottom: 'bottom',
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: 'ride Statistics',
                    type: 'pie',
                    radius: '60%',
                    data: [
                        {value: this.ridesComparisons.pooledRides, name: 'Pooled Rides'},
                        {value: this.ridesComparisons.kekeRides, name: 'Keke Rides'},
                        {value: this.ridesComparisons.busRides, name: 'Bus Rides'},
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        myChart.setOption(option);
    }

    /**
     * draw weeks/yearly
     */
    drawStackedLineGraphWeeklyYearlyRides(){
        let chartDom =<HTMLCanvasElement> document.getElementById('yearly-weekly-rides-bar');
        let myChart = echarts.init(chartDom);
        let option;

        let year: number[]=[];
        let rides: number[]=[];
        let week: number[]=[];

        for (let ride of this.weeklyRidesPerYear) {
            year.push(ride.year);
            rides.push(ride.rides);
            week.push(ride.week);
        }

        option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: []
            },
            grid: {
                left: '5%',
                right: '5%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: week
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: 'rides',
                    type: 'line',
                    data: rides
                },
                {
                    name: 'week',
                    type: 'line',
                    data: week
                },
            ]
        };

        option && myChart.setOption(option);
    }

    /**
     * monthly rides bar charts
     */
    drawMonthlyRidesBarCharts() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("monthly-rides-bar"));
        //Specify the configuration items and data of the icon
        let option;
        let months=[];
        let counts=[];
        for (let rides of this.monthlyRides) {
            months.push(rides['month'].substr(0,3).toUpperCase());
            counts.push(rides['count']);
        }

        option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: months,
                axisTick: {
                    alignWithLabel: true
                }
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: counts,
                type: 'bar',
                name: 'Monthly Rides',
            }],
            color:['green'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

    /**
     * YEARLy rides bar charts
     */
    drawYearlyRidesBarCharts() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("yearly-rides-bar"));
        //Specify the configuration items and data of the icon
        let option;
        let years:any[]=[];
        let rides:number[]=[];
        for (let ride of this.yearlyRides) {
            years.push(ride.year);
            rides.push(ride.rides);
        }
        option = {
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                data: years
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: rides,
                type: 'line',
                smooth:true,
            }]
        };
        //Display the data to the table
        myCharts.setOption(option);
    }
    /**
     * past 30 days rides bar charts
     */
    drawPastThirtyDaysRidesBarCharts() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("thirty-days-rides-bar"));
        //Specify the configuration items and data of the icon
        let option;
        let months=[];
        let counts=[];
        for (let rides of this.pastThirtyDaysRides) {
            months.push(rides['date']);
            counts.push(rides['rides']);
        }

        option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: months,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter: (function(value){
                        // @ts-ignore
                        return echarts.format.formatTime("d-MM", value);
                    }),
                    interval: 0
                },
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: counts,
                type: 'bar',
                name: 'Daily Rides',
                barWidth: '80%',
            }],
            color:['#0177D9'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }
    /**
     * weekly rides bar charts
     */
    drawWeeklyRidesBarCharts() {
        let myCharts = echarts.init(<HTMLCanvasElement> document.getElementById("weekly-rides-bar"));
        //Specify the configuration items and data of the icon
        let option;
        let dates=[];
        let counts=[];
        for (let rides of this.weeklyRides) {
            dates.push(rides['date']);
            counts.push(rides['count']);
        }

        option = {
            tooltip: {
                trigger: 'axis',
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: true,
                data: dates,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    formatter: (function(value){
                        // @ts-ignore
                        return echarts.format.formatTime("d-MM", value);
                    }),
                    interval: 0
                },
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: counts,
                type: 'bar',
                name: 'Weekly Rides',
                barWidth: '80%',
            }],
            color:['#0177D9'],
        };
        //Display the data to the table
        myCharts.setOption(option);
    }

    /**
     * draw a driver statistics
     */
    drawRiderStatistics(){
        let chartDom = <HTMLCanvasElement> document.getElementById('rider-statistics');
        let myChart = echarts.init(chartDom);
        let option;

        option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'horizontal',
                bottom: 'bottom',
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: 'ride Statistics',
                    type: 'pie',
                    radius: '60%',
                    data: [
                        {value: this.totalKilometers, name: 'Kilometer Covered'},
                        {value: this.totalCompletedRides, name: 'Completed Rides'},
                        {value: this.totalEarnings, name: 'Earnings'},
                        {value: this.happyCustomers, name: 'Happy Clients'},
                        {value: this.totalCancelledRides, name: 'Cancelled Rides'},
                        {value: this.totalScheduledRides, name: 'Scheduled Rides'},
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        myChart.setOption(option);
    }
    /**
     * draw a ride statistics
     */
    drawDriverStatistics(){
        let chartDom = <HTMLCanvasElement> document.getElementById('driver-statistics');
        let myChart = echarts.init(chartDom);
        let option;

        option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                orient: 'horizontal',
                left: 'left',
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            series: [
                {
                    name: 'Driver Statistics',
                    type: 'pie',
                    radius: '60%',
                    data: [
                        {value: this.totalCarDrivers, name: 'Car Drivers'},
                        {value: this.totalBusDrivers, name: 'Bus Drivers'},
                        {value: this.totalKekeDrivers, name: 'Keke Drivers'},
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        myChart.setOption(option);
    }

}
