import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startedFilter'
})
export class StartedFilterPipe implements PipeTransform {

  
  transform(rides , nameSearch: string, emailSearch: string, companySearch: string){
    if (rides && rides.length){
        return rides.filter(item =>{
          
            if (nameSearch && item.driver_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.rider_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1  
            && nameSearch && item.ride_date.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.total_fare.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.source.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
            && nameSearch && item.destination.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          ){
                return false;
            }
            return true;
       })
    }
    else{
        return rides;
    }
}

}
