import {Component, OnInit} from '@angular/core';
import {SnotifyService} from 'ng-snotify';
import {FormBuilder} from '@angular/forms';
import {ApiService} from '@app/core/http/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-message-to-all',
  templateUrl: './message-to-all.component.html',
  styleUrls: ['./message-to-all.component.css']
})
export class MessageToAllComponent implements OnInit {
  sendObj: any = {
    sendingWay: {type: 1, email_content: '', message: ''},
    userType: {type: 1, username: ''},
  };

  constructor(private snotifyService: SnotifyService,
              private formBuilder: FormBuilder,
              private apiServices: ApiService,
              private router: Router) {
  }

  ngOnInit() {
  }

  sendToAll() {
    if (this.sendObj.sendingWay.type == 1) {
      this.apiServices.sendPromotionalMsg({type: this.sendObj.userType.type}).subscribe((res) => {
          this.snotifyService.success('Message Sent Successfully', 'Success');
          this.router.navigate(['../../dashboard/send-to-all']);
        },
        (err) => {
          console.log(err);
        });
    } else if (this.sendObj.sendingWay.type == 2) {
      const data = {
        type: this.sendObj.userType.type,
        email_content: this.sendObj.sendingWay.email_content
      };
      this.apiServices.sendEmail(data).subscribe((res) => {
          this.snotifyService.success('Email Sent Successfully', 'Success');
          this.router.navigate(['../../dashboard/send-to-all']);
        },
        (err) => {
          console.log(err);
        });
    }
  }
}
