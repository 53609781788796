import { Component, OnInit } from '@angular/core';
import {MessagingService} from '@services/messaging.service';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "@app/core/http/api.service";
import {SnotifyService} from "ng-snotify";
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.css']
})
export class AddCouponComponent implements OnInit {
  addBusFormGroup: FormGroup;
  disabled = false;



  constructor( private messagingService: MessagingService,
               private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private snotifyService: SnotifyService,
              private router: Router) {

  }

  ngOnInit() {
    this.addBusFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
      status: ["1", Validators.required],
    });
  }

  addCouponCategory() {
    this.disabled = true;
    this.apiServices.postGeneralMethod('add-coupon-category',this.addBusFormGroup.value).subscribe((res) => {
        this.snotifyService.success('Added Successfully', 'Success');
        this.disabled = false;
        this.router.navigate(['../dashboard/coupon']);
      },
      (err) => {
        console.log(err);
        this.disabled = false;
        this.snotifyService.error('Db action error', 'Error');
      });
  }
}
