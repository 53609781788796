import { Component, OnInit } from '@angular/core';
import {ApiService} from "@app/core/http/api.service";
import {environment} from "@environments/environment";
import {SnotifyService} from 'ng-snotify';
import {FormBuilder, FormGroup} from "@angular/forms";
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-category-shop',
  templateUrl: './category-shop.component.html',
  styleUrls: ['./category-shop.component.css']
})
export class CategoryShopComponent implements OnInit {
  disabled = false;
  assetUrl: any = environment.assetsUrl;
  allCategories = [];
  categoryCurrentPage = 1;
  calling: boolean;
  totalCategory;

  selectedCategory;
  editCategoryForm:FormGroup;

  constructor(
      private apiServices: ApiService,
      private formBuilder: FormBuilder,
      private router: Router,
      private snotifyService:SnotifyService) {
  }

  ngOnInit() {
    this.getAllCategories();
    this.updateForm();
  }

  /**
   * edit category form
   */
  updateForm(){
    this.editCategoryForm = this.formBuilder.group({
      category_name:['']
    });
  }

  get form(){
    return this.editCategoryForm.controls;
  }


  /**
   * get all categories
   */
  getAllCategories() {
    this.calling = true;
    this.apiServices.getAllCategories(this.categoryCurrentPage)
        .subscribe((res) => {
          this.calling = false;
          this.allCategories = res.response.data;
          this.totalCategory = res.response.total;

        });
  }

  /**
   * edit
   */
  onCategoryUpdate(id){
    let category_name=this.form.category_name.value;
    this.apiServices.editCategory(category_name,id)
        .subscribe((res)=>{
          //hide modal
          $('#editCategoryModal').modal('hide');
          this.snotifyService.success('You have successfully updated the category name', 'Success');
          this.reloadCurrentRoute();
        },error => {
          this.snotifyService.error('Error occurred, try again later', 'Error');
        })
  }

  /**
   * user action, get category details
   * @param category
   */
  onCategoryClick(category:any){
    this.selectedCategory=category;
  }

  /**
   *
   * @param category_id
   */
  removeCategory(category_id) {
    this.apiServices.removeCategory(category_id)
        .subscribe((res)=>{
          this.snotifyService.success('You have successfully updated the order status', 'Success');
          this.reloadCurrentRoute();
        },error=>{
          this.snotifyService.error('Error occurred, try again later', 'Error');
        })
  }

  /**
   *
   * @param $event
   */
  pageChangedCategory($event) {
    this.categoryCurrentPage = $event;
    this.getAllCategories();
  }
  /**
   * reload
   */
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
