import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'poolPostedFilter'
})
export class PoolPostedFilterPipe implements PipeTransform {

  transform(ride, nameSearch: string) {
    if (ride && ride.length) {
      return ride.filter(item => {
        if (nameSearch && item.Driver_name.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && item.source.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && item.destination.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && 'Posted'.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && 'Accepted'.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && 'Started'.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && 'Completed'.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && 'Cancelled'.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
          && nameSearch && item.total_fare.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1) {
          return false;
        }
        return true;
      });
    } else {
      return ride;
    }
  }
}
