import {Component, OnInit} from '@angular/core';
import {SnotifyService} from 'ng-snotify';
import {ApiService} from '@app/core/http/api.service';
import {News} from '@app/shared/models/news';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news: News[];
  total;

  constructor(private snotifyService: SnotifyService, private apiServices: ApiService) {
  }

  ngOnInit() {
    this.getNews();
  }

  getNews() {
    this.apiServices.getNews().subscribe((res) => {
        this.news = res.response.news_feed;
        this.total = res.response.total;
      }, (err) => {
        console.log(err);
      }
    );
  }

  delete(id) {
    this.snotifyService.confirm('Click Yes if You Want Delete News', 'Are You Sure', {
      closeOnClick: false,
      buttons: [
        {
          text: 'Yes', action: () =>
            this.apiServices.deleteNews(id).subscribe((res) => {
                console.log(res);
                this.snotifyService.remove();
                this.snotifyService.success('News Deleted', 'Success');
                this.getNews();
              },
              (err) => {
                console.log(err);
              })
          , bold: false
        },
        {
          text: 'No', action: () =>
            this.snotifyService.remove()
        },
      ]
    });
  }
}
