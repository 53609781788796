import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@app/core/http/api.service';
import { Location } from '@angular/common';
import {User} from '@app/shared/models/user';
import {Rides} from '@app/shared/models/rides';
import {environment} from '@environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnotifyService} from 'ng-snotify';
declare var $: any;

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  assetUrl: any = environment.assetsUrl;
  currency: any = environment.currency;
  avatar_url:string;
  userDetails: any = {
    id: '',
    type: '',
    profile_picture: '',
    first_name: '',
    last_name: '',
    country_code: '',
    average_rating: '',
    email: '',
    city: '',
    created_at : '',
    updated_at : ''
  };
  ride: Rides[] = [];
  id;
  nameSearch;
  rideSearch;
  acceptedRide: any = {
    total : '',
    data: []
  };
  postedRide: any = {
    total: '',
    data: []
  };
  currentPage = 1;
  AcceptedCurrentPage = 1;
  PostedCurrentPage = 1;
  public dateFilterFormPosted: FormGroup;
  public dateFilterFormAccepted: FormGroup;
  str;
  end;

  constructor(private route: ActivatedRoute,
              private apiServices: ApiService,
              private formBuilder: FormBuilder,
              private location: Location,
              private snotifyService: SnotifyService,
              private router: Router) {
  }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getUserDetails(this.id);
      this.getUserPostedRides(this.id);
      this.getUserAcceptedRides(this.id);
    });
    this.dateFilterFormPosted = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
    this.dateFilterFormAccepted = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }

  getUserDetails(n) {
    this.apiServices.getSingleUser(n).subscribe((res) => {
        this.userDetails = res.response.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getUserAcceptedRides(id) {
    this.apiServices.getUserAcceptedRides(id , this.AcceptedCurrentPage, '', '', '').subscribe((res) => {
        this.acceptedRide.data = res.response.data;
        this.acceptedRide.total = res.response.total;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getUserPostedRides(id) {
    this.apiServices.getUserPostedRides(id , this.PostedCurrentPage, '', '', '').subscribe((res) => {
        this.postedRide.data = res.response.data;
        this.postedRide.total = res.response.total;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getFilterAccepted(filter) {
    this.apiServices.getUserAcceptedRides(this.id, this.AcceptedCurrentPage, filter, '', '').subscribe((res) => {
      if (res.response) {
        this.acceptedRide.data = res.response.data;
        this.acceptedRide.total = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getFilterPosted(filter) {
    this.apiServices.getUserPostedRides(this.id, this.PostedCurrentPage, filter, '', '').subscribe((res) => {
      if (res.response) {
        this.postedRide.data = res.response.data;
        this.postedRide.total = res.response.total;
        // this.total = res.response.total;
      }
    }, (err) => {
      console.log(err);
    });
  }
  getFilterDataPosted() {
    this.str = this.dateFilterFormPosted.get('start').value;
    this.end = this.dateFilterFormPosted.get('end').value;
    this.apiServices.getUserPostedRides(this.id, this.PostedCurrentPage, '', this.str, this.end).subscribe((res) => {
        $('#myDateModelPosted').modal('hide');
        this.postedRide.data = res.response.data;
        this.postedRide.total = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
      },
      (err) => {
        console.log(err);
      });
  }
  getFilterDataAccepted() {
    this.str = this.dateFilterFormAccepted.get('start').value;
    this.end = this.dateFilterFormAccepted.get('end').value;
    this.apiServices.getUserAcceptedRides(this.id, this.AcceptedCurrentPage, '', this.str, this.end).subscribe((res) => {
        $('#myDateModelAccepted').modal('hide');
        this.acceptedRide.data = res.response.data;
        this.acceptedRide.total = res.response.total;
        this.snotifyService.success('Filtered Successfully', 'Success');
      },
      (err) => {
        console.log(err);
      });
  }

  pageChangedPosted($event: number) {
    this.PostedCurrentPage = $event;
    this.getUserPostedRides(this.id);
  }

  pageChangedAccepted($event: number) {
    this.AcceptedCurrentPage = $event;
    this.getUserAcceptedRides(this.id);
  }

  goBack() {
    this.location.back(); // <-- go back to previous location on cancel
  }

  /**
   * use this to dispaly user profile
   */
  onCurrentDriverOwnerDocument(avatar: string) {
    this.avatar_url= avatar;
  }
}
