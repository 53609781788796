import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {ApiService} from '@app/core/http/api.service';
import {PushNotificationService} from '@services/push-notification.service';
@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  constructor(private angularFireMessaging: AngularFireMessaging,
              private apiService: ApiService,
              private notificationService: PushNotificationService) {
    this.angularFireMessaging.messaging.subscribe((messaging) => {
      messaging.onMessage = messaging.onMessage.bind(messaging);
      messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    });
  }

  /**
   * update token in firebase database
   *
   * @param token token as a value
   */
  updateToken(token) {
    this.apiService.storeTokenDetails(token).subscribe(res => {
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(token);
        // console.log(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        // console.log(payload);
        // console.log(payload.notification);
        this.notificationService.generateNotification(payload.notification);
        // console.log(payload);
      });
  }
}
