import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'rideFilter'
})
export class RideFilterPipe implements PipeTransform {

  transform(ride, rideSearch: string) {
    if (ride && ride.length) {
      return ride.filter(item => {
        if (rideSearch && item.Rider_name.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && item.source.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && item.destination.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && 'Posted'.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && 'Accepted'.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && 'Started'.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && 'Completed'.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && 'Cancelled'.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1
          && rideSearch && item.total_fare.toLowerCase().indexOf(rideSearch.toLowerCase()) === -1) {
          return false;
        }
        return true;
      });
    } else {
      return ride;
    }
  }
}
