import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ApiService} from '@app/core/http/api.service';
import {GeoLocationService} from '@services/geo-location.service';
import {LocalStorageService} from '@services/local-storage.service';
import {ApiPrefixInterceptor} from '@app/core/interceptors/api-prefix.interceptor';
import {HttpTokenInterceptor} from '@app/core/interceptors/http.token.interceptor';
import {AuthGuardService} from '@app/core/guards/auth.guard.service';
import {SharedServiceService} from '@services/shared-service.service';
import {NoAuthGuardService} from '@app/core/guards/no-auth.guard.service';
import { UserTypePipe } from '@app/core/pipes/user-type.pipe';
import { RideTypePipe } from './pipes/ride-type.pipe';
import { RideOptionPipe } from './pipes/ride-option.pipe';
import { RideStatusPipe } from './pipes/ride-status.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterRiderPipe } from './pipes/filter-rider.pipe';
import { UserFilterPipe } from './pipes/user-filter.pipe';
import { RideFilterPipe } from './pipes/ride-filter.pipe';
import { PoolPostedFilterPipe } from './pipes/pool-posted-filter.pipe';
import { PoolRequestedFilterPipe } from './pipes/pool-requested-filter.pipe';
import { PostedFilterPipe } from './pipes/posted-filter.pipe';
import { AcceptedFilterPipe } from './pipes/accepted-filter.pipe';
import { StartedFilterPipe } from './pipes/started-filter.pipe';
import { SupportStatusPipe } from './pipes/support-status.pipe';
import { TransactionFilterPipe } from './pipes/transaction-filter.pipe';
import { BusFilterPipe } from './pipes/bus-filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { GooglePlacesDirective } from './directives/google-places.directive';
import {DigitOnlyDirective} from '@app/core/directives/digit-only.directive';
import { TrueOrFalsePipe } from './pipes/true-or-false.pipe';
import { VehicleTypePipe } from './pipes/vehicle-type.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { NotificationPipe } from './pipes/notification.pipe';
import { PaymentMethodPipe } from './pipes/payment-method.pipe';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { TableFilterPipe } from './pipes/table-filter.pipe';
import { AccessControlDirective } from './directives/access-control.directive';
import { PipesPipe } from './pipes.pipe';
import { OnlineStatusPipe } from './pipes/online-status.pipe';
import { DriverTypePipe } from './pipes/driver-type.pipe';
@NgModule({
  declarations: [UserTypePipe, RideTypePipe, RideOptionPipe, RideStatusPipe, FilterPipe, FilterRiderPipe, AccessControlDirective,
    UserFilterPipe, RideFilterPipe, PoolPostedFilterPipe, PoolRequestedFilterPipe, PostedFilterPipe, AcceptedFilterPipe,
    StartedFilterPipe, SupportStatusPipe, TransactionFilterPipe, BusFilterPipe, SafeHtmlPipe, GooglePlacesDirective, DigitOnlyDirective,
      TrueOrFalsePipe, VehicleTypePipe, SearchFilterPipe, NotificationPipe, PaymentMethodPipe, OrderStatusPipe, TableFilterPipe, PipesPipe, OnlineStatusPipe, DriverTypePipe],
  imports: [
    CommonModule
  ],
    exports: [
        UserTypePipe,
        RideTypePipe,
        RideStatusPipe,
        FilterPipe,
        FilterRiderPipe,
        UserFilterPipe,
        RideFilterPipe,
        PoolPostedFilterPipe,
        PoolRequestedFilterPipe,
        PostedFilterPipe,
        AcceptedFilterPipe,
        StartedFilterPipe,
        SupportStatusPipe,
        TransactionFilterPipe,
        BusFilterPipe,
        SafeHtmlPipe,
        GooglePlacesDirective,
        DigitOnlyDirective,
        TrueOrFalsePipe,
        VehicleTypePipe,
        NotificationPipe,
        PaymentMethodPipe,
        OrderStatusPipe,
        SearchFilterPipe,
        TableFilterPipe,
        AccessControlDirective,
        DriverTypePipe
    ],
  providers: [
    ApiService,
    AuthGuardService,
    NoAuthGuardService,
    LocalStorageService,
    SharedServiceService,
    GeoLocationService,
    //{provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true}
  ]
})
export class CoreModule {
}
